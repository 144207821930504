import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/fi';

import { OrderingSelectors } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getHideTimes from '../selectors/getHideTimes';

import { setCurrentModal } from '../thunks/setCurrentModal';

import combineStyles from '../utils/combineStyles';

import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';

import OrderPropertySaleType from './OrderPropertySaleType';
import OrderPropertyLocation from './OrderPropertyLocation';
import PickupTime from './PickupTime';
import TableNumber from './TableNumber';
import OrderTotals from './OrderTotals';
import CheckoutButton from './CheckoutButton';
import DeliveryDetails from './DeliveryDetails';
import Surcharges from './Surcharges';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import CheckoutStripeProvider from '../components/CheckoutStripeProvider';

const { getMember, getSaleType, getStagedPurchaseCount, getSurcharges, getGuestOrderingAvailable } =
  OrderingSelectors;

const SignInPrompt: React.FC = () => {
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const enableGuestOrdering = useAppSelector(getGuestOrderingAvailable);

  const { t } = useTranslation();

  return (
    <TouchableOpacity
      style={styles.signInPromptContainer}
      onClick={() => dispatch(setCurrentModal({ modalId: SIGN_IN_MODAL_ID }))}
      ariaLabel="sign in"
    >
      <FiLogIn style={p('checkoutSignInPromptIcon', ['color', 'fontSize'])} />

      <Text themeKey="checkoutSignInPrompt" style={styles.checkoutSignInPrompt}>
        {t('checkoutSignInPrompt')}
      </Text>

      <Text themeKey="checkoutSignInPromptReason">
        {t(
          enableGuestOrdering
            ? 'checkoutSignInPromptReason.guestOrderingEnabled'
            : 'checkoutSignInPromptReason.guestOrderingDisabled',
        )}
      </Text>
    </TouchableOpacity>
  );
};

const DesktopReviewOrderSideSection: React.FC<{
  useMobileScreen: boolean | null;
}> = ({ useMobileScreen }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const member = useAppSelector(getMember);
  const saleType = useAppSelector(getSaleType);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
  const surcharges = useAppSelector(getSurcharges);
  const hideTimes = useAppSelector(getHideTimes);
  const tableNumberLabel = useAppSelector(state => state.config.tableNumberLabel);
  const disableLoyalty = useAppSelector(state => state.config.disableLoyalty);

  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING ||
    saleType === SALE_TYPE.CATERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  return (
    <div
      style={combineStyles(
        styles.mainContainer,
        p('reviewOrderScreenDesktop', ['boxShadow']),
        p('reviewOrderScreenDesktopSideOuter', ['backgroundColor']),
      )}
    >
      <div
        style={combineStyles(
          styles.innerContainer,
          p('screen', ['backgroundColor']),
          p('reviewOrderScreenDesktopSideInner', ['backgroundColor']),
        )}
      >
        <OrderPropertySaleType />

        {isDelivery && <DeliveryDetails />}

        <OrderPropertyLocation />

        {!isDelivery && (
          <div style={styles.control}>
            {!hideTimes && isPickupOrTakeAway && (
              <>
                <Text themeKey="sectionTitle" style={styles.controlLabel}>
                  {t('title.pickupTime')}
                </Text>

                <PickupTime
                  themeKey="orderPickupTimeSelect"
                  reducedWidth={110}
                  showTimeInErrorMessage
                />
              </>
            )}

            {isDineIn && (
              <>
                <Text themeKey="sectionTitle" style={styles.controlLabel}>
                  {tableNumberLabel || t('tableNumber')}
                </Text>

                <TableNumber themeKey="orderTableNumber" style={styles.tableNumber} />
              </>
            )}
          </div>
        )}

        <Surcharges />

        {(Boolean(stagedPurchaseCount) || Boolean(surcharges.length)) && (
          <OrderTotals pointsVisible useMobileScreen={useMobileScreen} />
        )}
        <CheckoutStripeProvider>
          <CheckoutButton />
        </CheckoutStripeProvider>
      </div>

      {disableLoyalty ? null : member ? null : <SignInPrompt />}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    minWidth: 250,
    backdropFilter: 'blur(2px)',
    WebkitBackdropFilter: 'blur(2px)',

    padding: 15,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },

  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',

    padding: 15,
  },

  control: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  controlLabel: {
    marginBottom: 10,
  },

  tableNumber: {
    width: 120,
    marginBottom: 10,
  },

  signInPromptContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
    marginTop: 30,
    marginBottom: 15,
  },

  checkoutSignInPrompt: {
    marginTop: 10,
    marginBottom: 10,
  },

  memberBalancesTitle: {
    textAlign: 'center',
  },
};

export default DesktopReviewOrderSideSection;
