import React, { useRef, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import combineStyles from '../utils/combineStyles';

import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';

import HeaderBar from './HeaderBar';
import { useAppSelector } from '../app/hooks';

const ScreenHero: React.FC<{
  scrollTop?: number;
  forcePerfectScrollbarForMobile?: boolean;
  disablePSB?: boolean;
  children?: React.ReactNode;
  onScroll?: () => void;
  heroChildren?: React.ReactNode;
}> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const psbRef = useRef<any>(null);
  const {
    scrollTop,
    forcePerfectScrollbarForMobile,
    disablePSB,
    children,
    onScroll,
    heroChildren,
  } = props;
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const heroScreenImagePath = useAppSelector(state => state.config.heroScreenImagePath);
  const hideHeroScreenImage = useAppSelector(state => state.config.hideHeroScreenImage);
  useEffect(() => {
    if (scrollTop)
      if (shouldUsePSB()) {
        psbRef.current!.scrollTop = scrollTop;
      } else {
        ref.current!.scrollTop = scrollTop;
      }
  }, [scrollTop]);

  const shouldUsePSB = () => {
    if (disablePSB) {
      return false;
    }

    if (deviceTypeMobile) {
      return forcePerfectScrollbarForMobile;
    }

    return true;
  };

  const scrollContent = (
    <>
      <div
        style={combineStyles(
          styles.heroImage,
          heroScreenImagePath && { backgroundImage: `url(${heroScreenImagePath})` },
          deviceTypeMobile ? styles.heroImageMobile : styles.heroImageDesktop,
          (extremelyShortScreen || hideHeroScreenImage) && styles.hideImage,
        )}
      >
        {heroChildren}
      </div>
      {children}
    </>
  );

  const scrollContainerProps = {
    style: styles.excludingHeader,
    onScroll,
  };

  return (
    <div style={styles.mainContainer}>
      <HeaderBar themeKey="headerBarHero" />

      {shouldUsePSB() ? (
        <PerfectScrollbar
          containerRef={ref => {
            psbRef.current = ref;
          }}
          {...scrollContainerProps}
        >
          {scrollContent}
        </PerfectScrollbar>
      ) : (
        <div ref={ref} {...scrollContainerProps}>
          {scrollContent}
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  excludingHeader: {
    flex: 1,

    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    overflowY: 'auto',
    overflowX: 'auto',
  },

  heroImage: {
    backgroundImage: 'url(https://via.placeholder.com/1920x300.png?text=HERO+IMAGE)',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // borderBottom: '1px solid rgba(0,0,0,0.1)',

    // WebkitBoxSizing: 'border-box', // /* Safari/Chrome, other WebKit */
    // MozBoxSizing: 'border-box', // /* Firefox, other Gecko */
    // boxSizing: 'border-box', // /* Opera/IE 8+ */

    position: 'relative',
  },

  heroImageMobile: {
    height: 180,
    minHeight: 180,
  },
  heroImageDesktop: {
    height: 300,
    minHeight: 300,
  },
  hideImage: {
    height: 0,
    minHeight: 0,
  },
};

export default ScreenHero;
