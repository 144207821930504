import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as updateMenuFlow from '../../actionCreators/flows/updateMenu';
import * as fetchMenuFlow from '../../actionCreators/flows/fetchMenu';
import * as applyBufferFlow from '../../actionCreators/flows/applyBuffer';

import {
  clearBuffer,
  setBufferReadyToApply,
  setBufferMenu,
} from '../../actionCreators/buffer';

import { $getLocationId } from '../../selectors/getLocationId';
import { $getSaleType } from '../../selectors/getSaleType';

import reserveOrCheckBuffer from '../reserveOrCheckBuffer';

import {
  makeErrorSerialisable,
  requestAndWaitForFlow,
  createBufferFlowApprover,
} from '../../utils/sagas';

export const requested = createBufferFlowApprover(updateMenuFlow);

export function* approved(
  action: ReturnType<typeof updateMenuFlow.actions.approved>,
) {
  const {
    payload: { autoApply, isSubFlow },
    meta: { flowId },
  } = action;

  let controlsBuffer;

  try {
    yield call(reserveOrCheckBuffer, flowId);
    controlsBuffer = true;

    if (!isSubFlow) {
      yield put(clearBuffer({ preserveReservationId: true }));
    }

    const locationId = yield select($getLocationId);
    const saleType = yield select($getSaleType);

    if (locationId == null || saleType == null) {
      throw new Error('missing required parameters');
    }

    const { succeeded, success } = yield call(
      requestAndWaitForFlow,
      fetchMenuFlow,
      { locationId, saleType },
      flowId,
    );

    if (!succeeded || !success.menu) {
      throw new Error('subflow did not finish successfully');
    }

    yield put(setBufferMenu(success.menu));

    if (!isSubFlow) {
      yield put(setBufferReadyToApply(true));

      if (autoApply) {
        yield put(applyBufferFlow.actions.requested({}, flowId));
      }
    }

    yield put(updateMenuFlow.actions.succeeded({}, flowId));
  } catch (e) {
    if (!isSubFlow && controlsBuffer) {
      yield put(clearBuffer({}));
    }
    yield put(
      updateMenuFlow.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(updateMenuFlow.events.REQUESTED, requested),
    takeEvery(updateMenuFlow.events.APPROVED, approved),
  ]);
}
