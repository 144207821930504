// This is the default theme.

// Many components can be themed even further than they are here.
// Inspect the styles and themeKeys of a component to see what is possible.

// Some components have a style hierarchy.
// E.g. defaultText > sectionTitle > cartCount

// Anything not specified (undefined) using the higher priority themeKey will fallback
// to the value provided by one of the lower priority themeKeys.

const DEFAULT_FONT_FAMILY = 'Roboto';

const TRANSPARENT = 'transparent';
const BLACK = 'black';
const WHITE = 'white';
const OFF_BLACK = '#1B1B1B';
const OFF_WHITE = '#fbfdfe';
const RED = 'red';

const GREEN = '#1D9B06';

const DefaultThemes = {
  color: {
    completedOrderMessage: BLACK,
    barcodeMemberNumber: BLACK,
    closeIconColor: BLACK,
    fieldIcons: 0,
    textColor: BLACK,
    labelColor: 'rgba(0,0,0,0.6)', //focused or not
    fieldBorderFocus: 'blue',
    fieldDefaultBorder: 'silver',
    fieldHoverBorderColor: 'blue',
    fieldBackground: TRANSPARENT,
    helperColor: RED,
    helperBackground: TRANSPARENT,
    labelBackgroundColor: TRANSPARENT,
    materialTextFieldErrorBorder: RED,
    materialDatePickerPlaceholderColor: 'rgba(0,0,0,0.6)',
    caretColor: BLACK,
    checkboxLabelColor: BLACK,
    defaultCheckboxColor: BLACK,
    checkboxCheckedColor: RED,
    filterTitle: BLACK,
    dateFilterTitle: BLACK,
    filterIconColors: BLACK,
    loyaltyHeaderInfo: BLACK,
    loyaltySubTitle: BLACK,
    loyaltyHeader: BLACK,
    loyaltyBalanceText: BLACK,
    loyaltyBalanceDefault: BLACK,
    loyaltyWalletTitle: BLACK,
    memberMoney: BLACK,
    memberPointsTitle: BLACK,
    balanceAmountMemberModal: BLACK,
    loyaltyGreeting: BLACK,
    loyaltyRing: 'indianred',
    giftCardValueButton: 'rgb(120,120,120)',
    giftCardOptionDefault: BLACK,
    giftCardOptionActive: WHITE,
    activeHeartIcon: 'indianred',
    resetFilters: 'grey',
    paginationActive: WHITE,
    paginationDefault: BLACK,
    loyaltySaleType: 'indianred',
    loyaltyUserDetails: 'indianred',
    balanceAmountCheckout: WHITE,
    balanceIconCheckout: WHITE,
    balanceIconMemberModal: BLACK,
    balanceLabelCheckout: WHITE,
    balanceLabelMemberModal: BLACK,
    banner: WHITE,
    cartCount: GREEN,
    categoryBarCurrentIndicator: WHITE,
    menuBrandsBarCurrentIndicator: WHITE,
    locationBrandBarCurrentIndicator: GREEN,
    categoryBarLabel: WHITE,
    menuBrandsBarLabel: WHITE,
    locationBrandBarLabel: BLACK,
    checkoutButton: WHITE,
    checkoutSignInPrompt: WHITE,
    checkoutSignInPromptIcon: WHITE,
    checkoutSignInPromptReason: WHITE,
    choiceSetCheckbox: WHITE,
    completedOrderTitle: GREEN,
    defaultButton: WHITE,
    defaultText: BLACK,
    deliveryDetailAddress: 'rgba(0,0,0,0.8)',
    deliveryDetailTime: 'rgba(0,0,0,0.8)',
    deliveryDetailTimeETA: GREEN,
    deliveryEstimateDetail: 'rgba(0,0,0,0.7)',
    deliveryTrackingLink: GREEN,
    desiredDeliveryAddressNotFoundIcon: 'orange',
    emptyCartMessage: 'rgba(0,0,0,0.85)',
    giftCardApplyButton: WHITE,
    giftCardApplyButtonIcon: GREEN,
    headerBrandingName: WHITE,
    headerCartIcon: OFF_WHITE,
    headerQuantity: WHITE,
    locationDetail: 'rgba(0,0,0,0.8)',
    locationDetailIcon: GREEN,
    lookupPromoCodeButton: WHITE,
    memberBalancesTitleCheckout: WHITE,
    menuCategoryHeadingCount: 'rgba(0,0,0,0.5)',
    menuItemDescription: 'rgba(0,0,0,0.7)',
    menuItemEnergySuffix: 'rgba(0,0,0,0.6)',
    menuItemSizeDescription: 'rgba(0,0,0,0.6)',
    menuSearchResultCount: WHITE,
    minimumOrderAmount: GREEN,
    modalButtonIcon: WHITE,
    modalButtonLabel: WHITE,
    modalFloatingTitle: WHITE,
    modalTitle: BLACK,
    noGuestOrderingLink: GREEN,
    offerInfoButton: GREEN,
    orderMessageIcon: 'orange',
    orderPropertyButton: BLACK,
    orderPropertyButtonIcon: GREEN,
    orderTotalDeemphasised: 'rgba(0,0,0,0.6)',
    paymentMethodIcon: GREEN,
    purchaseEditorDescription: 'rgba(0,0,0,0.7)',
    purchaseEditorEnergySuffix: 'rgba(0,0,0,0.6)',
    quantityPickerIcon: GREEN,
    removeOfferButton: WHITE,
    removeSavedCardButton: 'rgba(0,0,0,0.8)',
    removeSavedCardButtonIcon: GREEN,
    saveCardIcon: GREEN,
    saveCardLabel: 'rgba(0,0,0,0.8)',
    savedCardNumber: 'rgba(0,0,0,0.6)',
    paymentMethodAvailableBalance: 'rgba(0,0,0,0.6)',
    scrollForMoreIndicator: WHITE,
    sectionTitle: BLACK,
    selectedTagButton: WHITE,
    signInLink: BLACK,
    stagedPurchaseButton: 'rgba(0,0,0,0.9)',
    stagedPurchaseButtonIcon: GREEN,
    stagedPurchaseMultiplicationSymbol: GREEN,
    stagedPurchaseSubLine: 'rgba(0,0,0,0.8)',
    tagButton: BLACK,
    purchaserInvalidIcon: 'orange',
    locationMapMarker: '#E22C29',
    locationMapMarkerSelected: '#18a702',
    timeInErrorMessage: 'orange',
    deliveryDetailEditIcon: GREEN,
    menuLocationDetailsName: WHITE,
    fastOrderButton: WHITE,
    appLoadingSpinner: WHITE,
    fallbackToPickupButton: BLACK,
    fraudPreventionMessageIcon: 'orange',
    orderStatusStepIcon: BLACK,
    orderStatusStepIconCompleted: GREEN,
    noKnownUserLocationMessage: BLACK,
    deliveryTimeMayVaryMessage: '#E22C29',
    memberButton: WHITE,
    memberLoyaltyButtons: BLACK,
    signOutButton: BLACK,
    activeMemberButton: BLACK,
    activeSubButton: BLACK,
    activeMemberButtonLoyaltySideBar: 'indianred',
    activeSubRoute: 'indianred',
    defaultDropdownMenuButton: BLACK,
    defaultSubButton: BLACK,
    defaultMemberButtonLoyaltySideBar: BLACK,
    defaultSubRoute: BLACK,
    sliderIcon: BLACK,
    filterText: BLACK,
    orderOnlineButton: 'red',
    createNewFavourite: 'white',
    cartItemError: '#E22C29',
    cartItemErrorAlert: '#E22C29',
    cartItemWarning: '#ff9800',
    cartItemWarningAlert: '#ff9800',
    STOCK_BALANCE_THRESHOLD_0: '#E22C29',
    STOCK_BALANCE_THRESHOLD_1: '#ff9800',
    STOCK_BALANCE_THRESHOLD_2: BLACK,
    STOCK_BALANCE_THRESHOLD_3: BLACK,
    STOCK_BALANCE_THRESHOLD_4: BLACK,
  },
  backgroundColor: {
    orderOnlineButton: TRANSPARENT,
    createNewFavourite: 'red',
    loyaltyMobileBackground: 'white',
    loyaltyFilterModals: 'white',
    loyaltyModals: 'white',
    loyaltyBackground: 'whitesmoke',
    giftCardValueButton: TRANSPARENT,
    giftCardOptionDefault: WHITE,
    giftCardOptionActive: BLACK,
    orderOnline: 'rgb(195,21,22)',
    paginationActive: BLACK,
    paginationDefault: TRANSPARENT,
    app: WHITE,
    appLoadingOverlay: 'rgba(0,0,0,0.75)',
    input: WHITE,
    balancesSeparatorCheckout: WHITE,
    balancesSeparatorMemberModal: BLACK,
    banner: OFF_BLACK,
    bannerWithProblem: '#777777',
    cancelMenuSearchButton: TRANSPARENT,
    fallbackToPickupButton: TRANSPARENT,
    // checkoutButton: GREEN,
    choiceSetCheckbox: '#CF2634',
    defaultButton: GREEN, // '#1D1D1D',
    // deliveryTimeSelect: WHITE,
    // giftCardApplyButton: TRANSPARENT,
    headerBarFloating: 'rgba(0,0,0,0.8)',
    headerBarHero: OFF_BLACK,
    headerQuantity: 'green',
    initialScreenVerticalSeparator: BLACK,
    // locationListElement: WHITE,
    locationListElementSelected: 'rgba(22, 151, 2, 0.1)',
    locationSearchBar: '#F8F8F8',
    // lookupPromoCodeButton: GREEN,
    menuCategoriesBar: GREEN,
    menuBrandsBar: '#CF2634', //'#2C4A8E', // TODO: fix
    // modalDesktop: WHITE,
    // modalMobile: WHITE,
    menuSearchBar: GREEN,
    modalOverlay: 'rgba(0,0,0,0.75)',
    modalOverlayCallout: 'rgba(0,0,0,0.3)',
    // modalProceedButton: GREEN,
    // newOrderButton: GREEN,
    // orderPickupTimeSelect: WHITE,
    orderTotalsSeperator: 'rgba(0,0,0,0.2)',
    removeOfferButton: '#CF2634',
    screen: WHITE,
    scrollForMoreIndicator: '#28dd08',
    selectedTagButton: GREEN,
    // signInSubmitButton: 'green',
    signOutButton: TRANSPARENT,
    tagButton: TRANSPARENT,
    reviewOrderScreenDesktopSideOuter: 'rgba(0,0,0,0.6)',
    menuItem: WHITE,
    fastOrderButton: OFF_BLACK,
    locationBrandBar: WHITE,
    noKnownUserLocationMessage: '#ffc107',
    menuItemOpacityBackstop: 'white',
    cartItemErrorAlert: '#E22C29',
    cartItemWarningAlert: '#ff9800',
    memberButton: TRANSPARENT,
    memberLoyaltyButtons: TRANSPARENT,
    activeMemberButton: TRANSPARENT,
    activeSubButton: TRANSPARENT,
    defaultDropdownMenuButton: TRANSPARENT,
    defaultSubButton: TRANSPARENT,
    activeMemberButtonLoyaltySideBar: TRANSPARENT,
    defaultMemberButtonLoyaltySideBar: TRANSPARENT,
    defaultSubRoute: TRANSPARENT,
    activeSubRoute: TRANSPARENT,
    loyaltyCheckoutButton: 'indianred',
  },
  fontFamily: {
    defaultText: DEFAULT_FONT_FAMILY,
    signInLink: DEFAULT_FONT_FAMILY, // TODO: rework this to use Text component
  },
  fontSize: {
    completedOrderMessage: 22,
    barcodeMemberNumber: 14,
    giftCardValueButton: 15,
    giftCardOptionDefault: 15,
    giftCardOptionActive: 15,
    loyaltySubTitle: 15,
    orderOnline: 15,
    loyaltyHeaderInfo: 13,
    loyaltyDefaultText: 11,
    filterSaleTypes: 11,
    dateFilterTitle: 11,
    loyaltySubHeaders: 11,
    filterTitle: 12,
    resetFilters: 10,
    loyaltyOrderTotal: 12,
    loyaltyOrderItems: 12,
    loyaltyStoreLocation: 12,
    loyaltySaleType: 12,
    loyaltyOrderDate: 12,
    loyaltyHeader: 20,
    loyaltyUserDetails: 14,
    defaultBarcode: 14,
    loyaltyBalanceDefault: 35,
    loyaltyRingView: 80,
    loyaltyGreeting: 20,
    appLoadingSpinner: 150,
    balanceAmountCheckout: 20,
    balanceAmountMemberModal: 20,
    balanceIconCheckout: 45,
    balanceIconMemberModal: 45,
    balanceLabelCheckout: 13,
    balanceLabelMemberModal: 13,
    checkoutButton: 20,
    checkoutSignInPrompt: 20,
    checkoutSignInPromptIcon: 40,
    choiceSetCheckbox: 20,
    choiceSetCheckboxIcon: 30,
    completedOrderDetail: 30,
    completedOrderTitle: 22,
    defaultButton: 19,
    dynamicHeaderMemberButton: '3.5vw',
    defaultText: 16,
    deliveryDetailAddress: 15,
    deliveryDetailTime: 17,
    deliveryDetailTimeETA: 17,
    deliveryTimeSelect: 18,
    deliveryTrackingLink: 22,
    editDeliveryEstimateButton: 15,
    emptyCartMessage: 15,
    giftCardApplyButton: 15,
    headerBackButton: 23,
    headerBackButtonIcon: 30,
    headerBrandingNameDesktop: 25,
    headerBrandingNameMobile: 15,
    headerCartIcon: 30,
    dynamicCartIcon: '5vw',
    menuSearchIcon: 30,
    locationDetail: 12,
    locationDetailIcon: 14,
    locationListElementAddress: 13,
    locationListElementDetail: 13,
    locationListElementDistance: 12,
    locationSearchResultCount: 10,
    memberBalancesTitleCheckout: 20,
    menuCategoryHeading: 25,
    menuCategoryHeadingCount: 25,
    menuItemDescription: 14,
    menuItemEnergySuffix: 13,
    menuItemSizeDescription: 12,
    menuSearchResultCount: 13,
    modalButtonIcon: 35,
    modalButtonLabel: 16,
    modalFloatingTitle: 20,
    modalProceedButton: 20,
    modalTitle: 30,
    newOrderButton: 25,
    noResultsInMenuSearchMessage: 20,
    orderMessage: 13,
    orderMessageIcon: 15,
    orderOfferButton: 16,
    orderPickupTimeSelect: 18,
    orderTableNumber: 18,
    orderTotal: 16,
    orderTotalDeemphasised: 14,
    paymentMethod: 17,
    paymentMethodIcon: 22,
    paymentMethodUnavailableReason: 12,
    purchaseEditorEnergySuffix: 13,
    quantityPicker: 30,
    quantityPickerIcon: 40,
    removeSavedCardButton: 14,
    saveCardLabel: 14,
    savedCardNumber: 14,
    paymentMethodAvailableBalance: 14,
    sectionTitle: 17,
    signInLink: 16,
    stagedPurchase: 16,
    stagedPurchaseButton: 13,
    stagedPurchaseButtonIcon: 15,
    stagedPurchaseSubLine: 13,
    tagButton: 15,
    timeInErrorMessage: 13,
    deliveryDetailEdit: 13,
    deliveryDetailEditIcon: 16,
    // giftCardApplyButtonIcon: 18,
    menuLocationDetailsName: 35,
    locationBrandBarLabel: 15,
    fallbackToPickupButton: 19,
    fraudPreventionMessageIcon: 40,
    orderStatusStepIcon: 35,
    noKnownUserLocationMessageIcon: 18,
    memberLoyaltyButtons: 16,
    signOutButton: 14,
    activeSubButton: 12,
    activeMemberButton: 14,
    orderOnlineButton: 14,
    defaultDropdownMenuButton: 14,
    defaultSubButton: 12,
    activeMemberButtonLoyaltySideBar: 14,
    activeSubRoute: 12,
    defaultMemberButtonLoyaltySideBar: 14,
    defaultSubRoute: 12,
  },
  fontWeight: {
    completedOrderMessage: '500',
    barcodeMemberNumber: '500',
    giftCardOptionDefault: '400',
    giftCardOptionActive: '500',
    loyaltySubTitle: '500',
    dateFilterTitle: '500',
    loyaltySubHeaders: '500',
    filterTitle: '500',
    loyaltyOrderTotal: '500',
    loyaltyStoreLocation: '500',
    loyaltySaleType: '500',
    loyaltyHeader: '500',
    loyaltyUserDetails: '500',
    loyaltyWalletTitle: '500',
    loyaltyBalanceDefault: '500',
    loyaltyGreeting: '500',
    activeSubRoute: '500',
    activeSubButton: '500',
    activeMemberButtonLoyaltySideBar: '500',
    defaultMemberButtonLoyaltySideBar: '500',
    defaultSubRoute: '500',
    activeMemberButton: '500',
    defaultDropdownMenuButton: '400',
    orderOnlineButton: '400',
    defaultSubButton: '400',
    signOutButton: '400',
    balanceAmountMemberModal: '500',
    banner: '500',
    cartCount: '700',
    categoryBarLabel: '500',
    menuBrandsBarLabel: '500',
    locationBrandBarLabel: '400',
    checkoutSignInPrompt: '500',
    choiceSetCheckbox: '500',
    completedOrderDetail: '700',
    completedOrderTitle: '500',
    defaultButton: '500',
    deliveryDetailTime: '500',
    deliveryDetailTimeETA: '500',
    deliveryTimeSelect: '500',
    deliveryTrackingLink: '700',
    // giftCardApplyButton: '400',
    headerBackButton: '500',
    headerMemberButton: '500',
    headerPickupTimeSelect: '500',
    headerTableNumber: '500',
    locationListElementAddress: '300',
    menuItemEnergySuffix: '500',
    menuItemName: '500',
    menuItemPrice: '500',
    minimumOrderAmount: '700',
    modalButtonLabel: '500',
    modalFloatingTitle: '500',
    modalProceedButton: '500',
    modalTitle: '500',
    newOrderButton: '700',
    noGuestOrderingLink: '700',
    offerName: '500',
    orderMessage: '500',
    orderPickupTimeSelect: '500',
    orderPropertyButton: '500',
    orderTableNumber: '500',
    orderTotal: '700',
    orderTotalDeemphasised: '500',
    purchaseEditorEnergySuffix: '500',
    purchaseEditorName: '500',
    removeSavedCardButton: '400',
    sectionTitle: '500',
    stagedPurchase: '500',
    stagedPurchaseButton: '400',
    stagedPurchaseMultiplicationSymbol: '700',
    tagButton: '400',
    deliveryDetailEdit: '500',
    menuLocationDetailsName: '700',
    STOCK_BALANCE_THRESHOLD_0: '500',
    STOCK_BALANCE_THRESHOLD_1: '500',
    STOCK_BALANCE_THRESHOLD_2: '500',
    STOCK_BALANCE_THRESHOLD_3: '500',
    STOCK_BALANCE_THRESHOLD_4: '500',
  },
  letterSpacing: {
    menuItemEnergySuffix: 1,
    purchaseEditorEnergySuffix: 1,
    savedCardNumber: 1,
    stagedPurchaseButton: 0.25,
  },
  opacity: {
    giftCardValueButton: 1,
    giftCardOptionDefault: 1,
    giftCardOptionActive: 1,
    orderOnline: 1,
    categoryBarLabelCurrent: 0.7,
    menuBrandsBarLabelCurrent: 0.7,
    locationBrandBarCurrent: 0.7,
    locationSearchResultCount: 0.9,
    memberLoyaltyButtons: 1,
    activeSubButton: 1,
    activeMemberButton: 1,
    activeSubRoute: 1,
    activeMemberButtonLoyaltySideBar: 1,
    defaultDropdownMenuButton: 1,
    orderOnlineButton: 1,
    defaultSubButton: 1,
    defaultMemberButtonLoyaltySideBar: 1,
    defaultSubRoute: 1,
    createNewFavourite: 1,
  },
  padding: {
    giftCardOptionDefault: '5px 5px',
    giftCardOptionActive: '5px 5px',
    banner: '10px 15px',
    cancelMenuSearchButton: '0 10px',
    defaultButton: 15,
    deliveryTimeSelect: '10px 10px 10px 5px',
    editDeliveryEstimateButton: 10,
    giftCardApplyButton: '11px 20px 11px 20px',
    headerBackButton: 0,
    headerBar: '10px 15px',
    headerCartIcon: '5px 12px',
    headerMemberButton: 10,
    headerPickupTimeSelect: '3px 10px',
    headerTableNumber: '7px 0px',
    memberOffers: 13,
    modalProceedButton: 20,
    orderPickupTimeSelect: '10px 10px 10px 5px',
    orderTableNumber: 10,
    removeSavedCardButton: '5px 0',
    stagedPurchaseButton: 0,
    tagButton: '9px 11px',
  },
  borderRadius: {
    giftCardValueButton: 5,
    giftCardOptionDefault: 20,
    giftCardOptionActive: 20,
    choiceSetCheckbox: 0,
    defaultButton: 2,
    deliveryTimeSelect: 5,
    modalProceedButton: 0,
    orderPickupTimeSelect: 5,
    tagButton: 20,
    giftCardApplyButton: 5,
    orderOnline: 5,
  },
  border: {
    loyaltyFilterBorder: '1px solid black',
    giftCardValueButton: '1px solid silver',
    banner: '1px solid rgba(255, 255, 255, 0.1)',
    defaultButton: 'none',
    deliveryTimeSelect: '1px solid rgba(0, 0, 0, 0.1)',
    modalProceedButton: '1px solid rgba(0, 0, 0, 0.1)',
    orderPickupTimeSelect: '1px solid rgba(0, 0, 0, 0.1)',
    orderTableNumber: '1px solid rgba(0, 0, 0, 0.35)',
    scrollForMoreIndicator: '1px solid rgba(0, 0, 0, 0.1)',
    selectedTagButton: '1px solid transparent',
    tagButton: '1px solid rgba(0, 0, 0, 0.1)',
  },
  borderLeft: {
    activeMemberButton: '2px solid indianred',
  },
  outline: {
    menuItemHovered: '1.5px solid black',
  },
  boxShadow: {
    giftCardValueButton: 'none',
    giftCardOptionDefault: 'none',
    giftCardOptionActive: 'none',
    // https://tailwindcss.com/docs/box-shadow#outer-shadow
    cancelMenuSearchButton: 'none',
    fallbackToPickupButton: 'none',
    completedOrderScreenDesktop: '6px 6px 20px -6px rgba(0,0,0,0.75)',
    defaultButton: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    // giftCardApplyButton: 'none',
    headerBar: '0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.09)',
    initialScreenDesktop: '6px 6px 20px -6px rgba(0,0,0,0.75)',
    menuCategoriesBar: '0 4px 3px -1px rgba(0, 0, 0, 0.1), 0 2px 2px -1px rgba(0, 0, 0, 0.09)',
    modalCallout: '0px 4px 15px 2px rgba(0,0,0,0.6)',
    reviewOrderScreenDesktop: '6px 6px 20px -6px rgba(0,0,0,0.75)',
    scrollForMoreIndicator: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    tagButton: 'none',
    memberLoyaltyButtons: 'none',
    signOutButton: 'none',
    activeMemberButton: 'none',
    activeSubButton: 'none',
    defaultDropdownMenuButton: 'none',
    orderOnlineButton: 'none',
    defaultSubButton: 'none',
    activeMemberButtonLoyaltySideBar: 'none',
    defaultMemberButtonLoyaltySideBar: 'none',
    defaultSubRoute: 'none',
    activeSubRoute: 'none',
  },
  textDecoration: {
    deliveryTrackingLink: 'none',
    activeMemberButtonLoyaltySideBar: 'underline',
    activeSubRoute: 'underline',
  },
  maxHeight: {
    headerBrandingImage: '100%',
  },
  height: {
    purchaseEditorItemImage: 160,
    menuItemImage: 130,
  },
  textAlign: {
    defaultBarcode: 'right',
  },
};

export default DefaultThemes;
