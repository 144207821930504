import { put, all } from 'redux-saga/effects';

import {
  setBufferDeliveryProvider,
  setBufferDeliverySurcharges,
  setBufferDeliveryTime,
  setBufferLocationId,
  setBufferMinimumOrderInformation,
  setBufferOrderingProviderSurchargeInstructions,
  setBufferTimeEstimateReturned,
} from '../actionCreators/buffer';

export default function* applyLocationEstimate(
  locationEstimate: LocationDeliveryEstimate,
) {
  yield all([
    put(setBufferDeliveryProvider(locationEstimate.deliveryProvider)),
    put(setBufferDeliverySurcharges(locationEstimate.surcharges)),
    put(setBufferDeliveryTime(locationEstimate.bestDeliveryTime)),
    put(setBufferTimeEstimateReturned(locationEstimate.timeEstimateReturned)),
    put(setBufferLocationId(locationEstimate.locationId)),
    put(
      setBufferMinimumOrderInformation(
        locationEstimate.minimumOrderInformation,
      ),
    ),
    put(
      setBufferOrderingProviderSurchargeInstructions(
        locationEstimate.orderingProviderSurchargeInstructions,
      ),
    ),
  ]);
}
