import { createSelector } from 'reselect';
import lodash from 'lodash';

import getLocations from './getLocations';
import { getShowHiddenLocations } from './config';

export default createSelector(
  [getLocations, getShowHiddenLocations],
  (locations, showHiddenLocations): SDict<POSLocation> => {
    const locationsForDisplay: SDict<POSLocation> = {};

    const blacklist = [0, undefined, null];

    lodash.values(locations || {}).forEach(location => {
      if (
        !blacklist.includes(location.latitude) &&
        !blacklist.includes(location.longitude) &&
        (!location.hidden || showHiddenLocations)
      ) {
        locationsForDisplay[location.id] = location;
      }
    });

    return locationsForDisplay;
  },
);
