import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors } from 'polygon-ordering';
import StagedPurchase from './StagedPurchase';
import Text from './Text';
import { useAppSelector } from '../app/hooks';

const { getStagedPurchases, getStockBalances, getCartContainsInvalidQuantities } =
  OrderingSelectors;

interface IProps {
  readOnly?: boolean;
}

const Cart: React.FC<IProps> = ({ readOnly }) => {
  const { t } = useTranslation();
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const stockBalances = useAppSelector(getStockBalances);
  const cartHasInvalidItems = useAppSelector(getCartContainsInvalidQuantities);

  const [updatedPLUQuantities, setUpdatedPLUQuantities] = useState({});
  useEffect(() => {
    //@ts-ignore
    const trackPLUUpdates = cartHasInvalidItems.trackQuantityUpdates;
    setUpdatedPLUQuantities(trackPLUUpdates);
  }, [stockBalances, stagedPurchases]);

  return (
    <div style={styles.mainContainer}>
      {stagedPurchases?.length ? (
        stagedPurchases.map(purchase => (
          <StagedPurchase
            key={purchase.id}
            purchase={purchase}
            readOnly={readOnly}
            qtyUpdates={updatedPLUQuantities}
          />
        ))
      ) : (
        <div style={styles.emptyCartMessageContainer}>
          <Text themeKey="emptyCartMessage" value={t('emptyCartMessage')} style={styles.message} />
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    padding: 9,
  },
  emptyCartMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  message: {
    textAlign: 'center',
  },
};

export default Cart;
