import React from 'react';
import appleWallet from '../images/apple.svg';
import googleWallet from '../images/google.svg';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { OrderingSelectors } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';

const { getMember } = OrderingSelectors;

interface IProps {
  MemberNo?: string;
  VerifyPIN?: string;
  giftCard?: boolean;
}

interface Packet {
  WalletType: number;
  CardType: string;
  MemberNo?: string;
  VerifyPIN?: string;
}

const Wallet: React.FC<IProps> = ({ MemberNo, VerifyPIN, giftCard }) => {
  const member = useAppSelector(getMember);

  const handleWalletClick = (type: number) => {
    //Apple type:1
    //Google type:2
    //Gift card : gc
    //default: hc

    let packet: Packet = {
      WalletType: type,
      CardType: giftCard ? 'gc' : 'hc',
    };
    if (giftCard) {
      packet.MemberNo = MemberNo;
      packet.VerifyPIN = VerifyPIN;
    }

    const path = '/api/v1/wallet/generate_wallet_card';

    const fetch = member ? RedcatApiHandler.authorisedFetch : RedcatApiHandler.fetch;

    fetch({
      method: 'POST',
      path,
      body: packet,
    })
      .then(res => {
        if (type === 1) {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(res);
          window.location.href = downloadUrl;
        }
        if (res.success) {
          if (type === 2) {
            //@ts-ignore
            window.open(res.redirect_url);
          }
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };
  return (
    <div className="text-center">
      <img
        src={appleWallet}
        style={{ height: 40, cursor: 'pointer' }}
        className="img-fluid my-2"
        onClick={() => handleWalletClick(1)}
      />
      <img
        src={googleWallet}
        className="img-fluid my-2"
        onClick={() => handleWalletClick(2)}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default Wallet;
