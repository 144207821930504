import React, { useRef, useState, useEffect } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { setMenuCategoriesBarHeight } from '../slices/menuCategoriesBarHeight';
import { setCurrentMenuBrandId } from '../slices/currentMenuBrandId';

import getThemeLookup from '../selectors/getThemeLookup';
import getFilteredMenuItemCount from '../selectors/getFilteredMenuItemCount';
import getFilteredRootCategory from '../selectors/getFilteredRootCategory';
import getLocationBrandsWithMenuRepresentation from '../selectors/getLocationBrandsWithMenuRepresentation';
import getCurrentCategoryId from '../selectors/getCurrentCategoryId';
import getCurrentMenuBrandId from '../selectors/getCurrentMenuBrandId';

import combineStyles from '../utils/combineStyles';
import debug from '../utils/debug';
import { logEvent } from '../utils/analytics';

import EVENTS from '../constants/events';

import HorizontalScrollableMenu from './HorizontalScrollableMenu';
import MenuSearchBar from './MenuSearchBar';
import MenuSearchButton from './MenuSearchButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const MenuCategoriesBar: React.FC<{
  jumpToTop: () => void;
  setCurrentCategoryId: (value: string) => void;
}> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const { jumpToTop, setCurrentCategoryId } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const rootCategory = useAppSelector(getFilteredRootCategory);
  const representedBrands = useAppSelector(getLocationBrandsWithMenuRepresentation);
  const currentCategoryId = useAppSelector(getCurrentCategoryId);
  const currentMenuBrandId = useAppSelector(getCurrentMenuBrandId);
  const itemCount = useAppSelector(getFilteredMenuItemCount);
  const menuCategoriesBarHeight = useAppSelector(state => state.menuCategoriesBarHeight);
  const disableAllBrandsMenuTab = useAppSelector(state => state.config.disableAllBrandsMenuTab);

  const brandIdChangedRef = useRef(currentMenuBrandId);
  const toggleSearchActive = () => {
    logEvent(searchActive ? EVENTS.CLOSE_MENU_SEARCH : EVENTS.OPEN_MENU_SEARCH);
    setSearchActive(!searchActive);
  };

  useEffect(() => {
    jumpToTop();
    try {
      const updatedHeight = ref.current!.offsetHeight;

      if (menuCategoriesBarHeight !== updatedHeight) {
        dispatch(setMenuCategoriesBarHeight(updatedHeight));
      }
    } catch (e) {
      debug(false, 'failed to set new menuCategoriesBarHeight');
    }
  }, [searchActive, currentMenuBrandId]);

  useEffect(() => {
    if (brandIdChangedRef.current === currentMenuBrandId) {
      jumpToTop();
    }
    try {
      const updatedHeight = ref.current!.offsetHeight;

      if (menuCategoriesBarHeight !== updatedHeight) {
        dispatch(setMenuCategoriesBarHeight(updatedHeight));
      }
    } catch (e) {
      debug(false, 'failed to set new menuCategoriesBarHeight');
    }
  }, [itemCount]);

  const urlBrandId = queryString.parse(window.location.search);

  if (urlBrandId && urlBrandId.brand_id) {
    dispatch(setCurrentMenuBrandId(urlBrandId.brand_id as string));
  }

  const categories = rootCategory?.subCategories || [];

  const brandTabs: Brand[] = [...representedBrands];

  if (!disableAllBrandsMenuTab) {
    //@ts-ignore
    brandTabs.unshift({ name: t('allBrandsLabel'), id: '' }); //not a brand type
  }

  return (
    <div
      ref={ref}
      className="sticky"
      style={combineStyles(styles.outerContainer, p('menuCategoriesBar', ['boxShadow']))}
    >
      {representedBrands.length > 1 && (
        <div style={combineStyles(styles.rowContainer, p('menuBrandsBar', ['backgroundColor']))}>
          <HorizontalScrollableMenu
            items={brandTabs}
            currentItemId={currentMenuBrandId}
            setCurrentItemId={id => {
              dispatch(setCurrentMenuBrandId(id));
              logEvent(EVENTS.CHANGE_MENU_BRAND_FILTER, { value: parseInt(id) });
            }}
            labelThemeKey={'menuBrandsBarLabel'}
            currentLabelThemeKey={'menuBrandsBarLabelCurrent'}
            currentIndicatorThemeKey={'menuBrandsBarCurrentIndicator'}
            iconSize={35}
            containerBackgroundColor={p('menuBrandsBar', ['backgroundColor']).backgroundColor}
          />
        </div>
      )}

      <div
        style={combineStyles(
          styles.rowContainer,
          searchActive && styles.menuSearchContainer,
          p('menuCategoriesBar', ['backgroundColor']),
          searchActive && p('menuSearchBar', ['backgroundColor', 'boxShadow']),
        )}
      >
        {searchActive ? (
          <MenuSearchBar toggleSearchActive={toggleSearchActive} />
        ) : (
          <>
            <HorizontalScrollableMenu
              items={categories}
              currentItemId={currentCategoryId}
              setCurrentItemId={value => {
                setCurrentCategoryId(value);
                logEvent(EVENTS.JUMP_TO_MENU_CATEGORY, { label: value });
              }}
              labelThemeKey={'categoryBarLabel'}
              currentLabelThemeKey={'categoryBarLabelCurrent'}
              currentIndicatorThemeKey={'categoryBarCurrentIndicator'}
              containerBackgroundColor={p('menuCategoriesBar', ['backgroundColor']).backgroundColor}
            />

            <MenuSearchButton toggleSearchActive={toggleSearchActive} />
          </>
        )}
      </div>
    </div>
  );
};

const styles: Styles = {
  outerContainer: {
    zIndex: 2, // prevent the header bar box shadow from appearing on top of this
    top: 0, // kicks in during sticky mode
    width: '100%',
  },
  rowContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.05)',

    width: '100%',
    paddingBottom: 0,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',

    // zIndex: 2, // prevent the header bar box shadow from appearing on top of this
  },
  menuSearchContainer: {
    paddingTop: 15,
    paddingBottom: 10,
  },
};

export default MenuCategoriesBar;
