import { createSelector } from 'reselect';

import getDeliveryProvider from './getDeliveryProvider';
import getDeliveryAddress from './getDeliveryAddress';
import getDeliveryTime from './getDeliveryTime';
import getDeliveryNotes from './getDeliveryNotes';

import combineAddressParts from '../utils/ordering/combineAddressParts';

function createSubmissionAddress(address: TokenisedAddress | null) {
  if (!address) {
    return undefined;
  }

  const { unit, streetNumber, streetName, suburb, state, postcode } = address;

  return {
    StreetName: combineAddressParts([
      ...(streetNumber != null && unit != null
        ? [`${unit}/${streetNumber}`]
        : [unit, streetNumber]),
      streetName,
    ]),
    Suburb: suburb,
    State: state,
    Postcode: postcode,
  };
}

export default createSelector(
  [getDeliveryProvider, getDeliveryAddress, getDeliveryTime, getDeliveryNotes],
  (deliveryProvider, deliveryAddress, deliveryTime, deliveryNotes) => ({
    DeliveryTime: deliveryTime,
    Address: createSubmissionAddress(deliveryAddress),
    DeliveryProvider: deliveryProvider?.id,
    DeliveryNotes: deliveryNotes,
  }),
);
