import { ActionType, getType } from 'typesafe-actions';

import { actions as fetchLocations } from '../actionCreators/flows/fetchLocations';

export default function (
  state: SDict<POSLocation> | null = null,
  action: ActionType<typeof fetchLocations>,
): SDict<POSLocation> | null {
  switch (action.type) {
    case getType(fetchLocations.succeeded): {
      const { locations, merge } = action.payload;

      if (state === null || !merge) {
        return locations;
      } else {
        return { ...state, ...locations };
      }
    }
    default:
      return state;
  }
}
