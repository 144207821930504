import lodash from 'lodash';
import moment from 'moment';

// TODO: move to polygon-utils lib
export function centsToDollars(cents: undefined | number) {
  return cents !== undefined ? cents / 100 : undefined;
}

export function dollarsToCents(dollars: number | string) {
  let dollarsNum: number;

  if (typeof dollars == 'string') {
    dollarsNum = parseFloat(dollars || '0');
  } else {
    dollarsNum = dollars;
  }

  return Math.round(dollarsNum * 100);
}

export function centsToDollarString(cents: undefined | number) {
  const dollars = centsToDollars(cents);

  if (dollars === undefined) {
    return '';
  }

  return String(dollars.toFixed(2));
}

// converts a potentially messy string into a clean id string for page anchors etc.
export function safeString(s: string | null | undefined): string {
  return (s || '')
    .trim()
    .replace(/[\W_]+/g, '-')
    .toLowerCase();
}

// TODO: re-write this to preserve type information (keys)
export function sumObjectProperties(
  arr: SDict<any>[],
  properties?: string[],
  baseValues: SDict<number | undefined> = {},
  defaultBaseValue: number = 0,
) {
  const result: SDict<number> = {};

  if (!arr.length && properties === undefined) {
    return result;
  }

  (properties === undefined ? Object.keys(arr[0]) : properties).forEach(
    property => {
      const base =
        baseValues[property] === undefined
          ? defaultBaseValue
          : baseValues[property] || 0;

      result[property] = lodash.sumBy(arr, property) + base;
    },
  );

  return result;
}

export function normaliseArray<T>(array: Array<T>, indexKey: keyof T) {
  const normalizedObject: any = {};
  for (let i = 0; i < array.length; i++) {
    const key = array[i][indexKey];
    normalizedObject[key] = array[i];
  }
  return normalizedObject as { [key: string]: T };
}

export function replaceOrAppend<T>(
  array: Array<T>,
  appendable: T | undefined,
  finder: (el: T) => boolean,
  replacer: (prev: T[]) => T | undefined,
): T[] {
  const firstMatchIndex = array.findIndex(finder);
  const matches = array.filter(finder).length;

  let before = [];
  let after = [];

  let result: Array<T | undefined> = [];

  if (matches === 0) {
    result = [...array, appendable];
  } else {
    before = array.slice(0, Math.max(firstMatchIndex, 0));

    after = array
      .slice(firstMatchIndex + 1, array.length)
      .filter(element => !finder(element));

    result = [...before, replacer(array.filter(finder)), ...after];
  }

  return result.filter(element => element !== undefined) as T[];
}

export function generateTwoWayMap(
  list: Record<string | number, string | number>[],
): Record<string | number, string | number> {
  return list.reduce(
    (acc, n) => ({ ...acc, [n.value]: n.id, [n.id]: n.value }),
    {},
  );
}

export function removeUndefinedKeys(raw: SDict<any>): SDict<any> {
  return lodash.pickBy(raw, v => v !== undefined);
}

export function safeDate(input?: string) {
  return input ? moment(input).toISOString() : undefined;
}

export function findPropertyMinAndMax(things: any[], property: string) {
  return {
    min: lodash.minBy(things, t => t[property])[property] as number,
    max: lodash.maxBy(things, t => t[property])[property] as number,
  };
}

export function undefinedIfNull(value: any) {
  return value === null ? undefined : value;
}

export function getItemPaymentTotal(selectedPaymentMethods: any[], methodLabel: string) {
  let amount = 0;
  selectedPaymentMethods.some(selectedPaymentMethod => {
    if (selectedPaymentMethod.method === methodLabel) {
      amount = selectedPaymentMethod.amount;
    }
  });
  return amount;
}

// function reduceObjectArray (
//   arr: SDict<any>[],
// ): SDict<any>[] {
//   const result: SDict<any[]> = {};

//   arr.forEach(obj => {
//     Object.keys(obj).forEach(key => {
//       result[key] = (result[key] || []).concat([obj[key]]);
//     });
//   });

//   return result;
// }
