import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import CreditCardProvider from './CreditCardProvider';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import SavedCardProvider from './SavedCardProvider';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import ApplePayProvider from './ApplePayProvider';
import AppleGooglePayButtons from './AppleGooglePayButtons';
import { useFormikContext } from 'formik';
import GooglePayProvider from './GooglePayProvider';
import { OrderingSelectors } from 'polygon-ordering';
import { setPaymentMethod } from '../slices/paymentMethod';

const { getMember } = OrderingSelectors;

const SuperPaymentForm = ({
  route,
  notRequired = [],
}: {
  route: string;
  notRequired?: string[];
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const paymentFormFilled = useAppSelector(state => state.paymentFormFilled);
  const paymentFormInUse = useAppSelector(state => state.paymentFormInUse);
  const paymentMethod = useAppSelector(state => state.paymentMethod);
  const savedCards = useAppSelector(state => state.savedCards);

  //reset payment method on mount
  useEffect(() => {
    if (paymentMethod) {
      dispatch(setPaymentMethod(null));
    }
  }, []);

  const member = useAppSelector(getMember);

  const formik = useFormikContext<FormikFields>();

  // check whether the form is filled and error free then disable
  const formDisabled =
    !formik.isValid ||
    formik.isSubmitting ||
    Object.entries(formik.values)
      .filter(pair => !notRequired.includes(pair[0]))
      .some(pair => pair[1] === '');

  const showAppleGooglePay =
    (paymentMethod?.method === PAYMENT_METHOD.GOOGLE_PAY ||
      paymentMethod?.method === PAYMENT_METHOD.APPLE_PAY) &&
    !formDisabled;

  const buttonLabel =
    route === 'giftCardTopUp' || route === 'memberCashTopUp'
      ? t('button.topupButton')
      : t('button.purchaseButton');

  return (
    <div>
      {member &&
        savedCards?.map(savedCard => (
          <SavedCardProvider key={savedCard.token} savedCard={savedCard} route={route} />
        ))}

      <CreditCardProvider route={route} />
      <ApplePayProvider />
      <GooglePayProvider />

      <div style={{ marginTop: 20 }}>
        {showAppleGooglePay ? (
          <AppleGooglePayButtons route={route} />
        ) : (
          <StandardButton
            disabled={formDisabled || paymentFormInUse || !paymentFormFilled}
            onClick={() => {
              dispatch(setPaymentFormInUse(true));
            }}
            themeKey="loyaltyButton"
            containerStyle={styles.button}
            label={buttonLabel}
            showSpinner={paymentFormInUse}
          />
        )}
      </div>
    </div>
  );
};
const styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default SuperPaymentForm;
