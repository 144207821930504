import React, { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
//@ts-ignore
import Barcode from 'react-barcode';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from '../components/Text';
import { OrderingSelectors } from 'polygon-ordering';

const { getMember } = OrderingSelectors;

interface IProps {
  barcodeOptions: BarcodeOptions;
  showMemberDetails?: boolean;
  showText?: boolean;
}

const RedcatBarcode: React.FC<IProps> = ({
  barcodeOptions,
  showMemberDetails,
  showText = true,
}) => {
  const p = useAppSelector(getThemeLookup);
  const deviceDimensions = useAppSelector(state => state.deviceDimensions);
  const member = useAppSelector(getMember);

  useEffect(() => {
    const barcodes = document.getElementsByClassName('barcode');
    for (let i = 0; i < barcodes.length; i++) {
      barcodes[i].querySelector('svg')!.setAttribute('height', '100%');
      barcodes[i].querySelector('svg')!.setAttribute('width', '100%');
    }
  });

  const options = {
    value: barcodeOptions.value,
    text: barcodeOptions.text,
    textAlign: !barcodeOptions.text ? 'center' : p('defaultBarcode', ['textAlign']).textAlign,
    fontSize: p('defaultBarcode', ['fontSize']).fontSize,
    font: p('defaultText', ['fontFamily']).fontFamily,
    displayValue: showText,
  };

  return (
    <div className="barcode">
      <Barcode {...options} />
      {showMemberDetails && (
        <div
          className="position-absolute"
          style={{
            bottom: deviceDimensions?.width! < 500 ? 3 : 10,
            left: deviceDimensions?.width! < 500 ? 5 : 14,
          }}
        >
          <Text
            themeKey="loyaltyUserDetails"
            value={`${member?.givenName?.toUpperCase()} ${member?.surname?.toUpperCase()}`}
          />
        </div>
      )}
    </div>
  );
};

export default RedcatBarcode;
