import { createSelector } from 'reselect';

import getMinimumOrderInformation, {
  $getMinimumOrderInformation,
} from './getMinimumOrderInformation';

import getOrderingProviderSurchargeInstructions, {
  $getOrderingProviderSurchargeInstructions,
} from './getOrderingProviderSurchargeInstructions';

import getStagedPurchasesTotals, {
  $getStagedPurchasesTotals,
} from './getStagedPurchasesTotals';

// enforcing minimum
import getMinimumSurchargeAmount from './getMinimumSurchargeAmount';

const get = (
  minInfo: MinimumOrderInformation | null,
  orderingProviderSurchargeInstructions: SurchargeInstruction[] | null,
  stagedPurchasesTotals: StagedPurchasesTotals,
  minimumSurcharge: number,
): Surcharge[] => {
  const result: Surcharge[] = [];

  if (minInfo && minInfo.surchargeEnabled) {
    //enforcing minimum

    const amount = minimumSurcharge;
    const minOrderSurcharge = {
      amount,
      description: minInfo.surchargeDescription || 'Minimum Order Surcharge',
      type: minInfo.surchargeType as number,
    };
    if (minOrderSurcharge && minOrderSurcharge.amount) {
      result.push(minOrderSurcharge);
    }
  }

  (orderingProviderSurchargeInstructions || []).forEach(instruction => {
    const surcharge = {
      ...instruction,
      amount: instruction.isPercentage
        ? Math.round(
            (stagedPurchasesTotals.moneyPrice * instruction.amount) / 100,
          )
        : instruction.amount,
    };

    if (surcharge.amount) {
      result.push(surcharge);
    }
  });

  return result;
};

export default createSelector(
  [
    getMinimumOrderInformation,
    getOrderingProviderSurchargeInstructions,
    getStagedPurchasesTotals,
    getMinimumSurchargeAmount,
  ],
  get,
);

export const $getOrderingProviderSurcharges = createSelector(
  [
    $getMinimumOrderInformation,
    $getOrderingProviderSurchargeInstructions,
    $getStagedPurchasesTotals,
    getMinimumSurchargeAmount,
  ],
  get,
);
