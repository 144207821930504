import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { GiHamburgerMenu } from 'react-icons/gi';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { MEMBER_MODAL_ID } from '../modals/MemberModal';
import { BiSliderAlt } from 'react-icons/bi';
import { PAST_ORDERS_SCREEN_ROUTE } from '../screens/PastOrdersScreen';
import { showLoyaltyHeaders } from '../slices/loyaltyHeaders';
import { useLocation } from 'react-router-dom';
import { FILTERS_MODAL_ID } from '../modals/FiltersModal';
import { NON_MEMBER_MODAL_ID } from '../modals/NonMemberModal';
import { OrderingSelectors } from 'polygon-ordering';
const { getMember } = OrderingSelectors;

const LoyaltyHeaderBar = () => {
  const deviceType = useAppSelector(state => state.deviceType);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const showFilterIcon = pathname === PAST_ORDERS_SCREEN_ROUTE ? true : false;
  const loyaltyHeaders = useAppSelector(state => state.loyaltyHeaders);
  const currentModal = useAppSelector(state => state.currentModalId);
  const member = useAppSelector(getMember);

  useEffect(() => {
    if (deviceType === 1) {
      dispatch(showLoyaltyHeaders(false));
    } else {
      dispatch(showLoyaltyHeaders(true));
    }
  }, [deviceType]);

  return (
    <div>
      {loyaltyHeaders && deviceType === 2 && (
        <div className="px-2 py-3 position-relative" style={{ backgroundColor: 'white' }}>
          <div>
            <TouchableOpacity
              onClick={() =>
                dispatch(
                  setCurrentModal({ modalId: member ? MEMBER_MODAL_ID : NON_MEMBER_MODAL_ID }),
                )
              }
            >
              <GiHamburgerMenu style={{ fontSize: 30 }} />
            </TouchableOpacity>
          </div>
          {showFilterIcon && deviceType === 2 && (
            <div className="py-3 px-2" style={{ position: 'absolute', right: 0, top: 0 }}>
              <TouchableOpacity
                onClick={() => {
                  if (currentModal === FILTERS_MODAL_ID) {
                    dispatch(setCurrentModal(null));
                  } else {
                    dispatch(setCurrentModal(FILTERS_MODAL_ID));
                  }
                }}
              >
                <BiSliderAlt style={{ fontSize: 30 }} />
              </TouchableOpacity>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LoyaltyHeaderBar;
