import { createSelector } from 'reselect';
import lodash from 'lodash';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import getMember from './getMember';
import getOutstandingAmountByPaymentMethod from './getOutstandingAmountByPaymentMethod';
import getNonVerifiedMemberPresent from './getNonVerifiedMemberPresent';
import getSelectedOffer from './getSelectedOffer';

import { PAYMENT_METHOD_UNAVAILABLE_REASON } from '../constants';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [
    getEnabledPaymentMethods,
    getMember,
    getOutstandingAmountByPaymentMethod,
    getNonVerifiedMemberPresent,
    getSelectedOffer,
  ],
  (
    enabledPaymentMethods,
    member,
    outstandingByPaymentMethod,
    nonVerifiedMemberPresent,
    selectedOffer,
  ): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_REWARDS)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    if (!Boolean(member)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_MISSING);
    }

    const validMemberPresent =
      !!member && !nonVerifiedMemberPresent && member.registered;

    if (!validMemberPresent) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_INVALID);
    }

    const outstanding =
      lodash.get(outstandingByPaymentMethod, PAYMENT_METHOD.MEMBER_REWARDS) ||
      0;

    const balance = member?.rewardsBalance || 0;

    if (outstanding > balance || !balance) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE);
    }

    if (balance <= 0) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE);
    }

    if (selectedOffer) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.OFFER_NOT_COMPATIBLE);
    }

    return reasons;
  },
);
