import lodash from 'lodash';
import { setFacebookAnalyticsKey } from '../slices/config/facebookAnalyticsKey';
import { setGoogleAnalyticsKey } from '../slices/config/googleAnalyticsKey';
import { setBrandingFavicon } from '../slices/config/brandingFavicon';
import { setCollapsableChoiceSetsEnabled } from '../slices/config/collapsableChoiceSetsEnabled';
import { setCollapseOptionalChoiceSetsByDefault } from '../slices/config/collapseOptionalChoiceSetsByDefault';
import { setDisableAllBrandsMenuTab } from '../slices/config/disableAllBrandsMenuTab';
import { setDisableCategoryCount } from '../slices/config/disableCategoryCount';
import { setDisableLoyalty } from '../slices/config/disableLoyalty';
import { setDisableMenuSearch } from '../slices/config/disableMenuSearch';
import { setDisableOffers } from '../slices/config/disableOffers';
import { setDisablePickupTimeSelection } from '../slices/config/disablePickupTimeSelection';
import { setDisplayPointsAsWholeNumber } from '../slices/config/displayPointsAsWholeNumber';
import { setEnableDeliveryNotes } from '../slices/config/enableDeliveryNotes';
import { setEnableAutoselectFirstLocation } from '../slices/config/enableAutoselectFirstLocation';
import { setEnableOrderNotes } from '../slices/config/enableOrderNotes';
import { setEnableReCaptcha } from '../slices/config/enableReCaptcha';
import { setFallbackLocation } from '../slices/config/fallbackLocation';
import { setFlattenDeliverySurcharges } from '../slices/config/flattenDeliverySurcharges';
import { setFloatingScreenBackgroundImagePath } from '../slices/config/floatingScreenBackgroundImagePath';
import { setGeocoderCountryRestriction } from '../slices/config/geocoderCountryRestriction';
import { setGoogleMapsApiKey } from '../slices/config/googleMapsApiKey';
import { setHeaderBrandImagePath } from '../slices/config/headerBrandImagePath';
import { setHeaderBrandingType } from '../slices/config/headerBrandingType';
import { setHeaderBrandName } from '../slices/config/headerBrandName';
import { setHeroScreenImagePath } from '../slices/config/heroScreenImagePath';
import { setHideMenuItemSizeDescription } from '../slices/config/hideMenuItemSizeDescription';
import { setHideLocationHours } from '../slices/config/hideLocationHours';
import { setHideTimesForSaleTypes } from '../slices/config/hideTimesForSaleTypes';
import { setInitialZoom } from '../slices/config/initialZoom';
import { setLocationAddressTemplate } from '../slices/config/locationAddressTemplate';
import { setPaymentGatewayPublicKey } from '../slices/config/paymentGatewayPublicKey';
import { setReCaptchaSiteKey } from '../slices/config/reCaptchaSiteKey';
import { setRelaxMenuItemHeightConsistency } from '../slices/config/relaxMenuItemHeightConsistency';
import { setTableNumberLabel } from '../slices/config/tableNumberLabel';
import { setThemeOverrides } from '../slices/config/themeOverrides';
import { setEnableMenuLocationDetails } from '../slices/config/enableMenuLocationDetails';
import { setEnableFraudPreventionMessage } from '../slices/config/enableFraudPreventionMessage';
import { setThresholdOrderingProviders } from '../slices/config/thresholdOrderingProviders';
//fidlds config for member registration form
import { setProfileFields } from '../slices/config/profileFields';
import { setpasswordComplexity } from '../slices/config/passwordComplexity';
import { setTermsAndConditionsLink } from '../slices/config/termsAndConditionsLink';
import debug from '../utils/debug';
import { loadFonts } from '../utils/fonts';
import { initAnalytics } from '../utils/analytics';
import { SALE_INPUT_TYPE } from '../constants';
import { signOut } from '../thunks/signOut';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingOperations, OrderingConstants, OrderingSelectors } from 'polygon-ordering';
import redcatApiFetch from '../api';
import processConfig from '../utils/processors/processConfig';
import { withParams } from '../utils/url';
import { THEME_CLIENT_ID } from '../constants';
import json5 from 'json5'; // more forgiving json parser
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { RootState } from 'store';
import { setFirstSyncComplete } from '../slices/firstSyncComplete';
import { setLoyaltyDisplayAddReceipt } from '../slices/config/loyaltyDisplayAddReceipt';
import { setCreditLoyaltyThreshold } from '../slices/config/creditLoyaltyTreshold';
import { setLoyaltyPointsRing } from '../slices/config/loyaltyPointsRing';
import { setHqGatewayKey } from '../slices/config/hqGatewayKey';
import { setFallbackOpeningHours } from '../slices/config/fallbackOpeningHours';
import { setFallbackClosingHours } from '../slices/config/fallbackClosingHours';

import { setScriptInjection } from '../slices/config/scriptInjection';
import { setEnableStockPolling } from '../slices/config/enableStockPolling';
import { setStockPollingInterval } from '../slices/config/stockPollingInterval';
import { setGoogleLoginButtonClientKey } from '../slices/config/googleLoginButtonClientKey';
import { setFacebookLoginButtonClientId } from '../slices/config/facebookLoginButtonClientId';
import { setAppleLoginButtonClientId } from '../slices/config/appleLoginButtonClientKey';
import { setTiktokAnalyticsKey } from '../slices/config/tiktokAnalyticsKey';
import { setFirebaseApiKey } from '../slices/config/firebaseApiKey';
import { setFirebaseAppId } from '../slices/config/firebaseAppId';
import { setFirebaseAuthDomain } from '../slices/config/firebaseAuthDomain';
import { setFirebaseMeasurementId } from '../slices/config/firebaseMeasurementId';
import { setFirebaseMessagingSenderId } from '../slices/config/firebaseMessagingSenderId';
import { setFirebaseProjectId } from '../slices/config/firebaseProjectId';
import { setFirebaseStorageBucket } from '../slices/config/firebaseStorageBucket';
import { setPurchaseUnits } from '../slices/config/purchaseUnits';
import { setLoyaltyBarcodeView } from '../slices/config/loyaltyBarcodeView';
import { setDisabledLoyaltyRoutes } from '../slices/config/disabledLoyaltyRoutes';
import { setStripeMerchantAccount } from '../slices/config/stripeMerchantAccount';
import { setEnableGiftcardPurchase } from '../slices/config/enableGiftcardPurchase';
import { setEnableLoyaltyWallet } from '../slices/config/enableLoyaltyWallet';
import { setShowExpiredCoupons } from '../slices/config/showExpiredCoupons';
import { setShowRedeemedCoupons } from '../slices/config/showRedeemedCoupons';
import { setHideFreeChoiceMessage } from '../slices/config/hideFreeChoiceMessage';
import { setHideHeroScreenImage } from '../slices/config/hideHeroScreenImage';
import { setEnableStripeCvcRevalidation } from '../slices/config/enableStripeCvcRevalidation';
import getDisabledLoyaltyRoutes from '../utils/getDisabledLoyaltyRoutes';

const {
  fetchBrands,
  fetchLocations,
  setAttemptsConfig,
  setBrands,
  setCardsets,
  setChoiceSetQuantityDiscountConfig,
  setCouponOrderingMethod,
  setEnabledPaymentMethods,
  setEnabledSaleTypes,
  setEnableDuplicateSaleProtection,
  setEnableMultipleDeliveryEstimate,
  setExcludedChoiceSets,
  setFlattenCategories,
  setFreeChoiceOrderingMethod,
  setImportedGiftCardRegex,
  setInternationalMobileAllowed,
  setLoyaltyRequiresVerification,
  setOrderingWindowPadding,
  setMerchantConfig,
  setProductConfig,
  setSaleInputType,
  setEnableDynamicPaymentGatewayConfig,
  setEnableGuestOrdering,
  setEnablePayLater,
  fetchPayLaterRules,
  setHideNegativeIngredients,
  setEnableDineInNameMode,
  fetchStockBalances,
  setStockBalanceThresholds,
  setStockBalanceIgnoreThreshold,
  setFutureOrderingLimit,
  setFutureOrdering,
  setMobileRegexWhitelist,
  setFutureOrderingLimitCatering,
  setIncludeDiscountMinSpend,
} = OrderingOperations;

const { MEMBER_PAYMENT_METHODS } = OrderingConstants;
const { getMember } = OrderingSelectors;

export const sync = createAsyncThunk('sync', async (_data: undefined, { dispatch, getState }) => {
  try {
    debug('SYNC THUNK STARTING UP');

    const fetchConfig = await redcatApiFetch({
      method: 'GET',
      path: `/api/v1/config/web-ordering`,
    });
    const fetchThemeOverrides = await redcatApiFetch({
      method: 'GET',
      path: withParams('/api/v1/public/themes', {
        ClientId: THEME_CLIENT_ID,
        Version: process.env.REACT_APP_VERSION,
      }),
    });

    const config = processConfig(fetchConfig.data);

    const disabledLoyaltyRoutes = getDisabledLoyaltyRoutes(config);

    let themeOverrides: ThemeOverrides = lodash.get(fetchThemeOverrides, 'data.Body') || {};

    if (typeof themeOverrides === 'string') {
      themeOverrides = json5.parse(themeOverrides);
    }

    if (!config) {
      throw new Error('missing config');
    }

    debug('sync results', { config, themeOverrides });

    loadFonts(config.fontFamilies);

    dispatch(setEnableStripeCvcRevalidation(config.enableStripeCvcRevalidation));
    dispatch(setFutureOrderingLimitCatering(config.futureOrderingLimitCatering));
    dispatch(setMobileRegexWhitelist(config.mobileRegexWhiteList));
    dispatch(setHideFreeChoiceMessage(config.hideFreeChoiceMessage));
    dispatch(setHideHeroScreenImage(config.hideHeroScreenImage));
    dispatch(setIncludeDiscountMinSpend(config.includeDiscountMinSpend));

    dispatch(setShowExpiredCoupons(config.showExpiredCoupons));
    dispatch(setShowRedeemedCoupons(config.showRedeemedCoupons));

    dispatch(setEnableLoyaltyWallet(config.displayWallet));
    dispatch(setEnableGiftcardPurchase(config.enableGiftcardPurcase));
    dispatch(setLoyaltyBarcodeView(!config.useQrCode));
    dispatch(setPurchaseUnits(config.purchaseUnits));

    dispatch(setDisabledLoyaltyRoutes(disabledLoyaltyRoutes));
    dispatch(setStripeMerchantAccount(config.merchantAccount));
    dispatch(fetchStockBalances({}));
    dispatch(setFirebaseStorageBucket(config.firebaseStorageBucket));
    dispatch(setFirebaseProjectId(config.firebaseProjectId));
    dispatch(setFirebaseMessagingSenderId(config.firebaseMessagingSenderId));
    dispatch(setFirebaseMeasurementId(config.firebaseMeasurementId));
    dispatch(setFirebaseAuthDomain(config.firebaseAuthDomain));
    dispatch(setFirebaseAppId(config.firebaseAppId));
    dispatch(setFirebaseApiKey(config.firebaseApiKey));
    dispatch(setTiktokAnalyticsKey(config.tiktokAnalyticsKey));
    dispatch(setAppleLoginButtonClientId(config.appleLoginButtonClientId));
    dispatch(setFacebookLoginButtonClientId(config.facebookLoginButtonClientId));
    dispatch(setGoogleLoginButtonClientKey(config.googleLoginButtonClientKey));
    dispatch(setScriptInjection(config.scriptInjection));
    dispatch(setEnableStockPolling(config.enableStockPolling));
    dispatch(setStockPollingInterval(config.stockPollingInterval));
    dispatch(
      setStockBalanceThresholds({
        //@ts-ignore
        stockBalanceThreshold0: config.stockBalanceThreshold0,
        stockBalanceThreshold1: config.stockBalanceThreshold1,
        stockBalanceThreshold2: config.stockBalanceThreshold2,
        stockBalanceThreshold3: config.stockBalanceThreshold3,
        stockBalanceThreshold4: config.stockBalanceThreshold4,
      }),
    );
    dispatch(setStockBalanceIgnoreThreshold(config.stockBalanceIgnoreThreshold));
    dispatch(setFallbackOpeningHours(config.fallbackOpeningHours));
    dispatch(setFallbackClosingHours(config.fallbackClosingHours));
    dispatch(setEnableDineInNameMode(config.enableDineInNameMode));
    dispatch(setHqGatewayKey(config.paymentGatewayPublicKey));
    dispatch(setLoyaltyPointsRing(config.ringView));
    dispatch(setCreditLoyaltyThreshold(config.creditLoyaltyThreshold));
    dispatch(setLoyaltyDisplayAddReceipt(config.loyaltyDisplayAddReceipt));
    dispatch(setThemeOverrides(themeOverrides));
    dispatch(setFacebookAnalyticsKey(config.facebookAnalyticsKey));
    dispatch(setGoogleAnalyticsKey(config.googleAnalyticsKey));
    dispatch(setBrandingFavicon(config.brandingFavicon));
    dispatch(setCollapsableChoiceSetsEnabled(config.collapsableChoiceSetsEnabled));
    dispatch(setCollapseOptionalChoiceSetsByDefault(config.collapseOptionalChoiceSetsByDefault));
    dispatch(setDisableAllBrandsMenuTab(config.disableAllBrandsMenuTab));
    dispatch(setDisableCategoryCount(config.disableCategoryCount));
    dispatch(setDisableLoyalty(config.disableWebLoyalty));
    dispatch(setDisableMenuSearch(config.disableMenuSearch));
    dispatch(setDisableOffers(config.disableOffers));
    dispatch(setDisablePickupTimeSelection(config.disablePickupTimeSelection));
    dispatch(setDisplayPointsAsWholeNumber(config.displayPointsAsWholeNumber));
    dispatch(setEnableDeliveryNotes(config.enableDeliveryNotes));
    dispatch(setEnableAutoselectFirstLocation(config.enableAutoselectFirstLocation));
    dispatch(setEnableGuestOrdering(config.enableGuestOrdering));
    dispatch(setEnableMenuLocationDetails(config.enableMenuLocationDetails));
    dispatch(setEnableFraudPreventionMessage(config.enableFraudPreventionMessage));
    dispatch(setEnableOrderNotes(config.enableOrderNotes));
    dispatch(setEnableReCaptcha(config.enableReCaptcha));
    dispatch(setFlattenDeliverySurcharges(config.flattenDeliverySurcharges));
    dispatch(setFloatingScreenBackgroundImagePath(config.floatingScreenBackgroundImagePath));
    dispatch(setGeocoderCountryRestriction(config.geocoderCountryRestriction));
    dispatch(setGoogleMapsApiKey(config.googleMapsApiKey));
    dispatch(setHeaderBrandImagePath(config.headerBrandImagePath));
    dispatch(setHeaderBrandingType(config.headerBrandingType));
    dispatch(setHeaderBrandName(config.headerBrandName));
    dispatch(setHeroScreenImagePath(config.heroScreenImagePath));
    dispatch(setHideMenuItemSizeDescription(config.hideMenuItemSizeDescription));
    dispatch(setHideLocationHours(config.hideLocationHours));
    dispatch(setInitialZoom(config.initialZoom));
    dispatch(setLocationAddressTemplate(config.locationAddressTemplate));
    dispatch(setInternationalMobileAllowed(config.internationalMobileAllowed));
    dispatch(setPaymentGatewayPublicKey(config.paymentGatewayPublicKey));
    dispatch(setReCaptchaSiteKey(config.reCaptchaSiteKey));
    dispatch(setRelaxMenuItemHeightConsistency(config.relaxMenuItemHeightConsistency));
    dispatch(setTableNumberLabel(config.tableNumberLabel));
    dispatch(
      setFallbackLocation({
        latitude: config.fallbackLatitude ?? 0,
        longitude: config.fallbackLongitude ?? 0,
      }),
    );
    dispatch(setCouponOrderingMethod(config.couponOrderingMethod));
    dispatch(
      setEnabledPaymentMethods(
        config.disableLoyalty
          ? config.enabledPaymentMethods.filter(
              (method: PAYMENT_METHOD) => !MEMBER_PAYMENT_METHODS.includes(method),
            )
          : config.enabledPaymentMethods,
      ),
    );

    dispatch(setEnabledSaleTypes(config.enabledSaleTypes));
    dispatch(setHideTimesForSaleTypes(config.hideTimesForSaleTypes));
    dispatch(setExcludedChoiceSets(config.excludedChoiceSets));
    dispatch(setFlattenCategories(true));
    dispatch(setFreeChoiceOrderingMethod(config.freeChoiceOrderingMethod));
    dispatch(setAttemptsConfig({ validateOrder: 2, submitSale: 1 }));
    dispatch(setImportedGiftCardRegex(config.importedGiftCardRegex || null));
    dispatch(
      setOrderingWindowPadding({
        opening: config.openingPadding,
        closing: config.closingPadding,
      }),
    );

    dispatch(
      setMerchantConfig({
        //@ts-ignore
        merchantId: config.merchantId,
        //@ts-ignore
        merchantName: config.merchantName,
      }),
    );

    dispatch(setCardsets(config.cardsets as SDict<Cardset>));
    dispatch(setLoyaltyRequiresVerification(config.loyaltyRequiresVerification));
    dispatch(
      setProductConfig({
        vendor: 'Redcat',
        name: 'Web Ordering',
        version: process.env.REACT_APP_VERSION,
      }),
    );
    dispatch(setChoiceSetQuantityDiscountConfig(config.choiceSetDiscountConfig));
    dispatch(setThresholdOrderingProviders(config.thresholdOrderingProviders));
    dispatch(setEnableMultipleDeliveryEstimate(config.enableMultipleDeliveryEstimate));
    dispatch(setEnableDuplicateSaleProtection(config.enableDuplicateSaleProtection));
    dispatch(setEnableDynamicPaymentGatewayConfig(config.enableDynamicPaymentGatewayConfig));

    dispatch(setEnablePayLater(config.enablePayLater));

    dispatch(setHideNegativeIngredients(config.hideNegativeIngredients));

    //member registration form fields

    dispatch(setProfileFields(config.profileFields));
    dispatch(setpasswordComplexity(config.passwordComplexity));
    dispatch(setTermsAndConditionsLink(config.termsAndConditionsLink));

    // --------- fetch ---------
    dispatch(fetchLocations({}));

    //next-day-pickup
    dispatch(setFutureOrderingLimit(config.futureOrderingLimit));
    dispatch(setFutureOrdering(config.enableFutureOrdering));

    if (config.enableSaleInputTypes) {
      dispatch(setSaleInputType(SALE_INPUT_TYPE));
    }
    if (config.disableLoyalty) {
      if (!config.enableGuestOrdering) {
        alert('Guest ordering must be enabled if loyalty is disabled');
      }

      dispatch(signOut);
    }

    if (config.enableVirtualBrands) {
      dispatch(fetchBrands({}));
    } else {
      dispatch(setBrands(null));
    }

    if (config.enablePayLater) {
      dispatch(fetchPayLaterRules({}));
    }

    const firstSyncComplete = (getState() as RootState).firstSyncComplete;

    if (!firstSyncComplete) {
      dispatch(setFirstSyncComplete(true));
    } else {
      debug('first sync has previously occurred');
    }

    const member = getMember(getState() as RootState);
    const googleAnalyticsKey = config.googleAnalyticsKey;
    const facebookAnalyticsKey = config.facebookAnalyticsKey;
    const tiktokAnalyticsKey = config.tiktokAnalyticsKey;

    const firebaseConfig = {
      apiKey: config.firebaseApiKey,
      authDomain: config.firebaseAuthDomain,
      projectId: config.firebaseProjectId,
      storageBucket: config.firebaseStorageBucket,
      messagingSenderId: config.firebaseMessagingSenderId,
      appId: config.firebaseAppId,
      measurementId: config.firebaseMeasurementId,
    };

    const firebaseActive = Object.keys(firebaseConfig).some(function (k) {
      return (
        //@ts-ignore
        firebaseConfig[k] !== undefined && firebaseConfig[k] !== null && firebaseConfig[k] !== ''
      );
    });

    if (firebaseActive) {
      window.firebase = true;
    }

    initAnalytics({
      googleAnalyticsKey,
      facebookAnalyticsKey,
      member,
      firebaseConfig,
      tiktokAnalyticsKey,
    });
  } catch (e) {
    debug(false, 'something went wrong during sync', { e });
  }
});
