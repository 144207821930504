import { createSelector } from 'reselect';

import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getOrderTotals, { $getOrderTotals } from './getOrderTotals';
import getMinimumOrderInclusive from './getMinimumOrderInclusive';
import getSelectedOfferValid from './getSelectedOfferValid';
import getMinimumOrderInformation from './getMinimumOrderInformation';

const get = (
  selectedOffer: Offer | undefined,
  orderTotals: OrderTotals | undefined,
  isOfferValid: boolean | undefined,
  minimumOrderInfo: MinimumOrderInformation | null,
  isInclusive: boolean,
): boolean => {
  if (orderTotals === undefined || !isOfferValid || !selectedOffer) {
    return true;
  }

  //minimum order coupon
  if (selectedOffer.MinOrderValue) {
    if (isInclusive) {
      return !(
        orderTotals.purchasesMoneyPrice <
        selectedOffer.MinOrderValue * 100
      );
    } else {
      if (
        minimumOrderInfo &&
        minimumOrderInfo?.amount >= selectedOffer.MinOrderValue * 100
      ) {
        return (
          orderTotals.purchasesMoneyPrice >= selectedOffer.MinOrderValue * 100
        );
      }
      return (
        orderTotals.purchasesMoneyPrice - orderTotals.purchasesMoneyDiscount >=
        selectedOffer.MinOrderValue * 100
      );
    }
  }

  return true;
};

export default createSelector(
  [
    getSelectedOffer,
    getOrderTotals,
    getSelectedOfferValid,
    getMinimumOrderInformation,
    getMinimumOrderInclusive,
  ],
  get,
);

export const $getSelectedOfferMiniValueValid = createSelector(
  [
    $getSelectedOffer,
    $getOrderTotals,
    getSelectedOfferValid,
    getMinimumOrderInformation,
    getMinimumOrderInclusive,
  ],
  get,
);
