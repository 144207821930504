import React from 'react';
import { useTranslation } from 'react-i18next';

import { SALE_TYPE_ICONS } from '../constants/saleTypes';

import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';

import adjustSaleTypeAction from '../actions/adjustSaleType';

import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import { OrderingSelectors } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

export const ORDER_TYPE_MODAL_ID = 'ORDER_TYPE_MODAL_ID';

const { getLocation, getSaleType } = OrderingSelectors;

const OrderTypeModal = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const location = useAppSelector(getLocation);

  const selectedSaleType = useAppSelector(getSaleType);
  const locationId = location ? location.id : null;

  return (
    <Modal
      desktopMaxWidth={400}
      desktopMinWidth={350}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      closeButtonLabel=""
    >
      <div>
        {(displayableSaleTypes || []).map(saleType => (
          <StandardButton
            containerStyle={styles.button}
            key={saleType}
            label={t(`saleType.${saleType}`)}
            onClick={() =>
              dispatch(
                adjustSaleTypeAction({
                  saleType,
                  locationId:
                    selectedSaleType === SALE_TYPE.CATERING
                      ? location?.online
                        ? locationId
                        : null
                      : locationId,
                }),
              )
            }
            RightIconComponent={SALE_TYPE_ICONS[saleType]}
          />
        ))}
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: 30,
    paddingTop: 10,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  modalMobileContainer: {
    padding: 30,
    paddingTop: 10,
  },

  button: {
    marginTop: 20,
  },
};

export default OrderTypeModal;
