import React from 'react';
import ScreenHero from './ScreenHero';
import { useAppSelector } from '../app/hooks';
import { Col } from 'reactstrap';
import LoyaltySideBar from './LoyaltySideBar';
import LoyaltyHeaderBar from './LoyaltyHeaderBar';
import LoyaltyTitle from './LoyaltyTitle';
import { useLocation } from 'react-router-dom';
import LoyaltySubTitle from './LoyaltySubTitle';
import { v4 as uuid } from 'uuid';
import getThemeLookup from '../selectors/getThemeLookup';
import { getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';

const PrivateRouteLayout = ({ children }: { children: React.ReactNode }) => {
  const deviceType = useAppSelector(state => state.deviceType);
  const deviceDimensions = useAppSelector(state => state.deviceDimensions);
  const { pathname } = useLocation();
  const p = useAppSelector(getThemeLookup);
  const screens = getLoyaltyDropdownMenu();

  return (
    <ScreenHero disablePSB={true}>
      <div
        className={deviceType === 1 ? ' pb-5' : 'px-3'}
        style={{
          backgroundColor:
            deviceType === 1
              ? p('loyaltyBackground', ['backgroundColor']).backgroundColor
              : p('loyaltyMobileBackground', ['backgroundColor']).backgroundColor,
          minHeight: '100%',
        }}
      >
        <div className="container ">
          <div className="row" style={{ paddingTop: deviceType === 1 ? 100 : '' }}>
            <Col lg="1" className="d-none d-lg-block"></Col>
            <Col lg="3" className="d-none d-lg-block" style={{ maxWidth: 303, padding: 0 }}>
              <LoyaltySideBar />
            </Col>
            <Col
              lg="6"
              md="12"
              className={deviceDimensions?.width! < 992 ? `mx-auto ` : ''}
              style={{ maxWidth: 500, padding: 0 }}
            >
              <LoyaltyHeaderBar />

              <div
                className={` pb-5 ${deviceType === 1 ? 'px-5 pt-5' : 'px-2'}`}
                style={{
                  backgroundColor: p('loyaltyModals', ['backgroundColor']).backgroundColor,
                  borderRadius: deviceType === 1 ? 5 : 0,
                  marginLeft: deviceType === 1 ? 20 : 0,
                }}
              >
                {screens.map(screen =>
                  pathname === screen.path
                    ? screen.title && (
                        <div key={uuid()}>
                          <LoyaltyTitle title={screen.title} />
                          {screen.subTitle && <LoyaltySubTitle title={screen.subTitle} />}
                        </div>
                      )
                    : screen.subRoutes?.map(
                        route =>
                          route.path === pathname && (
                            <div key={uuid()}>
                              <LoyaltyTitle title={screen.title!} />
                              <LoyaltySubTitle title={route.subTitle} />
                            </div>
                          ),
                      ),
                )}

                {children}
              </div>
            </Col>
          </div>
        </div>
      </div>
    </ScreenHero>
  );
};

export default PrivateRouteLayout;
