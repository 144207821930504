import { createSelector } from 'reselect';
import getMinimumOrderInformation from './getMinimumOrderInformation';
import getMinimumOrderInclusive from './getMinimumOrderInclusive';
import getSelectedOffer from './getSelectedOffer';
import getStagedPurchases from './getStagedPurchases';
import { getFreeChoiceOrderingMethod, getCouponOrderingMethod } from './config';
import getRewardsAccrualRate from './getRewardsAccrualRate';
import getStagedPurchasesTotals from './getStagedPurchasesTotals';
import { calculateCouponDiscountedPrice } from '../utils/ordering/calculateCouponDiscount';

const get = (
  minInfo: MinimumOrderInformation | null,
  selectedOffer: Offer | undefined,
  isInclusive: boolean,
  stagedPurchasesTotals: PurchaseTotals,
  purchasedItems: any[],
  rewardsAccrualRate: any,
  couponOrderingMethod: any,
  freeChoiceOrderingMethod: any,
): number => {
  let result = false;
  const miniAmount = minInfo?.amount || 0;
  if (!minInfo || miniAmount === 0 || !minInfo.surchargeEnabled) result = false;

  let amount = Math.max(
    0,
    (miniAmount || 0) - stagedPurchasesTotals.moneyPrice,
  );

  if (!isInclusive) {
    //calculate surcharge when valid offer applied
    if (selectedOffer) {
      if (
        selectedOffer.MinOrderValue &&
        selectedOffer.MinOrderValue * 100 > miniAmount
      ) {
        amount = 0;
      } else {
        const result = calculateCouponDiscountedPrice(
          selectedOffer,
          stagedPurchasesTotals,
          purchasedItems,
          rewardsAccrualRate,
          couponOrderingMethod,
          freeChoiceOrderingMethod,
        );

        amount = miniAmount - result > 0 ? miniAmount - result : 0;
      }
    }
  } else {
    if (
      selectedOffer &&
      selectedOffer.MinOrderValue &&
      selectedOffer.MinOrderValue * 100 >= miniAmount
    )
      amount = 0;
  }

  result = amount > 0;
  return amount;
};
export default createSelector(
  [
    getMinimumOrderInformation,
    getSelectedOffer,
    getMinimumOrderInclusive,
    getStagedPurchasesTotals,
    getStagedPurchases,
    getRewardsAccrualRate,
    getCouponOrderingMethod,
    getFreeChoiceOrderingMethod,
  ],
  get,
);
