import { call, put, select } from 'redux-saga/effects';
import i18next from 'i18next';

import { OrderingOperations, requestAndWaitForFlow } from 'polygon-ordering';

import debug from '../utils/debug';
import history from '../history';

import setCurrentModal from '../actions/setCurrentModal';

import getLocationBrandsWithMenuRepresentation from '../selectors/getLocationBrandsWithMenuRepresentation';

import { MENU_SCREEN_ROUTE } from '../screens/MenuScreen';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';

const { updateKeyOrderProperty, setPickupTime } = OrderingOperations;

export default function* adjustOrder({
  payload: { saleType: newSaleType, locationId: newLocationId },
}: {
  payload: { saleType: number | undefined; locationId: string | undefined };
}) {
  debug('adjusting order', { newSaleType, newLocationId });

  const result = yield call(requestAndWaitForFlow, updateKeyOrderProperty, {
    saleType: newSaleType,
    locationId: newLocationId,
    autoApply: true,
  });

  yield put(setPickupTime(null));

  debug('updateKeyOrderProperty', { result });

  if (result.succeeded) {
    history.replace(MENU_SCREEN_ROUTE);

    const representedBrands = yield select(getLocationBrandsWithMenuRepresentation);
    const multipleBrands = representedBrands.length > 1;

    if (multipleBrands) {
      yield put(
        setCurrentModal({
          modalId: MESSAGE_MODAL_ID,
          params: {
            title: i18next.t('title.modal.brandsEducation'),
            message: i18next.t('brandsEducationMessage'),
          },
          clearReturnModalDetails: true,
        }),
      );
    } else {
      yield put(setCurrentModal(null));
    }
  } else {
    console.error(result);
  }
}
