export default function (locA: POSLocation, locB: Partial<Coordinates>) {
  if (locA.longitude && locA.latitude && locB.latitude && locB.longitude) {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a =
      0.5 -
      c((locB.latitude - locA.latitude) * p) / 2 +
      (c(locA.latitude * p) *
        c(locB.latitude * p) *
        (1 - c((locB.longitude - locA.longitude) * p))) /
        2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }
}
