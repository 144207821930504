import { createCustomAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { singleValuePayload, noPayload } from '../utils/actions';

const SET_LOCATION_ID = '$SET_LOCATION_ID';
export const setLocationId = createCustomAction(
  SET_LOCATION_ID,
  singleValuePayload<typeof SET_LOCATION_ID, string | null>(),
);

const SET_MENU = '$SET_MENU';
export const setMenu = createCustomAction(
  SET_MENU,
  singleValuePayload<typeof SET_MENU, Menu | null>(),
);

const SET_CACHED_MENU = '$SET_CACHED_MENU';
export const setCachedMenu = createCustomAction(
  SET_CACHED_MENU,
  singleValuePayload<typeof SET_CACHED_MENU, Menu | null>(),
);

const SET_OFFER_ID = '$SET_OFFER_ID';
export const setOfferId = createCustomAction(
  SET_OFFER_ID,
  singleValuePayload<typeof SET_OFFER_ID, string | null>(),
);

const SET_OFFERS = '$SET_OFFERS';
export const setOffers = createCustomAction(
  SET_OFFERS,
  singleValuePayload<typeof SET_OFFERS, Offer[] | null>(),
);

const ADD_OFFERS = '$ADD_OFFERS';
export const addOffers = createCustomAction(
  ADD_OFFERS,
  singleValuePayload<typeof ADD_OFFERS, Offer[]>(),
);

const SET_PICKUP_TIME = '$SET_PICKUP_TIME';
export const setPickupTime = createCustomAction(
  SET_PICKUP_TIME,
  singleValuePayload<typeof SET_PICKUP_TIME, string | null>(),
);

const SET_DELIVERY_TIME = '$SET_DELIVERY_TIME';
export const setDeliveryTime = createCustomAction(
  SET_DELIVERY_TIME,
  singleValuePayload<typeof SET_DELIVERY_TIME, string | null>(),
);

const SET_TABLE = '$SET_TABLE';
export const setTable = createCustomAction(
  SET_TABLE,
  singleValuePayload<typeof SET_TABLE, string | null>(),
);

const SET_SALE_DETAILS = '$SET_SALE_DETAILS';
export const setSaleDetails = createCustomAction(
  SET_SALE_DETAILS,
  singleValuePayload<typeof SET_SALE_DETAILS, Sale>(),
);

const SET_OPEN_PURCHASE = '$SET_OPEN_PURCHASE';
export const setOpenPurchase = createCustomAction(
  SET_OPEN_PURCHASE,
  singleValuePayload<typeof SET_OPEN_PURCHASE, _Purchase>(),
);

const SET_OPEN_PURCHASE_LOWEST_BALANCE = '$SET_OPEN_PURCHASE_LOWEST_BALANCE';
export const setOpenPurchaseLowestBalance = createCustomAction(
  SET_OPEN_PURCHASE_LOWEST_BALANCE,
  singleValuePayload<typeof SET_OPEN_PURCHASE_LOWEST_BALANCE, number>(),
);

const SET_STAGED_PURCHASES = '$SET_STAGED_PURCHASES';
export const setStagedPurchases = createCustomAction(
  SET_STAGED_PURCHASES,
  singleValuePayload<typeof SET_STAGED_PURCHASES, _Purchase[]>(),
);

const SET_TIME_ESTIMATE_RETURNED = '$SET_TIME_ESTIMATE_RETURNED';
export const setTimeEstimateReturned = createCustomAction(
  SET_TIME_ESTIMATE_RETURNED,
  singleValuePayload<typeof SET_TIME_ESTIMATE_RETURNED, string | null>(),
);

const ADD_STAGED_PURCHASES = '$ADD_STAGED_PURCHASES';
export const addStagedPurchases = createCustomAction(
  ADD_STAGED_PURCHASES,
  singleValuePayload<typeof ADD_STAGED_PURCHASES, _Purchase[]>(),
);

const SET_NOTES = '$SET_NOTES';
export const setNotes = createCustomAction(
  SET_NOTES,
  singleValuePayload<typeof SET_NOTES, string>(),
);

const SET_PAYMENT = '$SET_PAYMENT';
export const setPayment = createCustomAction(
  SET_PAYMENT,
  singleValuePayload<typeof SET_PAYMENT, Payment | null>(),
);

const SET_PURCHASER = '$SET_PURCHASER';
export const setPurchaser = createCustomAction(
  SET_PURCHASER,
  singleValuePayload<typeof SET_PURCHASER, _Purchaser>(),
);

const RESET_SALE_UUID = '$RESET_SALE_UUID';
export const resetSaleUUID = createCustomAction(RESET_SALE_UUID);

const RESET_ORDER = '$RESET_ORDER';
export const resetOrder = createCustomAction(
  RESET_ORDER,
  (type: typeof RESET_ORDER) =>
    ({
      preserveMember,
      uuid = uuidv4(),
    }: {
      preserveMember: boolean;
      uuid?: string;
    }) => ({
      type,
      payload: {
        preserveMember,
        uuid: uuid,
      },
    }),
);

const ADJUST_OPEN_PURCHASE_QUANTITY = '$ADJUST_OPEN_PURCHASE_QUANTITY';
export const adjustOpenPurchaseQuantity = createCustomAction(
  ADJUST_OPEN_PURCHASE_QUANTITY,
  singleValuePayload<typeof ADJUST_OPEN_PURCHASE_QUANTITY, number>(),
);

const ADJUST_STAGED_PURCHASE_QUANTITY = '$ADJUST_STAGED_PURCHASE_QUANTITY';
export const adjustStagedPurchaseQuantity = createCustomAction(
  ADJUST_STAGED_PURCHASE_QUANTITY,
  (type: typeof ADJUST_STAGED_PURCHASE_QUANTITY) =>
    (purchaseId: string, adjustment?: number, newQuantity?: number) => ({
      type,
      payload: {
        purchaseId,
        adjustment,
        newQuantity,
      },
    }),
);

const REMOVE_STAGED_PURCHASE = '$REMOVE_STAGED_PURCHASE';
export const removeStagedPurchase = createCustomAction(
  REMOVE_STAGED_PURCHASE,
  singleValuePayload<typeof REMOVE_STAGED_PURCHASE, string>(),
);

const ADJUST_PURCHASER_NAME = '$ADJUST_PURCHASER_NAME';
export const adjustPurchaserName = createCustomAction(
  ADJUST_PURCHASER_NAME,
  singleValuePayload<
    typeof ADJUST_PURCHASER_NAME,
    'clear' | 'delete' | string
  >(),
);

const ADJUST_PURCHASER_MOBILE = '$ADJUST_PURCHASER_MOBILE';
export const adjustPurchaserMobile = createCustomAction(
  ADJUST_PURCHASER_MOBILE,
  singleValuePayload<
    typeof ADJUST_PURCHASER_MOBILE,
    'clear' | 'delete' | string
  >(),
);

const ADJUST_PURCHASER_EMAIL = '$ADJUST_PURCHASER_EMAIL';
export const adjustPurchaserEmail = createCustomAction(
  ADJUST_PURCHASER_EMAIL,
  singleValuePayload<
    typeof ADJUST_PURCHASER_EMAIL,
    'clear' | 'delete' | string
  >(),
);

const ADJUST_TABLE = '$ADJUST_TABLE';
export const adjustTable = createCustomAction(
  ADJUST_TABLE,
  singleValuePayload<typeof ADJUST_TABLE, 'clear' | 'delete' | string>(),
);

const ADJUST_PROMO_CODE = '$ADJUST_PROMO_CODE';
export const adjustPromoCode = createCustomAction(
  ADJUST_PROMO_CODE,
  singleValuePayload<typeof ADJUST_PROMO_CODE, 'clear' | 'delete' | string>(),
);

const SET_PURCHASER_NAME = '$SET_PURCHASER_NAME';
export const setPurchaserName = createCustomAction(
  SET_PURCHASER_NAME,
  singleValuePayload<typeof SET_PURCHASER_NAME, string | null>(),
);

const SET_PURCHASER_FAMILY_NAME = '$SET_PURCHASER_FAMILY_NAME';
export const setPurchaserFamilyName = createCustomAction(
  SET_PURCHASER_FAMILY_NAME,
  singleValuePayload<typeof SET_PURCHASER_FAMILY_NAME, string | null>(),
);

const SET_PURCHASER_MOBILE = '$SET_PURCHASER_MOBILE';
export const setPurchaserMobile = createCustomAction(
  SET_PURCHASER_MOBILE,
  singleValuePayload<typeof SET_PURCHASER_MOBILE, string | null>(),
);

const SET_PURCHASER_EMAIL = '$SET_PURCHASER_EMAIL';
export const setPurchaserEmail = createCustomAction(
  SET_PURCHASER_EMAIL,
  singleValuePayload<typeof SET_PURCHASER_EMAIL, string | null>(),
);

const SET_SALE_IN_PROGRESS = '$SET_SALE_IN_PROGRESS';
export const setSaleInProgress = createCustomAction(
  SET_SALE_IN_PROGRESS,
  singleValuePayload<typeof SET_SALE_IN_PROGRESS, boolean>(),
);

const CANCEL_PAYMENT_IN_PROGRESS = '$CANCEL_PAYMENT_IN_PROGRESS';
export const cancelPaymentInProgress = createCustomAction(
  CANCEL_PAYMENT_IN_PROGRESS,
  noPayload<typeof CANCEL_PAYMENT_IN_PROGRESS>(),
);

const SET_PROMO_CODE = '$SET_PROMO_CODE';
export const setPromoCode = createCustomAction(
  SET_PROMO_CODE,
  singleValuePayload<typeof SET_PROMO_CODE, string | null>(),
);

const SET_SALE_STATUS = '$SET_SALE_STATUS';
export const setSaleStatus = createCustomAction(
  SET_SALE_STATUS,
  singleValuePayload<typeof SET_SALE_STATUS, any>(),
);

const SET_SALE_TYPE = '$SET_SALE_TYPE';
export const setSaleType = createCustomAction(
  SET_SALE_TYPE,
  singleValuePayload<typeof SET_SALE_TYPE, number>(),
);

const SET_DELIVERY_ADDRESS = '$SET_DELIVERY_ADDRESS';
export const setDeliveryAddress = createCustomAction(
  SET_DELIVERY_ADDRESS,
  singleValuePayload<typeof SET_DELIVERY_ADDRESS, TokenisedAddress | null>(),
);

const SET_DELIVERY_COORDINATES = '$SET_DELIVERY_COORDINATES';
export const setDeliveryCoordinates = createCustomAction(
  SET_DELIVERY_COORDINATES,
  singleValuePayload<typeof SET_DELIVERY_COORDINATES, GPSCoordinates | null>(),
);

const SET_DELIVERY_PROVIDER = '$SET_DELIVERY_PROVIDER';
export const setDeliveryProvider = createCustomAction(
  SET_DELIVERY_PROVIDER,
  singleValuePayload<typeof SET_DELIVERY_PROVIDER, DeliveryProvider | null>(),
);

const SET_DELIVERY_SURCHARGES = '$SET_DELIVERY_SURCHARGES';
export const setDeliverySurcharges = createCustomAction(
  SET_DELIVERY_SURCHARGES,
  singleValuePayload<typeof SET_DELIVERY_SURCHARGES, Surcharge[] | null>(),
);

const SET_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS =
  '$SET_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS';
export const setOrderingProviderSurchargeInstructions = createCustomAction(
  SET_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS,
  singleValuePayload<
    typeof SET_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS,
    SurchargeInstruction[] | null
  >(),
);

const SET_MINIMUM_ORDER_INFORMATION = '$SET_MINIMUM_ORDER_INFORMATION';
export const setMinimumOrderInformation = createCustomAction(
  SET_MINIMUM_ORDER_INFORMATION,
  singleValuePayload<
    typeof SET_MINIMUM_ORDER_INFORMATION,
    MinimumOrderInformation | null
  >(),
);

const SET_CREDIT_CARD_PAYMENT_INFORMATION =
  '$SET_CREDIT_CARD_PAYMENT_INFORMATION';
export const setCreditCardPaymentInformation = createCustomAction(
  SET_CREDIT_CARD_PAYMENT_INFORMATION,
  singleValuePayload<
    typeof SET_CREDIT_CARD_PAYMENT_INFORMATION,
    CreditCardPaymentInformation | null
  >(),
);

const SET_PAYMENT_GATEWAY_CONFIG = '$SET_PAYMENT_GATEWAY_CONFIG';
export const setPaymentGatewayConfig = createCustomAction(
  SET_PAYMENT_GATEWAY_CONFIG,
  singleValuePayload<
    typeof SET_PAYMENT_GATEWAY_CONFIG,
    PaymentGatewayConfig | null
  >(),
);

const SET_PAYMENT_GATEWAY_CONFIG_PAYMENT_METHODS =
  '$SET_PAYMENT_GATEWAY_CONFIG_PAYMENT_METHODS';
export const setPaymentGatewayConfigPaymentMethods = createCustomAction(
  SET_PAYMENT_GATEWAY_CONFIG_PAYMENT_METHODS,
  singleValuePayload<
    typeof SET_PAYMENT_GATEWAY_CONFIG_PAYMENT_METHODS,
    any | null
  >(),
);

const APPLY_BUFFER_DATA = '$APPLY_BUFFER_DATA';
// prettier-ignore
export const applyBufferData = createCustomAction(
  APPLY_BUFFER_DATA,
  singleValuePayload<
    typeof APPLY_BUFFER_DATA,
    {
      creditCardPaymentInformation?: Parameters<typeof setCreditCardPaymentInformation>[0];
      deliveryAddress?: Parameters<typeof setDeliveryAddress>[0];
      deliveryCoordinates?: Parameters<typeof setDeliveryCoordinates>[0];
      deliveryProvider?: Parameters<typeof setDeliveryProvider>[0];
      deliverySurcharges?: Parameters<typeof setDeliverySurcharges>[0];
      deliveryTime?: Parameters<typeof setDeliveryTime>[0];
      locationId?: Parameters<typeof setLocationId>[0];
      menu?: Parameters<typeof setMenu>[0];
      minimumOrderInformation?: Parameters<typeof setMinimumOrderInformation>[0];
      offers?: Parameters<typeof setOffers>[0];
      openPurchase?: Parameters<typeof setOpenPurchase>[0];
      orderingProviderSurchargeInstructions?: Parameters<typeof setOrderingProviderSurchargeInstructions>[0];
      paymentGatewayConfig?: Parameters<typeof setPaymentGatewayConfig>[0];
      saleType?: Parameters<typeof setSaleType>[0];
      stagedPurchases?: Parameters<typeof setStagedPurchases>[0];
      timeEstimateReturned?: Parameters<typeof setTimeEstimateReturned>[0];
      cachedMenu?: Parameters<typeof setCachedMenu>[0];
    }
  >(),
);

const SET_DELIVERY_NOTES = '$SET_DELIVERY_NOTES';
export const setDeliveryNotes = createCustomAction(
  SET_DELIVERY_NOTES,
  singleValuePayload<typeof SET_DELIVERY_NOTES, string>(),
);

const SET_REMAINING_TOTAL = '$SET_REMAINING_TOTAL';
export const setRemainingTotal = createCustomAction(
  SET_REMAINING_TOTAL,
  singleValuePayload<typeof SET_REMAINING_TOTAL, number>(),
);

const ADD_SELECTED_PAYMENT_METHOD = '$ADD_SELECTED_PAYMENT_METHOD';
export const addSelectedPaymentMethod = createCustomAction(
  ADD_SELECTED_PAYMENT_METHOD,
  singleValuePayload<typeof ADD_SELECTED_PAYMENT_METHOD, SubPayment>(),
);

const REMOVE_SELECTED_PAYMENT_METHOD = '$REMOVE_SELECTED_PAYMENT_METHOD';
export const removeSelectedPaymentMethod = createCustomAction(
  REMOVE_SELECTED_PAYMENT_METHOD,
  singleValuePayload<typeof REMOVE_SELECTED_PAYMENT_METHOD, PAYMENT_METHOD>(),
);

const SET_SELECTED_PAYMENT_METHODS = '$SET_SELECTED_PAYMENT_METHODS';
export const setSelectedPaymentMethods = createCustomAction(
  SET_SELECTED_PAYMENT_METHODS,
  singleValuePayload<typeof SET_SELECTED_PAYMENT_METHODS, SubPayment[]>(),
);

const SET_STOCK_BALANCES = '$SET_STOCK_BALANCES';
export const setStockBalances = createCustomAction(
  SET_STOCK_BALANCES,
  singleValuePayload<typeof SET_STOCK_BALANCES, StockBalance[]>(),
);

const SET_CUTOFF_TIMES = '$SET_CUTOFF_TIMES';
export const setCutoffTimes = createCustomAction(
  SET_CUTOFF_TIMES,
  singleValuePayload<typeof SET_CUTOFF_TIMES, CutoffTime[]>(),
);

const SET_ETAG = '$SET_ETAG';
export const setEtag = createCustomAction(
  SET_ETAG,
  singleValuePayload<typeof SET_ETAG, string | null>(),
);
