import PastOrdersScreen, { PAST_ORDERS_SCREEN_ROUTE } from '../screens/PastOrdersScreen';
import FavouritesScreen, { FAVOURITES_SCREEN_ROUTE } from '../screens/FavouritesScreen';
import InitialScreen, { INITIAL_SCREEN_ROUTE } from '../screens/InitialScreen';
import CompletedOrderScreen, {
  COMPLETED_ORDER_SCREEN_ROUTE,
} from '../screens/CompletedOrderScreen';
import MenuScreen, { MENU_SCREEN_ROUTE } from '../screens/MenuScreen';
import ReviewOrderScreen, { REVIEW_ORDER_SCREEN_ROUTE } from '../screens/ReviewOrderScreen';
import RewardsScreen, { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';
import ReplaceCardScreen, { REPLACE_CARD_ROUTE } from '../screens/ReplaceCardScreen';
import ClaimMissingPointsScreen, {
  CLAIM_MISSING_POINTS_ROUTE,
} from '../screens/ClaimMissingPointsScreen';
import OffersScreen, { OFFERS_ROUTE } from '../screens/OffersScreen';
import ProfileScreen, { PROFILE_ROUTE } from '../screens/ProfileScreen';
import PurchaseGiftCardScreen, {
  PURCHASE_GIFT_CARD_ROUTE,
} from '../screens/PurchaseGiftCardScreen';
import GiftCardCheckBalanceScreen, {
  CHECK_BALANCE_ROUTE,
} from '../screens/GiftCardCheckBalanceScreen';
import GiftCardTopUp, { GIFT_CARD_TOP_UP_ROUTE } from '../screens/GiftCardTopUp';
import AddLoyaltyWalletScreen, {
  ADD_LOYALTY_WALLET_ROUTE,
} from '../screens/AddLoyaltyWalletScreen';
import MemberMoneyScreen, { MEMBER_MONEY_ROUTE } from '../screens/MemberMoneyScreen';
import TransactionsScreen, { TRANSACTIONS_SCREEN } from '../screens/TransactionsScreen';

import { store } from '../store';

interface DropdownModalMenu {
  label: string;
  path: string;
  private: boolean;
  title?: string;
  subTitle?: string;
  subRoutes?: SubMenu[];
}

interface SubMenu {
  label: string;
  path: string;
  subTitle: string;
}

export const giftcardRoutes = [
  PURCHASE_GIFT_CARD_ROUTE,
  CHECK_BALANCE_ROUTE,
  GIFT_CARD_TOP_UP_ROUTE,
  ADD_LOYALTY_WALLET_ROUTE,
];

export const menu: DropdownModalMenu[] = [
  {
    label: 'button.giftCard',
    path: PURCHASE_GIFT_CARD_ROUTE,
    title: 'giftCarHeader',
    subTitle: 'purchaseGiftCard',
    private: false,
    subRoutes: [
      {
        label: 'purchaseGiftCard',
        path: PURCHASE_GIFT_CARD_ROUTE,
        subTitle: 'purchaseGiftCard',
      },
      {
        label: 'checkBalance',
        path: CHECK_BALANCE_ROUTE,
        subTitle: 'checkBalance',
      },
      {
        label: 'giftCardTopUp',
        path: GIFT_CARD_TOP_UP_ROUTE,
        subTitle: 'giftCardTopUp',
      },
      {
        label: 'addLoyaltyWallet',
        path: ADD_LOYALTY_WALLET_ROUTE,
        subTitle: 'addLoyaltyWallet',
      },
    ],
  },
];

export const loyaltyMenu: DropdownModalMenu[] = [
  {
    label: 'button.rewards',
    path: REWARDS_SCREEN_ROUTE,
    private: true,
  },
  {
    label: 'button.offers',
    path: OFFERS_ROUTE,
    title: 'offersHeader',
    private: true,
  },
  {
    label: 'button.profile',
    path: PROFILE_ROUTE,
    title: 'profileHeader',
    subTitle: 'profileDetails',
    private: true,
  },
  {
    label: 'button.giftCard',
    path: PURCHASE_GIFT_CARD_ROUTE,
    title: 'giftCarHeader',
    subTitle: 'purchaseGiftCard',
    private: false,
    subRoutes: [
      {
        label: 'purchaseGiftCard',
        path: PURCHASE_GIFT_CARD_ROUTE,
        subTitle: 'purchaseGiftCard',
      },
      {
        label: 'checkBalance',
        path: CHECK_BALANCE_ROUTE,
        subTitle: 'checkBalance',
      },
      {
        label: 'giftCardTopUp',
        path: GIFT_CARD_TOP_UP_ROUTE,
        subTitle: 'giftCardTopUp',
      },
      {
        label: 'addLoyaltyWallet',
        path: ADD_LOYALTY_WALLET_ROUTE,
        subTitle: 'addLoyaltyWallet',
      },
    ],
  },
  {
    label: 'memberMoneyHeader',
    path: MEMBER_MONEY_ROUTE,
    title: 'memberMoneyHeader',
    subTitle: 'button.topupButton',
    private: true,
  },
  {
    label: 'button.favouriteOrders',
    path: FAVOURITES_SCREEN_ROUTE,
    title: 'favouriteOrdersHeader',
    private: true,
  },
  {
    label: 'button.pastOrdersMemberButton',
    path: PAST_ORDERS_SCREEN_ROUTE,
    private: true,
  },
  {
    label: 'button.transactions',
    path: TRANSACTIONS_SCREEN,
    private: true,
    title: 'transactionsHeader',
  },
  {
    label: 'button.claimMissingPoints',
    path: CLAIM_MISSING_POINTS_ROUTE,
    title: 'claimMissingPointsHeader',
    private: true,
  },
  {
    label: 'button.replaceCard',
    path: REPLACE_CARD_ROUTE,
    title: 'replaceCardHeader',
    private: true,
  },
];

interface Routes {
  path: string;
  exact: boolean;
  component: React.ComponentType;
  private: boolean;
  useWrapper?: boolean;
}

const defaultRoutes: Routes[] = [
  {
    path: INITIAL_SCREEN_ROUTE,
    exact: true,
    component: InitialScreen,
    private: false,
  },
  {
    path: COMPLETED_ORDER_SCREEN_ROUTE,
    exact: false,
    component: CompletedOrderScreen,
    private: false,
  },

  {
    path: MENU_SCREEN_ROUTE,
    exact: false,
    component: MenuScreen,
    private: false,
  },

  {
    path: REVIEW_ORDER_SCREEN_ROUTE,
    exact: false,
    component: ReviewOrderScreen,
    private: false,
  },
];

const loyaltyRoutes: Routes[] = [
  {
    path: FAVOURITES_SCREEN_ROUTE,
    exact: false,
    component: FavouritesScreen,
    private: true,
  },
  {
    path: PAST_ORDERS_SCREEN_ROUTE,
    exact: false,
    component: PastOrdersScreen,
    private: true,
  },

  {
    path: MEMBER_MONEY_ROUTE,
    exact: false,
    component: MemberMoneyScreen,
    private: true,
  },
  {
    path: OFFERS_ROUTE,
    exact: false,
    component: OffersScreen,
    private: true,
  },
  {
    path: PROFILE_ROUTE,
    exact: false,
    component: ProfileScreen,
    private: true,
  },

  {
    path: REPLACE_CARD_ROUTE,
    exact: false,
    component: ReplaceCardScreen,
    private: true,
  },
  {
    path: CLAIM_MISSING_POINTS_ROUTE,
    exact: false,
    component: ClaimMissingPointsScreen,
    private: true,
  },
  {
    path: REWARDS_SCREEN_ROUTE,
    exact: false,
    component: RewardsScreen,
    private: true,
  },
  {
    path: TRANSACTIONS_SCREEN,
    exact: false,
    component: TransactionsScreen,
    private: true,
  },
];

const giftCardRoutes: Routes[] = [
  {
    path: GIFT_CARD_TOP_UP_ROUTE,
    exact: false,
    component: GiftCardTopUp,
    private: false,
    useWrapper: true,
  },
  {
    path: ADD_LOYALTY_WALLET_ROUTE,
    exact: false,
    component: AddLoyaltyWalletScreen,
    private: false,
    useWrapper: true,
  },
  {
    path: PURCHASE_GIFT_CARD_ROUTE,
    exact: false,
    component: PurchaseGiftCardScreen,
    private: false,
    useWrapper: true,
  },
  {
    path: CHECK_BALANCE_ROUTE,
    exact: false,
    component: GiftCardCheckBalanceScreen,
    private: false,
    useWrapper: true,
  },
];

export const getDefaultDropdownMenu = () => {
  const isGiftcardPurchaseEnabled = store.getState().config.enableGiftcardPurchase;
  const disabledRoutes = isGiftcardPurchaseEnabled ? [] : [PURCHASE_GIFT_CARD_ROUTE];
  const isWalletEnabled = store.getState().config.enableLoyaltyWallet;

  return isGiftcardPurchaseEnabled
    ? isWalletEnabled
      ? menu
      : menu.map(item => {
          if (item.subRoutes) {
            item.subRoutes = item.subRoutes?.filter(
              route => route.path !== ADD_LOYALTY_WALLET_ROUTE,
            );
          }
          return item;
        })
    : menu.filter(item => !disabledRoutes.includes(item.path));
};

export const getLoyaltyDropdownMenu = () => {
  const disabledLoyaltyRoutes = store.getState().config.disabledLoyaltyRoutes;
  const isGiftcardPurchaseEnabled = store.getState().config.enableGiftcardPurchase;
  const disabledRoutes = isGiftcardPurchaseEnabled ? [] : [PURCHASE_GIFT_CARD_ROUTE];
  const isWalletEnabled = store.getState().config.enableLoyaltyWallet;

  return loyaltyMenu
    .filter(menu => !disabledLoyaltyRoutes?.includes(menu.path))
    .filter(menu => !disabledRoutes?.includes(menu.path))
    .map(menu => {
      if (!isWalletEnabled && menu.subRoutes) {
        menu.subRoutes = menu.subRoutes?.filter(route => route.path !== ADD_LOYALTY_WALLET_ROUTE);
      }
      return menu;
    });
};

export const getAvailableRoutes = () => {
  return {
    defaultRoutes,
    loyaltyRoutes,
    giftCardRoutes,
  };
};
