import { call, put, select } from 'redux-saga/effects';
import lodash, { get } from 'lodash';
import i18next from 'i18next';

import {
  OrderingLifecycleHooks,
  OrderingConstants,
  OrderingOperations,
  OrderingSelectors,
  requestAndWaitForFlow,
  OrderingLifecycleEvents,
} from 'polygon-ordering';

import debug from '../utils/debug';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from '../utils/snackbar';

import getCurrentSavedCardToken from '../selectors/getCurrentSavedCardToken';

import { setCheckoutInProgress } from '../slices/checkoutInProgress';
import { purchase } from '../utils/analytics';

const finaliseOrderLifeCycle = OrderingLifecycleEvents.finaliseOrder;
const { updateKeyOrderProperty, finaliseOrder, validateOrder } = OrderingOperations;
const {
  getSaleType,
  getMember,
  getSelectedPaymentMethods,
  getStagedPurchases,
  getFreeOrderReadyToSubmit,
} = OrderingSelectors;
const { SALE_TYPES, FAILURE_REASON } = OrderingConstants;

function alertForFailedValidation(payload) {
  let title;
  let subtitle;

  const getFailureReasonString = reason => i18next.t(`failureReason.${reason}`);

  switch (lodash.get(payload, 'reason')) {
    case FAILURE_REASON.FETCH_FAILED: {
      title = getFailureReasonString(FAILURE_REASON.FETCH_FAILED);
      break;
    }
    case FAILURE_REASON.LOCATION_OFFLINE: {
      title = getFailureReasonString(FAILURE_REASON.LOCATION_OFFLINE);
      break;
    }
    case FAILURE_REASON.LOCATION_CLOSED: {
      title = getFailureReasonString(FAILURE_REASON.LOCATION_CLOSED);
      break;
    }
    case FAILURE_REASON.UNKNOWN:
    default:
      title = getFailureReasonString(FAILURE_REASON.UNKNOWN);
      subtitle = lodash.get(payload, 'errorText');
  }

  enqueueErrorSnackbar(title + (subtitle ? '\n' + subtitle : ''));
}

export default function* checkout({ payload = {} }) {
  debug('checkout', { payload });

  function customerConfirm() {
    let value = window.confirm(
      'Your estimated delivery time has been updated. Would you like to continue?',
    );
    if (value === true) {
      return true;
    } else {
      proceedToFinalise = false; //posible bug
      return false;
    }
  }

  yield put(setCheckoutInProgress(true));

  let proceedToFinalise = true;

  try {
    const memberPresent = Boolean(yield select(getMember));
    const firstValidationResult = yield call(requestAndWaitForFlow, validateOrder, {
      authenticationMethod: memberPresent ? 'member' : 'none',
    });
    const saleType = yield select(getSaleType);
    // TODO: enhance this to try fetching menu/order fees+surcharges for all sale types if validation fails
    // (must stop checkout and alert user if order total increases)
    if (!lodash.get(firstValidationResult, 'success.valid')) {
      if (saleType !== SALE_TYPES.DELIVERY) {
        alertForFailedValidation(firstValidationResult.success);
        proceedToFinalise = false;
      } else {
        yield call(requestAndWaitForFlow, updateKeyOrderProperty, {
          autoApply: true,
          updateDeliveryEstimate: true,
        });
        const secondValidationResult = yield call(requestAndWaitForFlow, validateOrder, {
          authenticationMethod: memberPresent ? 'member' : 'none',
        });
        if (!lodash.get(secondValidationResult, 'success.valid')) {
          alertForFailedValidation(secondValidationResult.success);
          proceedToFinalise = false;
        } else {
          customerConfirm();
          enqueueSuccessSnackbar('Delivery time updated to reflect cart total');
        }
      }
    }
    if (proceedToFinalise) {
      const currentSavedCardToken = yield select(getCurrentSavedCardToken);
      const selectedPaymentMethods = yield select(getSelectedPaymentMethods);
      const freeOrderReadyToSubmit = yield select(getFreeOrderReadyToSubmit);

      if (selectedPaymentMethods.length !== 0) {
        yield call(requestAndWaitForFlow, finaliseOrder, {
          subPayments: selectedPaymentMethods,
          paymentGatewayToken: currentSavedCardToken,
          authenticationMethod: memberPresent ? 'member' : 'none',
        });
      } else if (freeOrderReadyToSubmit) {
        yield call(requestAndWaitForFlow, finaliseOrder, {
          subPayments: [{ method: 'free', amount: 0 }],
          authenticationMethod: memberPresent ? 'member' : 'none',
        });
      }

      // OrderingPaymentHooks.subscribe

      const { subscribe } = OrderingLifecycleHooks;
      const stagedPurchases = yield select(getStagedPurchases);

      subscribe(finaliseOrderLifeCycle.SUCCEEDED, ({ payload: { subPayments } }) => {
        if (stagedPurchases) {
          let total =
            selectedPaymentMethods.length > 0
              ? selectedPaymentMethods
                  .map(payment => payment.amount)
                  .reduce((prev, next) => prev + next)
              : 0;

          purchase(
            stagedPurchases.map(purchaseItem => {
              return {
                ...purchaseItem.item,
                totalPrice: purchaseItem.moneyPrice,
              };
            }),
            total,
          );
        }
      });
    }
  } catch (e) {
    debug(false, 'there was a problem during checkout', { e });
  }

  yield put(setCheckoutInProgress(false));
}
