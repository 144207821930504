import { call, put, takeEvery, all, select, delay } from 'redux-saga/effects';
import lodash from 'lodash';

import * as submitSale from '../../actionCreators/flows/submitSale';

import getSaleBody from '../../selectors/getSaleBody';
import getLocation from '../../selectors/getLocation';
import getOrderUUID from '../../selectors/getOrderUUID';
import getPayment from '../../selectors/getPayment';
import getPaymentGatewayConfig from '../../selectors/getPaymentGatewayConfig';
import {
  getEnableDuplicateSaleProtection,
  getMerchantConfig,
} from '../../selectors/config';

import Logger from '../../utils/Logger';
import PaymentHooks from '../../utils/PaymentHooks';
import processSale from '../../utils/processors/processSale';
import Api, { FetchParams } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import smoothPickupTime from '../../utils/ordering/smoothPickupTime';

import { FAILURE_REASON } from '../../constants/failureReasons';
import { PAYMENT_METHODS, GATEWAY_REQUIRES_ACTION } from '../../constants';
import { PAYMENT_METHOD } from '../../constants/paymentMethod';

import { resetSaleUUID } from '../../actionCreators/currentOrder';

export const requested = createFlowApprover(submitSale);

function determineSaleCharged(e: any) {
  const searchResults = [
    lodash.get(e, 'details.json.additional_info.sale_charged'),
    lodash.get(e, 'details.json.extra_info.sale_charged'),

    // future-proofing against potential error shape changes
    lodash.get(e, 'details.additional_info.sale_charged'),
    lodash.get(e, 'details.extra_info.sale_charged'),
    lodash.get(e, 'additional_info.sale_charged'),
    lodash.get(e, 'extra_info.sale_charged'),
  ].filter(result => result != null);

  if (!searchResults.length) {
    return undefined;
  }

  return searchResults.some(result => result);
}

export function* approved(
  action: ReturnType<typeof submitSale.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'none' },
    meta: { flowId },
  } = action;

  let chargedOverride;
  let reason: submitSale.FailureReason;

  const payment = (yield select(getPayment)) as ReturnType<typeof getPayment>;
  let tempResponse = {};
  let saleError;
  console.log(payment, 'PAYMENT');

  const PAYMENT_GATEWAY_METHODS = [
    PAYMENT_METHODS.APPLE_PAY,
    PAYMENT_METHODS.GOOGLE_PAY,
    PAYMENT_METHODS.CREDIT_CARD,
    PAYMENT_METHODS.EFTPOS,
    PAYMENT_METHOD.SAVED_CARD,
    PAYMENT_METHOD.ALIPAY,
  ];

  const subPayments = payment?.subPayments;

  const matchingPayment = subPayments?.find(subPayment => {
    return PAYMENT_GATEWAY_METHODS.includes(subPayment.method);
  });

  const paymentGatewayMethod = matchingPayment?.method; // extract out the payment type its not from our system. Passed to gateway hook

  try {
    const baseBody = (yield select(getSaleBody)) as ReturnType<
      typeof getSaleBody
    >;

    let body;

    // Body changes depending on whether or not more than 1 payment method is used
    if (baseBody.Payments) {
      if (baseBody.Payments.length === 1) {
        const { Payments, ...baseBodyWithoutPayments } = baseBody;
        body = {
          ...baseBodyWithoutPayments,
          ...baseBody.Payments[0],
        };
      } else if (baseBody.Payments.length > 1) {
        body = {
          ...baseBody,
          MultiplePaymentMode: 'PART',
        };
      }
    } else {
      throw new Error('payment method is undefined');
    }

    if (body.SaleType === 105) {
      body.PickupTime = 'ASAP';
    }

    if (body.PickupTime != undefined) {
      const location = (yield select(getLocation)) as POSLocation;

      body.PickupTime = smoothPickupTime(
        lodash.get(baseBody, 'PickupTime'),
        location.averageOrderWaitTime,
      );
    }

    const saleParams: FetchParams = {
      path: {
        trusted: '/api/v1/authsale2',
        member: '/api/v1/sale',
        none: '/api/v1/sale/nonmember',
      }[authenticationMethod],
      method: 'POST',
      body,
      applicationErrorAllowed: true,
    };

    Logger.log('submitting sale:', undefined, body);
    Logger.log(JSON.stringify(saleParams, null, 2));

    let rawSale: RawSale | undefined;

    let skipDuplicateProtection = false;

    try {
      let response = yield call(Api.fetch, saleParams, authenticationMethod);

      if (response.error_code === 206) {
        // payment gateway says action is required

        const hook: any = PaymentHooks.get(GATEWAY_REQUIRES_ACTION);

        if (!hook) {
          throw new Error('payment hook not set');
        }

        const paymentGatewayConfig = yield select(getPaymentGatewayConfig);
        const merchantConfig = yield select(getMerchantConfig);

        console.log(paymentGatewayConfig, 'PAYMENT GATEWAY CONFIG');

        const hookResult = yield call(hook, {
          serverData: response.extra_info,
          paymentGatewayConfig,
          paymentGatewayMethod,
          merchantConfig,
        });

        if (!hookResult.success) {
          skipDuplicateProtection = true;
          chargedOverride = false;
          reason = FAILURE_REASON.PAYMENT_AUTHENTICATION_FAILED;

          throw new Error(
            lodash.get(hookResult, 'error.message') || 'required action failed',
          );
        }

        // resubmit sale with proof of action

        const resubmitSaleParams: FetchParams = {
          path: {
            trusted: '/api/v1/authsale2/finalise',
            member: '/api/v1/sale/finalise',
            none: '/api/v1/sale/finalise/nonmember',
          }[authenticationMethod],
          method: 'POST',
          body: {
            ...hookResult.data,
          },
        };

        response = yield call(
          Api.fetch,
          resubmitSaleParams,
          authenticationMethod,
        );
      } else if (!response.success) {
        // TODO: this may need a bit of filtering in the future if the error message is too terse or technical but
        // its probably better than a catch all message
        tempResponse = response;
        throw new Error(response.error);
      }

      rawSale = response.data;
    } catch (e) {
      saleError = e;
      const enableDuplicateSaleProtection = yield select(
        getEnableDuplicateSaleProtection,
      );

      const uuid = yield select(getOrderUUID);

      // The below checks if a csvError exists and skips the duplicate sale call if it does.
      // This prevents web ordering from doing the check which was returning an error for nonmember transactions
      if (e) {
        chargedOverride = determineSaleCharged(tempResponse ? tempResponse : e);
      }

      if (skipDuplicateProtection || !enableDuplicateSaleProtection || !uuid) {
        throw e;
      }

      Logger.log('checking for sale via uuid', undefined, { uuid });

      const checkParams: FetchParams = {
        path: {
          trusted: `/api/v1/auth/orderstatus/uuid/${uuid}`,
          member: `/api/v1/profile/orders?qf=uuid&qv=${uuid}`,
          none: `/api/v1/sale/order/uuid/${uuid}`,
        }[authenticationMethod],
        method: 'GET',
      };

      yield delay(300);

      const response = yield call(Api.fetch, checkParams, authenticationMethod);

      rawSale = response.data;

      if (!rawSale?.StatusID || rawSale.StatusID === 255) {
        yield put(resetSaleUUID());
        throw e;
      }

      // member auth method endpoint returns an array, even if uuid not found
      if (Array.isArray(rawSale)) {
        if (rawSale.length === 0) {
          chargedOverride = false;
          throw e;
        }

        rawSale = rawSale[0];
      }

      Logger.log('sale found via uuid');
    }

    if (!rawSale) {
      throw new Error('missing sale details');
    }

    const processedSale = processSale(rawSale);

    yield put(
      submitSale.actions.succeeded(
        {
          saleDetails: processedSale,
          paymentGatewayClientSecret:
            rawSale.paymentData?.payment_intent_client_secret,
        },
        flowId,
      ),
    );
  } catch (e) {
    if (e.message === 'Verify PIN does not match') {
      reason = FAILURE_REASON.BAD_GIFT_CARD;
    }

    const PRE_CHARGE_METHODS = [
      PAYMENT_METHODS.APPLE_PAY,
      PAYMENT_METHODS.GOOGLE_PAY,
      PAYMENT_METHODS.CREDIT_CARD,
      PAYMENT_METHODS.EFTPOS,
    ];

    const chargeStarted = subPayments?.some(subPayment => {
      if (PRE_CHARGE_METHODS.includes(subPayment.method as any)) {
        return true;
      }
    });

    const wasEftpos = subPayments?.some(subPayment => {
      if (PAYMENT_METHODS.EFTPOS === (subPayment.method as any)) {
        return true;
      }
    });

    let charged;

    if (chargedOverride != null) {
      charged = chargedOverride;
    } else if (wasEftpos) {
      charged = true;
    } else {
      //@ts-ignore
      charged = determineSaleCharged(tempResponse);
    }

    if (charged == null) {
      charged = undefined;
    }

    yield put(
      submitSale.actions.failed(
        {
          //@ts-ignore
          error: makeErrorSerialisable(saleError ? saleError : e),
          reason,
          charged,
          chargeStarted,
        },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(submitSale.events.REQUESTED, requested),
    takeEvery(submitSale.events.APPROVED, approved),
  ]);
}
