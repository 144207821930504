import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchLocations from '../../actionCreators/flows/fetchLocations';

import { normaliseArray } from '../../utils/misc';
import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import processLocation from '../../utils/processors/processLocation';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(fetchLocations);

export function* approved(
  action: ReturnType<typeof fetchLocations.actions.approved>,
) {
  const {
    payload: { locationId, rawLocations },
    meta: { flowId },
  } = action;

  try {
    let locationsToProcess: RawLocation[];

    if (rawLocations && rawLocations.length) {
      locationsToProcess = rawLocations;
    } else {
      const params: FetchParams = {
        path: locationId
          ? `/api/v1/stores/${locationId}?hide_invisible=0`
          : '/api/v1/stores?hide_invisible=0',
        method: 'GET',
      };

      const response: ApiResponse = yield call(Api.fetch, params);
      locationsToProcess = locationId ? [response.data] : response.data;
    }

    const processedLocations = locationsToProcess.map(processLocation);

    yield put(
      fetchLocations.actions.succeeded(
        {
          locations: normaliseArray(processedLocations, 'id'),
          merge: Boolean(locationId),
        },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      fetchLocations.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchLocations.events.REQUESTED, requested),
    takeEvery(fetchLocations.events.APPROVED, approved),
  ]);
}
