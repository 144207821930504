import moment, { Moment } from 'moment';

export default function calculateFutureDatedOrderingWindow(
  pickupTime: string,
  location: POSLocation | undefined,
  averageOrderWaitTime: number = 0,
  openingPadding: number = 0,
  closingPadding: number = 0,
  minuteStep: number,
): {
  earliestTime: string;
  latestTime: string;
  openingSession?: { start: string; end: string }[];
} {
  const { closingTime, openingTime, tradingHours, customHours, holidayHours } =
    location as POSLocation;

  let earliestTime = moment(openingTime)
      .add(openingPadding, 'minute')
      .add(averageOrderWaitTime, 'minute')
      .toISOString(),
    latestTime = moment(closingTime)
      .subtract(closingPadding, 'minute')
      .subtract(averageOrderWaitTime, 'minute')
      .toISOString();

  let result = customHoursValidate(customHours, moment(pickupTime));

  if (result) {
    return {
      earliestTime: result.earliestTime
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute')
        .toISOString(),
      latestTime: result.latestTime
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute')
        .toISOString(),
    };
  }

  result = holidayHoursValidate(tradingHours, holidayHours, moment(pickupTime));

  if (result) {
    return {
      earliestTime: result.earliestTime
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute')
        .toISOString(),
      latestTime: result.latestTime
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute')
        .toISOString(),
    };
  }
  //Mon - Sun opening hours
  const openingDay = moment(pickupTime).format('dddd');
  const tTradingHours = tradingHours.find(e => e.name === openingDay);

  //selected date opening and closing time
  const py = moment(pickupTime).year();
  const pm = moment(pickupTime).month();
  const pd = moment(pickupTime).date();

  const oh = tTradingHours?.openingTime.split(':')[0];
  const om = tTradingHours?.openingTime.split(':')[1];

  const ch = tTradingHours?.closingTime.split(':')[0];
  const cm = tTradingHours?.closingTime.split(':')[1];

  const ot = moment([py, pm, pd, Number(oh), Number(om)]);
  const ct = moment([py, pm, pd, Number(ch), Number(cm)]);
  //selected date opening and closing time

  //close all day
  if (tTradingHours?.closed) {
    return {
      earliestTime: moment([py, pm, pd, 0, 0]).toISOString(),
      latestTime: moment([py, pm, pd, 0, 0]).toISOString(),
    };
  }

  //yesterday opening hours
  const yesterday = ot.clone().subtract(1, 'days');

  const yTradingHours = tradingHours.find(
    e => e.name === yesterday.format('dddd'),
  )!;

  let yesterdayClosingHour = yTradingHours.closingTime;
  let yesterdayOpeningHour = yTradingHours.openingTime;

  const yy = moment(yesterday).year();
  const ym = moment(yesterday).month();
  const yd = moment(yesterday).date();
  let ych = yesterdayClosingHour.split(':')[0]; //yesterday closing hour
  let ycm = yesterdayClosingHour.split(':')[1]; //yesterday closing minute
  let yoh = yesterdayOpeningHour.split(':')[0]; //yesterday opening hour
  let yom = yesterdayOpeningHour.split(':')[1]; //yesterday opening minute
  //yesterday opening hours
  result = customHoursValidate(customHours, yesterday);
  if (result) {
    const { earliestTime, latestTime } = result;
    ych = latestTime.hour().toString();
    ycm = latestTime.minute().toString();
    yoh = earliestTime.hour().toString();
    yom = earliestTime.minute().toString();
  } else {
    const result = holidayHoursValidate(tradingHours, holidayHours, yesterday);
    if (result) {
      const { earliestTime, latestTime } = result;
      ych = latestTime.hour().toString();
      ycm = latestTime.minute().toString();
      yoh = earliestTime.hour().toString();
      yom = earliestTime.minute().toString();
    }
  }

  const yct =
    Number(yoh + yom) > Number(ych + ycm)
      ? moment([py, pm, pd, ych, ycm])
      : moment([yy, ym, yd, ych, ycm]); //yesterday closing time

  const openingSession = []; //multi opening sessiong

  //
  if (Number(yoh + yom) > Number(ych + ycm)) {
    if (
      yct.diff(moment([py, pm, pd]), 'minute') < minuteStep ||
      yct > ot ||
      ot.diff(yct, 'minute') < minuteStep
    ) {
      //yesterday closing time will not affect todays opening time
      earliestTime = ot
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute')
        .toISOString();
      latestTime = (ot > ct ? moment([py, pm, pd, 23, 59]) : ct)
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute')
        .toISOString();
    } else {
      openingSession.push({
        start: moment([py, pm, pd])
          .add(openingPadding, 'minute')
          .add(averageOrderWaitTime, 'minute')
          .toISOString(),
        end: yct
          .subtract(closingPadding, 'minute')
          .subtract(averageOrderWaitTime, 'minute')
          .toISOString(),
      });
      openingSession.push({
        start: ot
          .add(openingPadding, 'minute')
          .add(averageOrderWaitTime, 'minute')
          .toISOString(),
        end: (ot > ct ? moment([py, pm, pd, 23, 59]) : ct)
          .subtract(closingPadding, 'minute')
          .subtract(averageOrderWaitTime, 'minute')
          .toISOString(),
      });

      earliestTime = openingSession[0].start;
      latestTime = openingSession[1].end;
    }
  } else {
    earliestTime = ot
      .add(openingPadding, 'minute')
      .add(averageOrderWaitTime, 'minute')
      .toISOString();
    latestTime = (ot > ct ? moment([py, pm, pd, 23, 59]) : ct)
      .subtract(closingPadding, 'minute')
      .subtract(averageOrderWaitTime, 'minute')
      .toISOString();
  }

  return {
    earliestTime,
    latestTime,
    openingSession: openingSession.length > 0 ? openingSession : undefined,
  };
}

//custom hours settings
const customHoursValidate = (
  customHours: Record<string, CustomHours> | undefined,
  date: Moment,
): { earliestTime: Moment; latestTime: Moment } | undefined => {
  if (customHours && Object.keys(customHours).length > 0) {
    for (const [key, value] of Object.entries(customHours)) {
      if (moment(date).date() === moment(key).date()) {
        const earliestTime = moment(
          value.ot ? `${key} ${value.ot}` : `${key} 00:00`,
        );
        const latestTime = moment(
          value.ct ? `${key} ${value.ct}` : `${key} 00:00`,
        );
        if (!value.closed) {
          return {
            earliestTime,
            latestTime,
          };
        } else {
          return {
            earliestTime,
            latestTime: earliestTime,
          };
        }
      }
    }
  }
  return undefined;
};

//holiday hours settings
const holidayHoursValidate = (
  tradingHours: TradingHours[],
  holidayHours: HolidayHours[] | undefined,
  date: Moment,
): { earliestTime: Moment; latestTime: Moment } | undefined => {
  if (holidayHours && holidayHours.length > 0) {
    for (let i = 0; i < holidayHours.length; i++) {
      const ho = holidayHours[i].Date;
      const d = moment().year() + '-' + ho.slice(2) + '-' + ho.slice(0, 2);

      if (moment(date).dayOfYear() === moment(d).dayOfYear()) {
        //looking for publick opening hours
        const th = tradingHours.find(e => e.name === 'P. Holidays');

        if (th) {
          const earliestTime = moment(
            th.openingTime ? `${d} ${th.openingTime}` : `${d} 00:00`,
          );
          const latestTime = moment(
            th.closingTime ? `${d} ${th.closingTime}` : `${d} 00:00`,
          );
          if (!th.closed) {
            return {
              earliestTime,
              latestTime,
            };
          } else {
            return {
              earliestTime,
              latestTime: earliestTime,
            };
          }
        }
      }
    }
  }
};
