import { store, RootState } from '../store';
import { OrderingSelectors } from 'polygon-ordering';
import { dollarsToCents } from '../libs/polygon-ordering/src/utils/misc';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import moment from 'moment-timezone';
import { GIFTCARD_PURCHASE_SUCCESSFUL_MODAL } from '../modals/GiftcardSuccessModal';

const { getRememberCreditCard } = OrderingSelectors;

export default function constructPaymentRequest(data: ProcessPaymentData) {
  const paymentMethod = (store.getState() as RootState).paymentMethod;
  const savedCardToken = (store.getState() as RootState).savedCardToken;
  const rememberCreditCard = getRememberCreditCard(store.getState() as RootState);
  const profile = (store.getState() as RootState).profile;

  const { route, token, values } = data;
  let path: string = '';
  let modal: string = '';
  let body: PaymentPacket = {
    PaymentType: 'cc',
    PaymentInfo: {
      ...(paymentMethod?.method === PAYMENT_METHOD.SAVED_CARD
        ? { saved_pg_token: savedCardToken }
        : { id: token!.id }),
      remember_pg_token: profile ? rememberCreditCard || undefined : false, // false for giftcards
      last_four: rememberCreditCard ? token?.card?.last4 : undefined,
      useIntents: true,
      paymentMethodType: paymentMethod?.method,
    },
  };

  if (route === 'memberPurchaseGiftCardForSelf') {
    path = '/api/v1/sale/giftcard';
    body.OrderTotal = dollarsToCents(values.Amount!);
    body.Email = profile?.emailAddress;
    body.ConfirmEmail = profile?.emailAddress;
    body.Surname = profile?.surname;
    body.GivenNames = profile?.givenNames;
    modal = GIFTCARD_PURCHASE_SUCCESSFUL_MODAL;
  }

  if (route === 'nonMemberPurchaseGiftCardForSelf') {
    path = '/api/v1/sale/giftcard';
    body.OrderTotal = dollarsToCents(values.Amount!);
    body.Email = values.Email;
    body.ConfirmEmail = values.ConfirmEmail;
    body.Surname = values.LastName;
    body.GivenNames = values.FirstName;
    modal = GIFTCARD_PURCHASE_SUCCESSFUL_MODAL;
  }

  if (route === 'memberPurchaseGiftCardForOthers') {
    path = '/api/v1/sale/giftcard';
    body.Email = profile?.emailAddress;
    body.ConfirmEmail = profile?.emailAddress;
    body.Surname = profile?.surname;
    body.GivenNames = profile?.givenNames;

    body.RecipientEmail = values.RecipientEmail;
    body.RecipientName = values.RecipientName;
    body.RecipientSurname = values.RecipientSurname;
    body.Memo = values.Memo;
    body.OrderTotal = dollarsToCents(values.Amount!);

    if (values.SendTime !== '') {
      body.SendTime = moment(values.SendTime).tz(values.timezone!, true).toISOString();
    }
    modal = GIFTCARD_PURCHASE_SUCCESSFUL_MODAL;
  }

  if (route === 'nonMemberPurchaseGiftCardForOthers') {
    path = '/api/v1/sale/giftcard';
    body.RecipientEmail = values.RecipientEmail;
    body.RecipientName = values.RecipientName;
    body.RecipientSurname = values.RecipientSurname;
    body.Memo = values.Memo;
    body.OrderTotal = dollarsToCents(values.Amount!);

    body.Email = values.Email;
    body.ConfirmEmail = values.ConfirmEmail;
    body.Surname = values.LastName;
    body.GivenNames = values.FirstName;

    if (values.SendTime !== '') {
      body.SendTime = moment(values.SendTime).tz(values.timezone!, true).toISOString();
    }
    modal = GIFTCARD_PURCHASE_SUCCESSFUL_MODAL;
  }

  if (route === 'memberCashTopUp') {
    path = '/api/v1/sale';
    body.HCTopupAmount = dollarsToCents(values.Amount!);
  }

  if (route === 'giftCardTopUp') {
    path = profile ? '/api/v1/sale' : '/api/v1/sale/nonmember';
    body.GCMemberNo = values.MemberNo;
    body.GCTopupAmount = dollarsToCents(Number(values.Amount!));
    body.VerifyPin = values.VerifyPIN;
  }

  return {
    body,
    path,
    modal,
  };
}
