import { createSelector } from 'reselect';
import getMinimumOrderInclusive from './getMinimumOrderInclusive';
import getSelectedOffer from './getSelectedOffer';
import getOrderTotals from './getOrderTotals';
import getMinimumOrderInformation from './getMinimumOrderInformation';

const get = (
  isInclusive: boolean,
  selectedOffer: Offer | undefined,
  orderTotals: OrderTotals | undefined,
  minimumOrderInfo: MinimumOrderInformation | null,
): number => {
  let result = 0;
  if (selectedOffer) {
    if (isInclusive) {
      result = orderTotals
        ? Math.max(
            0,
            (selectedOffer.MinOrderValue || 0) * 100 -
              orderTotals.purchasesMoneyPrice,
          )
        : selectedOffer.MinOrderValue || 0;
    } else {
      result = orderTotals
        ? Math.max(
            0,
            (selectedOffer.MinOrderValue || 0) * 100 -
              (orderTotals.purchasesMoneyPrice -
                orderTotals.purchasesMoneyDiscount),
          )
        : selectedOffer.MinOrderValue || 0;
      if (
        minimumOrderInfo &&
        (minimumOrderInfo.amount || 0) >=
          (selectedOffer.MinOrderValue || 0) * 100
      ) {
        result = orderTotals
          ? Math.max(
              0,
              (selectedOffer.MinOrderValue || 0) * 100 -
                (orderTotals.purchasesMoneyPrice || 0),
            )
          : selectedOffer.MinOrderValue || 0;
      }
    }

    // result = isInclusive
    //   ? (selectedOffer.MinOrderValue || 0) * 100 -
    //     orderTotals.purchasesMoneyPrice
    //   : (selectedOffer.MinOrderValue || 0) * 100 -
    //     (orderTotals.purchasesMoneyPrice - orderTotals.purchasesMoneyDiscount);
  }
  return result;
};
const getMinimumCouponMoneyDiff = createSelector(
  [
    getMinimumOrderInclusive,
    getSelectedOffer,
    getOrderTotals,
    getMinimumOrderInformation,
  ],
  get,
);
export default getMinimumCouponMoneyDiff;
