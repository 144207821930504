import React, { useState, useEffect } from 'react';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { OrderingSelectors } from 'polygon-ordering';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import { setCheckoutInProgress } from '../slices/checkoutInProgress';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import RedcatImage from './RedcatImage';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

import '../styles/stripe.css';

const { getPaymentGatewayConfig } = OrderingSelectors;

interface IProps {
  setGooglePayEnabled: (status: boolean) => void;
}

const PaymentButton: React.FC<IProps> = ({ setGooglePayEnabled }) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'AU',
      currency: 'aud',
      total: {
        label: 'Payment Total:',
        amount: 0,
      },
    });

    pr.canMakePayment().then(result => {
      if (result) {
        if (result.googlePay) {
          setGooglePayEnabled(true);
        }
      }
    });
  }, [stripe, elements]);

  return null;
};

const PaymentMethodGooglePay = () => {
  const [googlePayEnabled, setGooglePayEnabled] = useState<boolean | null>(null);
  const dispatch = useAppDispatch();
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const paymentGatewayConfig = useAppSelector(getPaymentGatewayConfig);

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(() =>
    loadStripe(paymentGatewayPublicKey!, {
      stripeAccount: paymentGatewayConfig?.merchantAccount,
    }),
  );

  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentButton
          setGooglePayEnabled={setGooglePayEnabled}
          //@ts-ignore
          setCheckoutInProgress={dispatch(setCheckoutInProgress)} // is this necessary?
        />
      </Elements>
      {googlePayEnabled && (
        <PaymentMethod
          method={PAYMENT_METHOD.GOOGLE_PAY}
          right={
            <div style={styles.buttonContainer}>
              <div style={styles.markContainer}>
                <RedcatImage
                  imagePath={
                    'https://assets.redcatcloud.com.au/webordering/GPay_Acceptance_Mark_800.png'
                  }
                  size={60}
                />
              </div>
            </div>
          }
          subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
        ></PaymentMethod>
      )}
    </>
  );
};

const styles: Styles = {
  buttonContainer: {
    position: 'relative',
  },
  markContainer: {
    position: 'absolute',
    top: -32,
    left: 'auto',
    right: 0,
  },
};

export default PaymentMethodGooglePay;
