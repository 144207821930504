import React from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import toDollarString from '../utils/toDollarString';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import ReorderButton from './ReorderButton';
import ViewInvoice from './ViewInvoice';
import moment from 'moment';
import { AiTwotoneHeart } from 'react-icons/ai';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { FAVOURITE_NAME_MODAL } from '../modals/FavouriteNameModal';

const SinglePastOrder: React.FC<{
  selectedOrder: EnhancedHistoricalOrder;
}> = ({ selectedOrder: order }) => {
  const { t } = useTranslation();
  const deviceType = useAppSelector(state => state.deviceType);
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();

  const tableHeaders = [
    {
      title: 'title.loyaltyDescriptionHeader',
      showMobile: true,
    },
    {
      title: 'title.loyaltyQuantity',
      showMobile: false,
    },
    {
      title: 'title.loyaltyPoints',
      showMobile: false,
    },
    {
      title: 'title.loyaltyPrice',
      showMobile: true,
    },
  ];

  const orderDetails = [
    {
      title: 'title.pastOrdersSaleID',
      themeKey: 'loyaltySubHeaders',
      value: order.id,
    },
    {
      title: 'title.loyaltyDateString',
      themeKey: 'loyaltySubHeaders',
      value: moment(order.orderDate).format('DD/MM/YYYY'),
    },
    {
      title: 'title.loyaltyTimeString',
      themeKey: 'loyaltySubHeaders',
      value: order.orderTime,
    },
  ];

  return (
    <div className="p-3">
      <div className="mt-2">
        <Text themeKey="loyaltySaleType" value={t(`saleType.${order.saleType}`).toUpperCase()} />{' '}
        {order.locationName && (
          <>
            <Text themeKey="loyaltyStoreLocation" value={order.locationName.toUpperCase()} />{' '}
          </>
        )}
      </div>

      {orderDetails.map((order, index) => (
        <div key={index}>
          <Text value={t(order.title)} themeKey="loyaltySubHeaders" />{' '}
          <Text value={String(order.value)} themeKey="loyaltyDefaultText" />
        </div>
      ))}

      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              {tableHeaders.map((header, index) =>
                deviceType === 1 ? (
                  <th
                    key={index}
                    scope="col"
                    style={{ textAlign: header.title === 'title.loyaltyPrice' ? 'right' : 'left' }}
                    className={`border-0 p-0 ${
                      header.title === 'title.loyaltyQuantity' && 'text-center'
                    }`}
                  >
                    <Text value={t(header.title)} themeKey="loyaltySubHeaders" />
                  </th>
                ) : (
                  header.showMobile && (
                    <th
                      key={index}
                      scope="col"
                      style={{
                        textAlign: header.title === 'title.loyaltyPrice' ? 'right' : 'left',
                      }}
                      className={`border-0 p-0 ${
                        header.title === 'title.loyaltyQuantity' && 'text-center'
                      }`}
                    >
                      <Text value={t(header.title)} themeKey="loyaltySubHeaders" />
                    </th>
                  )
                ),
              )}
            </tr>
          </thead>

          <tbody>
            {order.items.map((item, index) => (
              <tr key={index}>
                <td
                  className="border-0 p-0"
                  style={{ fontSize: 11, width: deviceType === 1 ? '40%' : '80%' }}
                >
                  {item.name}
                </td>
                {deviceType !== 2 && (
                  <>
                    <td className="border-0 p-0 text-center" style={{ fontSize: 11, width: '20%' }}>
                      {item.quantity}
                    </td>
                    <td className="border-0 p-0 " style={{ fontSize: 11, width: '20%' }}>
                      {item.redeemPoints ? item.redeemPoints / 10 : 0}
                    </td>
                  </>
                )}

                <td
                  className="border-0 p-0 "
                  style={{ fontSize: 11, width: '20%', textAlign: 'right' }}
                >
                  {toDollarString(item.moneyPrice)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col" className={`border-0 p-0`} style={{ width: '80%' }}>
                <Text value={t('title.loyaltyTotal')} themeKey="loyaltySubHeaders" />
              </th>
              <th
                scope="col"
                className={`border-0 p-0`}
                style={{ width: '20%', textAlign: 'right' }}
              >
                <Text value={toDollarString(order.moneyPrice)} themeKey="loyaltySubHeaders" />
              </th>
            </tr>
            <tr>
              <th scope="col" className={`border-0 p-0`} style={{ width: '80%' }}>
                <Text value={t('totals.pointsAward')} themeKey="loyaltySubHeaders" />
              </th>
              <th
                scope="col"
                className={`border-0 p-0`}
                style={{ width: '20%', textAlign: 'right' }}
              >
                <Text
                  value={
                    (order.items?.reduce((a, b) => a + (b.redeemPoints || 0), 0) / 10).toString() ||
                    '0'
                  }
                  themeKey="loyaltySubHeaders"
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <ViewInvoice invoiceID={order.id} />

      <div
        style={deviceType === 1 ? { width: 72, bottom: -15, right: 10, position: 'absolute' } : {}}
      >
        <ReorderButton order={order} />
      </div>

      <div className="position-absolute" style={{ top: 10, right: 10 }}>
        <TouchableOpacity
          onClick={() =>
            dispatch(
              setCurrentModal({
                modalId: FAVOURITE_NAME_MODAL,
                params: {
                  historicalOrder: order,
                },
              }),
            )
          }
        >
          <AiTwotoneHeart style={combineStyles(p('activeHeartIcon', ['color']))} />
        </TouchableOpacity>
      </div>
    </div>
  );
};

export default SinglePastOrder;
