// This file contains all the non-transformative selectors that run directly off of the config slice

import { createSelector } from 'reselect';

import getOrdering from './getOrdering';
import { SALE_TYPE } from '../constants/saleType';

export const getConfig = createSelector(
  [getOrdering],
  ordering => ordering.config,
);

export const getMobileRegexWhiteList = createSelector(
  [getConfig],
  config => config.mobileRegexWhiteList,
);

export const getApiHandlerReady = createSelector([getConfig], config =>
  Boolean(config.apiHandlerReady),
);

export const getAttempts = createSelector(
  [getConfig],
  config => config.attempts,
);

export const getUseChoiceSetDiscountMap = createSelector(
  [getConfig],
  config => config.useChoiceSetDiscountMap,
);

export const getchoiceSetDiscountMap = createSelector(
  [getConfig, getUseChoiceSetDiscountMap],
  (config, usechoiceSetDiscountMap) =>
    usechoiceSetDiscountMap ? config.choiceSetDiscountMap : undefined,
);

export const getChoiceSetDiscountConfig = createSelector(
  [getConfig, getUseChoiceSetDiscountMap],
  (config, useChoiceSetDiscountMap) =>
    !useChoiceSetDiscountMap
      ? config.choiceSetQuantityDiscount || undefined
      : undefined,
);

export const getCouponOrderingMethod = createSelector(
  [getConfig],
  config => config.couponOrderingMethod,
);

export const getEftposConfig = createSelector(
  [getConfig],
  config => config.eftpos,
);

export const getFlattenCategories = createSelector([getConfig], config =>
  Boolean(config.flattenCategories),
);

export const getFreeChoiceOrderingMethod = createSelector(
  [getConfig],
  config => config.freeChoiceOrderingMethod,
);

export const getEnabledSaleTypes = createSelector(
  [getConfig],
  config =>
    config.enabledSaleTypes || [
      SALE_TYPE.DELIVERY,
      SALE_TYPE.PICKUP,
      SALE_TYPE.TAKE_AWAY,
      SALE_TYPE.DINE_IN,
      SALE_TYPE.TABLE_ORDER,
    ],
);

export const getInternationalMobileAllowed = createSelector(
  [getConfig],
  config => Boolean(config.internationalMobileAllowed),
);

export const getLoyaltyRequiresVerification = createSelector(
  [getConfig],
  config => Boolean(config.loyaltyRequiresVerification),
);

export const getMenuSource = createSelector(
  [getConfig],
  config => config.menuSource,
);

export const getOrderingWindowPadding = createSelector(
  [getConfig],
  config => config.orderingWindowPadding,
);

export const getPaymentEnvironment = createSelector(
  [getConfig],
  config => config.paymentEnvironment,
);

export const getMerchantConfig = createSelector(
  [getConfig],
  config => config.merchant,
);

export const getProductConfig = createSelector(
  [getConfig],
  config => config.product,
);

export const getRememberCreditCard = createSelector([getConfig], config =>
  Boolean(config.rememberCreditCard),
);

export const getOrderingProvider = createSelector(
  [getConfig],
  config => config.orderingProvider,
);

export const getImportedGiftCardRegex = createSelector(
  [getConfig],
  config => config.importedGiftCardRegex,
);

export const getCardSets = createSelector(
  [getConfig],
  config => config.cardsets,
);

export const getEnableDineInNameMode = createSelector(
  [getConfig],
  config => config.enableDineInNameMode,
);

export const getExcludedChoiceSets = createSelector(
  [getConfig],
  config => config.excludedChoiceSets,
);

export const getBrands = createSelector(
  [getConfig],
  config => config.brands || [],
);

export const getShowHiddenLocations = createSelector(
  [getConfig],
  config => config.showHiddenLocations,
);

export const getEnableMultipleDeliveryEstimate = createSelector(
  [getConfig],
  config => config.enableMultipleDeliveryEstimate,
);

export const getEnableDuplicateSaleProtection = createSelector(
  [getConfig],
  config => config.enableDuplicateSaleProtection,
);

export const getSaleInputType = createSelector(
  [getConfig],
  config => config.saleInputType,
);

export const getEnableDynamicPaymentGatewayConfig = createSelector(
  [getConfig],
  config => config.enableDynamicPaymentGatewayConfig,
);

export const getPayLaterRules = createSelector(
  [getConfig],
  config => config.payLaterRules,
);

export const getHideNegativeIngredients = createSelector(
  [getConfig],
  config => config.hideNegativeIngredients,
);

export const getStockBalanceThresholds = createSelector(
  [getConfig],
  config => config.stockBalanceThresholds,
);

export const getStockBalanceIgnoreThresholds = createSelector(
  [getConfig],
  config => config.stockBalanceIgnoreThresholds,
);

export const getLocaliseOrderTimes = createSelector(
  [getConfig],
  config => config.localiseOrderTimes,
);
