import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import getOrderInProgress from '../selectors/getOrderInProgress';
import getHideTimes from '../selectors/getHideTimes';
import combineStyles from '../utils/combineStyles';
import HeaderBranding from './HeaderBranding';
import HeaderMemberButton from './HeaderMemberButton';
import HeaderCartButton from './HeaderCartButton';
import PickupTime from './PickupTime';
import TableNumber from './TableNumber';
import HeaderBackButton from './HeaderBackButton';
import { REVIEW_ORDER_SCREEN_ROUTE } from '../screens/ReviewOrderScreen';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import '../styles/HeaderBar.css';
import StandardButton from './StandardButton';
import history from '../history';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';

const { getSaleType, getOrderSubmitted } = OrderingSelectors;

interface IProps {
  themeKey: string;
}

const HeaderBar: React.FC<IProps> = ({ themeKey }) => {
  const { t } = useTranslation();
  const saleType = useAppSelector(getSaleType);
  const p = useAppSelector(getThemeLookup);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const hideTimes = useAppSelector(getHideTimes);
  const tableNumberLabel = useAppSelector(state => state.config.tableNumberLabel);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  const { pathname } = useLocation();
  const showBackToMenuButton = [REVIEW_ORDER_SCREEN_ROUTE].includes(pathname);

  const showControls = orderInProgress && !orderSubmitted;
  const screens = getLoyaltyDropdownMenu();

  const loyaltyScreenOn =
    screens.filter(screen =>
      screen.subRoutes
        ? screen.subRoutes.filter(route => route.path === pathname).length !== 0
        : screen.path === pathname,
    ).length !== 0;

  return (
    <div
      style={combineStyles(
        styles.mainContainer,
        p('headerBar', ['padding', 'backgroundColor', 'boxShadow']),
        p(themeKey, ['padding', 'backgroundColor']),
      )}
    >
      {showBackToMenuButton ? <HeaderBackButton /> : <HeaderBranding />}

      <div style={styles.rightSection}>
        {showControls && !loyaltyScreenOn && (
          <>
            {!hideTimes && isPickupOrTakeAway && (
              <PickupTime
                className="SaleTypeHeaderControl"
                themeKey="headerPickupTimeSelect"
                containerStyle={styles.control}
              />
            )}

            {isDineIn && (
              <TableNumber
                className="SaleTypeHeaderControl"
                themeKey="headerTableNumber"
                containerStyle={styles.control}
                placeholder={`${tableNumberLabel || t('tableNumber')}*`}
                style={styles.tableNumber}
              />
            )}
          </>
        )}
        <div className="my-auto">
          {loyaltyScreenOn && !deviceTypeMobile && (
            <StandardButton
              containerStyle={{
                minWidth: 135,
                paddingTop: 0,
                paddingBottom: 0,
                height: 35,
                margin: 10,
              }}
              label={t('button.orderOnline')}
              themeKey="orderOnline"
              onClick={() => history.push('/')}
            />
          )}
        </div>
        <HeaderMemberButton />

        {showControls && <HeaderCartButton />}
      </div>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    width: '100%',
    minHeight: 55,
    maxHeight: 55,
    height: 55,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    zIndex: 2, // so box shadow sits over other screen elements
  },

  title: {
    textTransform: 'uppercase',
  },

  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    minWidth: 0,
    maxWidth: '30vw',
  },

  control: {
    marginRight: 18,
    display: 'flex',
    flexDirection: 'row',
  },

  tableNumber: {
    width: 125,
  },
};

export default HeaderBar;
