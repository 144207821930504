import { createCustomAction } from 'typesafe-actions';

import { AuthenticationMethod } from '../../utils/Api';

import { standardEmptyACC, standardFlowACC } from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$SUBMIT_SALE/REQUESTED';
const BLOCKED = '$SUBMIT_SALE/BLOCKED';
const APPROVED = '$SUBMIT_SALE/APPROVED';
const SUCCEEDED = '$SUBMIT_SALE/SUCCEEDED';
const FAILED = '$SUBMIT_SALE/FAILED';

export type FailureReason =
  | undefined
  | FAILURE_REASON.BAD_GIFT_CARD
  | FAILURE_REASON.PAYMENT_AUTHENTICATION_FAILED;

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      authenticationMethod?: AuthenticationMethod;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      saleDetails: Sale;
      paymentGatewayClientSecret?: string;
    }>(type);
};

const failed = <T>(type: T) => {
  return standardFlowACC<
    T,
    {
      error?: SerialisableException;
      reason: FailureReason;
      charged?: boolean;
      chargeStarted?: boolean;
    }
  >(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(FAILED, failed),
};

export default actions.requested;
