import React, { useState, useEffect } from 'react';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { OrderingSelectors } from 'polygon-ordering';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import RedcatImage from './RedcatImage';
import { useAppSelector } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import '../styles/stripe.css';

const { getPaymentGatewayConfig } = OrderingSelectors;

interface IProps {
  setApplePayEnabled: (status: boolean) => void;
}

const PaymentButton: React.FC<IProps> = ({ setApplePayEnabled }) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'AU',
      currency: 'aud',
      total: {
        label: 'Payment Total:',
        amount: 0,
      },
    });

    pr.canMakePayment().then(result => {
      if (result) {
        if (result.applePay) {
          setApplePayEnabled(true);
        }
      }
    });
  }, [stripe, elements]);

  return null;
};

const PaymentMethodApplePay = () => {
  const [applePayEnabled, setApplePayEnabled] = useState<boolean | null>(null);
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const paymentGatewayConfig = useAppSelector(getPaymentGatewayConfig);

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(() =>
    loadStripe(paymentGatewayPublicKey!, {
      stripeAccount: paymentGatewayConfig?.merchantAccount,
    }),
  );

  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentButton setApplePayEnabled={setApplePayEnabled} />
      </Elements>
      {applePayEnabled && (
        <PaymentMethod
          method={PAYMENT_METHOD.APPLE_PAY}
          right={
            <div style={styles.buttonContainer}>
              <div style={styles.markContainer}>
                <RedcatImage
                  imagePath="https://assets.redcatcloud.com.au/webordering/Apple_Pay_Mark_RGB_041619.svg"
                  size={50}
                />
              </div>
            </div>
          }
          subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
        />
      )}
    </>
  );
};

const styles: Styles = {
  buttonContainer: {
    position: 'relative',
  },
  markContainer: {
    position: 'absolute',
    top: -26,
    left: 'auto',
    right: 0,
  },
};

export default PaymentMethodApplePay;
