import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import Text from '../components/Text';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import toDollarString from '../utils/toDollarString';
import lodash from 'lodash';

const { getTransactionHistory } = OrderingSelectors;

export const TRANSACTIONS_SCREEN = '/loyalty-history';

interface DescriptionMap {
  [key: number]: string;
}

const descriptionMap: DescriptionMap = {
  1: 'Sale',
  2: 'Redeem Points',
  3: 'Top Up',
  4: 'Redeem Money',
  5: 'Points Increase',
  6: 'Points Decrease',
  7: 'Money Increase',
  8: 'Money Decrease',
  9: 'Sale Reward',
  10: 'Redeem Reward',
  66: 'Birthday Gift',
};

const TransactionsScreen: React.FC = () => {
  const orders = useAppSelector(getTransactionHistory);
  return (
    <div>
      {orders?.map((order, index) => (
        <Transaction key={index} order={order} />
      ))}
    </div>
  );
};

const Transaction = ({ order }: { order: TransactionHistory }) => {
  const gst = lodash.sumBy(order.Items, 'GST') || null;

  return (
    <div className="p-3 my-2" style={{ backgroundColor: 'whitesmoke', borderRadius: 5 }}>
      <div style={{ paddingRight: 10 }}>
        {order.Store && (
          <>
            <Text themeKey="loyaltyStoreLocation" value={order.Store.toUpperCase()} />{' '}
          </>
        )}
        <Text
          themeKey="loyaltyOrderDate"
          value={moment(order.TxnDate).format('DD/MM/YYYY hh:mma')}
        />

        {order.TxnType && (
          <div>
            <Text themeKey="loyaltyOrderTotal" value={descriptionMap[order.TxnType]} />{' '}
          </div>
        )}

        {order.SalesID && (
          <div>
            <Text themeKey="loyaltyOrderItems" value={'ID: ' + order.SalesID.toString()} />{' '}
          </div>
        )}

        <div>
          {order.Items?.map((listItem, index) => (
            <div key={index}>
              <Row>
                <Col>
                  <Text themeKey="loyaltyOrderItems" value={listItem.Item} />{' '}
                  <Text themeKey="loyaltyOrderItems" value={'x' + listItem.Qty.toString()} />
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Text themeKey="loyaltyOrderItems" value={toDollarString(listItem.Price)} />
                </Col>
              </Row>
            </div>
          ))}

          {gst && (
            <div>
              <Text themeKey="loyaltyOrderTotal" value={'GST: ' + toDollarString(gst)} />
            </div>
          )}

          <div>
            <Text
              themeKey="loyaltyOrderTotal"
              value={'Total: ' + toDollarString(order.Amount * 100)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsScreen;
