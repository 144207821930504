import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { INITIAL_SCREEN_ROUTE } from './InitialScreen';
import LoyaltyWrapper from '../components/LoyaltyWrapper';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import PrivateRoute from './PrivateRoute';
import { getAvailableRoutes, giftcardRoutes } from '../utils/getAvailableScreens';
import { useAppSelector } from '../app/hooks';
import { ADD_LOYALTY_WALLET_ROUTE } from '../screens/AddLoyaltyWalletScreen';

const { defaultRoutes, loyaltyRoutes, giftCardRoutes } = getAvailableRoutes();

const Routes = () => {
  const loyalyDisabled = useAppSelector(state => state.config.disableLoyalty);

  const disabledLoyaltyRoutes = useAppSelector(state => state.config.disabledLoyaltyRoutes);
  const isGiftcardPurchaseEnabled = useAppSelector(state => state.config.enableGiftcardPurchase);
  const isWalletEnabled = useAppSelector(state => state.config.enableLoyaltyWallet);
  const disableGiftcardRoutes = isGiftcardPurchaseEnabled
    ? isWalletEnabled
      ? []
      : [ADD_LOYALTY_WALLET_ROUTE]
    : isWalletEnabled
    ? giftcardRoutes.filter(item => item !== ADD_LOYALTY_WALLET_ROUTE)
    : giftcardRoutes;

  return (
    <Switch>
      {defaultRoutes.map(route => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => <route.component />}
        />
      ))}

      {giftCardRoutes
        .filter(route => !disableGiftcardRoutes?.includes(route.path))
        .map(route => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={() => (
              <LoyaltyWrapper>
                <route.component />
              </LoyaltyWrapper>
            )}
          />
        ))}

      {!loyalyDisabled &&
        loyaltyRoutes
          .filter(route => !disabledLoyaltyRoutes?.includes(route.path))
          .map(route => (
            <PrivateRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
      <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />
    </Switch>
  );
};

export default Routes;
