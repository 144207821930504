import { combineReducers, AnyAction, Reducer } from 'redux';
import lodash from 'lodash';

import { OrderingReducer } from 'polygon-ordering';

import firstSyncComplete from './firstSyncComplete';
import config from './config';
import currentModalId from './currentModalId';
import previousModalId from './previousModalId';
import modalParams from './modalParams';
import calloutOffsets from './calloutOffsets';
import deviceType from './deviceType';
import deviceDimensions from './deviceDimensions';
import currentUserLocation from './currentUserLocation';
import returnModalDetails from './returnModalDetails';
import currentPaymentMethod from './currentPaymentMethod';
import currentSavedCardToken from './currentSavedCardToken';
import desiredDeliveryAddress from './desiredDeliveryAddress';
import desiredDeliveryTime from './desiredDeliveryTime';
import desiredDeliveryAddressNotFound from './desiredDeliveryAddressNotFound';
import orderDate from './orderDate';
import addressCache from './addressCache';
import locationWhitelist from './locationWhitelist';

import apiHandlerReady from './apiHandlerReady';
import checkoutInProgress from './checkoutInProgress';
import validationInProgress from './validationInProgress';
import creditCardComplete from './creditCardComplete';
import currentCategoryId from './currentCategoryId';
import currentMenuBrandId from './currentMenuBrandId';
import deliveryEstimateFailed from './deliveryEstimateFailed';
import deliveryEstimateInProgress from './deliveryEstimateInProgress';
import handleURLParamsInProgress from './handleURLParamsInProgress';
import keyOrderPropertyUpdateInProgress from './keyOrderPropertyUpdateInProgress';
import menuCategoriesBarHeight from './menuCategoriesBarHeight';
import menuSearchText from './menuSearchText';
import loyaltyHeaders from './loyaltyHeaders';
import loyaltyFilters from './loyaltyFilters';
import loyaltyDateFilters from './loyaltyDateFilters';
import offers from './offers';
import profile from './profile';
import paymentFormInUse from './paymentFormInUse';
import paymentMethod from './paymentMethod';
import paymentFormFilled from './paymentFormFilled';
import savedCardToken from './savedCardToken';
import savedCards from './savedCards';
import loyaltySelectedOffer from './loyaltySelectedOffer';
import cvcFilled from './cvcFilled';

const PERSIST = {
  ordering: OrderingReducer,
  addressCache,
  calloutOffsets,
  config,
  currentModalId,
  currentPaymentMethod,
  currentSavedCardToken,
  currentUserLocation,
  desiredDeliveryAddress,
  desiredDeliveryAddressNotFound,
  desiredDeliveryTime,
  deviceDimensions,
  deviceType,
  firstSyncComplete,
  locationWhitelist,
  modalParams,
  orderDate,
  previousModalId,
  returnModalDetails,
  profile,
};

const rootReducer: Reducer<WebOrderingState, AnyAction> = combineReducers({
  ...PERSIST,
  cvcFilled,
  apiHandlerReady,
  checkoutInProgress,
  creditCardComplete,
  currentCategoryId,
  currentMenuBrandId,
  deliveryEstimateFailed,
  deliveryEstimateInProgress,
  handleURLParamsInProgress,
  keyOrderPropertyUpdateInProgress,
  menuCategoriesBarHeight,
  menuSearchText,
  validationInProgress,
  loyaltyHeaders,
  loyaltyFilters,
  loyaltyDateFilters,
  offers,
  paymentFormInUse,
  paymentMethod,
  paymentFormFilled,
  savedCardToken,
  savedCards,
  loyaltySelectedOffer,
});

export const PERSIST_KEYS = lodash.keys(PERSIST);
export default rootReducer;
