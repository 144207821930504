import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

import toDollarString from '../utils/toDollarString';

import getThemeLookup from '../selectors/getThemeLookup';

import Text from './Text';
import OrderTotalsPaymentSummary from './OrderTotalsPaymentSummary';
import { useAppSelector } from '../app/hooks';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const {
  getOrderTotals,
  getAvailablePaymentMethods,
  getEnabledPaymentMethods,
  getSaleType,
  getSelectedOffer,
} = OrderingSelectors;

const Line = ({
  label,
  amount,
  deemphasised = false,
}: {
  label: string;
  amount: number | string;
  deemphasised?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);
  let additionalStyle;

  if (deemphasised) {
    additionalStyle = p('orderTotalDeemphasised', TEXT_PROPERTIES);
  }

  return (
    <div style={styles.line}>
      <Text themeKey="orderTotal" style={combineStyles(styles.orderTotalLabel, additionalStyle)}>
        {label}
      </Text>

      <Text themeKey="orderTotal" style={additionalStyle}>
        {amount}
      </Text>
    </div>
  );
};

interface IProps {
  pointsVisible?: boolean;
  orderCompleted?: boolean;
  useMobileScreen?: boolean | null;
  totalOverride?: number;
}
const OrderTotals: React.FC<IProps> = ({
  totalOverride,
  pointsVisible = false,
  useMobileScreen,
  orderCompleted,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const orderTotals = useAppSelector(getOrderTotals);
  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const saleType = useAppSelector(getSaleType);

  if (!orderTotals) {
    return null;
  }

  const pmAvailable = (method: PAYMENT_METHOD) => availablePaymentMethods.includes(method);

  const pmEnabled = (method: PAYMENT_METHOD) => enabledPaymentMethods.includes(method);

  const noOfferPresent = !selectedOffer;

  const isDelivery = saleType === SALE_TYPE.DELIVERY;

  const showPointsCost =
    pmAvailable(PAYMENT_METHOD.MEMBER_POINTS) &&
    noOfferPresent &&
    !isDelivery &&
    Boolean(orderTotals.pointsPrice);

  const showPointsAward =
    pmEnabled(PAYMENT_METHOD.MEMBER_POINTS) && noOfferPresent && Boolean(orderTotals.pointsAward);

  const showRewardsAward =
    pmEnabled(PAYMENT_METHOD.MEMBER_REWARDS) && Boolean(orderTotals.rewardsAward);

  return (
    <div style={styles.mainContainer}>
      <div
        style={combineStyles(
          styles.horizontalSeperator,
          p('orderTotalsSeperator', ['backgroundColor']),
        )}
      />

      {!useMobileScreen && !orderCompleted && <OrderTotalsPaymentSummary />}

      {totalOverride != null && (
        <Line label={t('totals.total')} amount={toDollarString(totalOverride)} />
      )}

      {totalOverride == null && (
        <>
          {Boolean(orderTotals.orderMoneyDiscount) && (
            <Line
              label={t('totals.discount')}
              amount={toDollarString(orderTotals.orderMoneyDiscount * -1)}
              deemphasised
            />
          )}

          <Line
            label={t('totals.total')}
            amount={toDollarString(orderTotals.discountedMoneyPrice)}
          />

          {pointsVisible && (
            <>
              {showPointsCost && (
                <Line
                  label={t('totals.pointsPrice')}
                  amount={orderTotals.pointsPrice}
                  deemphasised
                />
              )}

              {showPointsAward && (
                <Line
                  label={t('totals.pointsAward')}
                  amount={orderTotals.pointsAward}
                  deemphasised
                />
              )}

              {showRewardsAward && (
                <Line
                  label={t('totals.rewardsAward')}
                  amount={toDollarString(orderTotals.rewardsAward, { dollarSymbol: '' })}
                  deemphasised
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    paddingBottom: 10,
  },
  horizontalSeperator: {
    height: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
    paddingLeft: 9,
    paddingRight: 9,
  },
  orderTotalLabel: {
    paddingRight: 10,
  },
};

export default OrderTotals;
