import React from 'react';
import { RiCloseCircleFill, RiPencilFill } from 'react-icons/ri';
import { IoMdAlert } from 'react-icons/io';

import combineStyles from '../utils/combineStyles';
import toDollarString from '../utils/toDollarString';
import { SELECT_PROPERTIES } from '../utils/theme';

import StandardButton from './StandardButton';
import Text from '../components/Text';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import calculateStockBalanceData from '../utils/calculateStockBalanceData';
import calcMinBalanceStaged from '../utils/calcMinBalanceStaged';
import calcStagedPurchasesInCart from '../utils/calcStagedPurchasesInCart';
import cartChoiceSetCompactAlert from '../utils/cartChoiceSetCompactAlert';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { useTranslation } from 'react-i18next';

const {
  updateSelectedPaymentMethods,
  adjustStagedPurchaseQuantity,
  removeStagedPurchase,
  openStagedPurchase,
} = OrderingOperations;

const { getCartContainsInvalidQuantities, getStagedPurchases } = OrderingSelectors;
const NUMBERS = Array.from({ length: 99 }, (_, i) => i + 1);

const QuantitySelector = ({
  value,
  onChange,
  maxValue,
}: {
  value?: number;
  onChange: (value?: string) => void;
  maxValue: number;
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <select
      value={value}
      style={combineStyles(
        styles.quantitySelect,
        p('input', SELECT_PROPERTIES),
        p('stagedPurchaseQuantity', SELECT_PROPERTIES),
      )}
      onChange={event => onChange(event?.target?.value)} // note: value is a string
    >
      {maxValue !== undefined
        ? Array.from({ length: maxValue > 0 ? maxValue : 1 }, (_, i) => i + 1).map(n => (
            <option key={n} value={n}>
              {n}
            </option>
          ))
        : NUMBERS.map(n => (
            <option key={n} value={n}>
              {n}
            </option>
          ))}
    </select>
  );
};

const SubLine = ({
  quantity = 0,
  label,
  amount,
  soldOut,
  itemPLU,
  cartHasInvalidItems,
  qtyUpdates,
}: {
  quantity?: number;
  label?: string;
  amount?: number;
  soldOut?: boolean | null;
  itemPLU?: string;
  cartHasInvalidItems?: any;
  qtyUpdates?: any;
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();

  return (
    <div style={styles.subLine}>
      <div style={styles.left}>
        <div style={styles.subLineLeftContent}>
          <Text themeKey="stagedPurchaseSubLine">
            {`${quantity > 1 ? `${quantity} × ` : ''}${label}`}
            {soldOut && (
              <div style={styles.badgeAndMessageContainer}>
                <IoMdAlert
                  style={combineStyles(
                    p('cartItemErrorAlert', ['color']),
                    styles.cartItemErrorAlert,
                  )}
                />
                <Text
                  themeKey="stagedPurchaseSubLine"
                  style={combineStyles(p('cartItemError', ['color']), styles.cartItemError)}
                >
                  {t('itemOutOfStockMessage')}
                </Text>
              </div>
            )}

            {qtyUpdates?.hasOwnProperty(itemPLU) && cartHasInvalidItems && !soldOut && (
              <div style={styles.badgeAndMessageContainer}>
                <IoMdAlert
                  style={combineStyles(
                    p('cartItemWarningAlert', ['color']),
                    styles.cartItemWarningAlert,
                  )}
                />
                <Text
                  themeKey="stagedPurchaseSubLine"
                  style={combineStyles(p('cartItemWarning', ['color']), styles.cartItemWarning)}
                >
                  {t('itemQuantityWarningMessage', {
                    newItemQuantity: qtyUpdates[itemPLU!],
                    someLabel: label,
                  })}
                </Text>
              </div>
            )}
          </Text>
        </div>
      </div>

      {amount != null && amount !== 0 && (
        <Text themeKey="stagedPurchaseSubLine">{toDollarString(amount)}</Text>
      )}
    </div>
  );
};

interface IProps {
  readOnly?: boolean;
  showIngredientAmount?: boolean;
  purchase: PurchaseWithTotals;
  hideQuantityWhenSingular?: boolean;
  qtyUpdates: any;
}

const StagedPurchase: React.FC<IProps> = ({
  purchase,
  readOnly,
  hideQuantityWhenSingular,
  showIngredientAmount,
  qtyUpdates,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const iconStyle = p('stagedPurchaseButtonIcon', ['color', 'fontSize']);
  const hideQuantity = hideQuantityWhenSingular && purchase.quantity === 1;
  const dispatch = useAppDispatch();
  const cartHasInvalidItems = useAppSelector(getCartContainsInvalidQuantities);

  const stockBalanceData = calculateStockBalanceData(purchase.item.id);
  const quantityMap = calcStagedPurchasesInCart(purchase.id);
  const minBalanceStaged = calcMinBalanceStaged(purchase.choicesWithQuantity, quantityMap);
  const choicesAlert = cartChoiceSetCompactAlert(
    purchase.choicesWithQuantity,
    //@ts-ignore
    cartHasInvalidItems.trackQuantityUpdates,
  );

  return (
    <div style={styles.mainContainer}>
      <div style={styles.top}>
        <div style={styles.left}>
          {hideQuantity ? null : readOnly ? (
            <Text themeKey="stagedPurchase">{purchase.quantity}&nbsp;</Text>
          ) : (
            <QuantitySelector
              value={purchase.quantity}
              onChange={value => {
                dispatch(adjustStagedPurchaseQuantity(purchase.id, 0, Number(value)));
              }}
              // using last condition as -Infinity cause undefined won't work in the Max function
              maxValue={Math.max(
                purchase.quantity,
                minBalanceStaged
                  ? minBalanceStaged
                  : stockBalanceData.cartAdjustedBalance
                  ? purchase.quantity + stockBalanceData.cartAdjustedBalance
                  : (choicesAlert.choiceInvalid && !choicesAlert.choiceSoldOut) ||
                    (choicesAlert.choiceInvalid && choicesAlert.choiceSoldOut) ||
                    stockBalanceData.itemBalance! >= 0 //since default value from stockBalanceData will be undefined if it's less then 0
                  ? -Infinity
                  : 99,
              )}
            />
          )}

          <Text themeKey="stagedPurchase">
            {!hideQuantity && <Text themeKey="stagedPurchaseMultiplicationSymbol">{` × `}</Text>}
            {purchase.item.name}
          </Text>
        </div>

        <div style={styles.right}>
          <Text themeKey="stagedPurchase">{toDollarString(purchase.discountedMoneyPrice)}</Text>
        </div>
      </div>

      <div style={styles.subLines}>
        {purchase.choicesWithQuantity.map(choice => (
          <SubLine
            key={choice.id}
            label={choice.name}
            quantity={choice.quantity}
            amount={showIngredientAmount ? choice.moneyPrice : undefined}
            soldOut={stockBalanceData.soldOut}
            itemPLU={choice.id}
            //@ts-ignore
            cartHasInvalidItems={cartHasInvalidItems.cartContainsInvalidQuantities}
            // This can be a safe guard for the CompleteOrderScreen Bug qtyUpdates={qtyUpdates ? qtyUpdates : {}}
            qtyUpdates={qtyUpdates}
          />
        ))}

        {Boolean(purchase.moneyDiscount) && (
          <SubLine label={t('stagedPurchaseDiscount')} amount={purchase.moneyDiscount * -1} />
        )}
      </div>

      {!readOnly && (
        <div>
          {stockBalanceData.soldOut && (
            <div style={styles.badgeAndMessageContainer}>
              <IoMdAlert
                style={combineStyles(p('cartItemErrorAlert', ['color']), styles.cartItemErrorAlert)}
              />
              <Text
                themeKey="stagedPurchaseSubLine"
                style={combineStyles(p('cartItemError', ['color']), styles.cartItemError)}
              >
                {t('itemOutOfStockMessage')}
              </Text>
            </div>
          )}
          {qtyUpdates?.hasOwnProperty(purchase.item.id) &&
            purchase.choicesWithQuantity.length === 0 &&
            //@ts-ignore
            cartHasInvalidItems.cartContainsInvalidQuantities &&
            !stockBalanceData.soldOut && (
              <div style={styles.badgeAndMessageContainer}>
                <IoMdAlert
                  style={combineStyles(
                    p('cartItemWarningAlert', ['color']),
                    styles.cartItemWarningAlert,
                  )}
                />
                <Text
                  themeKey="stagedPurchaseSubLine"
                  style={combineStyles(p('cartItemWarning', ['color']), styles.cartItemWarning)}
                >
                  {t('itemQuantityWarningMessage', {
                    newItemQuantity: qtyUpdates[purchase.item.id],
                    someLabel: purchase.item.name,
                  })}
                </Text>
              </div>
            )}
          <div style={styles.buttons}>
            {!stockBalanceData.soldOut && (
              <StandardButton
                label={t('button.stagedPurchase.customise')}
                onClick={() => dispatch(openStagedPurchase({ purchaseId: purchase.id }))}
                LeftIconComponent={RiPencilFill}
                themeKey="stagedPurchaseButton"
                containerStyle={styles.button}
                leftIconStyle={iconStyle}
                invisibleContainer
              />
            )}

            <StandardButton
              label={t('button.stagedPurchase.remove')}
              onClick={() => {
                dispatch(removeStagedPurchase(purchase.id));
                dispatch(updateSelectedPaymentMethods({}));
              }}
              LeftIconComponent={RiCloseCircleFill}
              themeKey="stagedPurchaseButton"
              containerStyle={styles.button}
              leftIconStyle={iconStyle}
              invisibleContainer
            />
          </div>
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    marginBottom: 20,
  },

  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 5,
  },

  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  right: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
  },

  subLines: {
    paddingLeft: 5,
  },

  subLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
  },
  subLineLeftContent: {
    display: 'flex',
    flexDirection: 'column',
  },

  quantitySelect: {
    fontFamily: 'Roboto',
    marginRight: 7,
    // marginBottom: 5,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 0,
    paddingTop: 5,
  },
  button: {
    marginRight: 20,
  },
  adjust: {
    marginLeft: 30,
  },
  cartItemError: {
    paddingLeft: 5,
  },
  cartItemErrorAlert: {
    fontSize: 20,
  },
  cartItemWarning: {
    paddingLeft: 5,
  },
  cartItemWarningAlert: {
    fontSize: 20,
  },

  badgeAndMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: -2,
  },
  spanCartSublineMessage: {
    marginBottom: -5,
    marginLeft: 5,
  },
};

export default StagedPurchase;
