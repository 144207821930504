import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SaveCardCheckbox from './SaveCardCheckbox';
import Text from './Text';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import PaymentMethodProvider from './PaymentMethodProvider';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import debug from '../utils/debug';
import { PaymentMethod } from '@stripe/stripe-js';
import { setPaymentFormFilled } from '../slices/paymentFormFilled';
import { loadStripe } from '@stripe/stripe-js';
import { useFormikContext } from 'formik';
import { processPayment, process3ds } from '../thunks/processPayment';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';

const CreditCardProvider = ({ route }: { route: string }) => {
  const dispatch = useAppDispatch();
  const paymentMethod = useAppSelector(state => state.paymentMethod);
  const p = useAppSelector(getThemeLookup);
  const profile = useAppSelector(state => state.profile);
  const stripe = useStripe();
  const elements = useElements();
  const paymentFormInUse = useAppSelector(state => state.paymentFormInUse);
  const paymentFormFilled = useAppSelector(state => state.paymentFormFilled);
  const hqGatewayKey = useAppSelector(state => state.config.hqGatewayKey);
  const [token, setToken] = useState<PaymentMethod | null>(null);
  const formik = useFormikContext<FormikFields>();
  const merchantAccount = useAppSelector(state => state.config.stripeMerchantAccount);

  const handleFormSubmit = () => {
    formik.setSubmitting(true);
    const data = {
      values: formik.values,
      token,
      route,
    };

    dispatch(processPayment(data)).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        formik.resetForm();
      } else {
        if (response.payload.details.json.error_code === 206) {
          const { modal } = response.payload;
          const { additional_info } = response.payload.details.json;
          handle3ds(additional_info, modal); // 3ds required
        }
      }
      formik.setSubmitting(false);
    });
  };

  useEffect(() => {
    if (token) {
      //submit with a credit card
      handleFormSubmit();
    }
  }, [token]);

  const handle3ds = async (info: any, modal: string) => {
    try {
      const { orderInfo } = info;
      const stripe2 = await loadStripe(hqGatewayKey!, { stripeAccount: merchantAccount! });
      const result = await stripe2?.handleCardAction(info.action.client_secret);

      if (result) {
        const { paymentIntent, error } = result;

        if (error) {
          dispatch(setPaymentFormInUse(false));
          enqueueErrorSnackbar(error.message!);
          return;
        }

        await dispatch(
          process3ds({
            orderInfo,
            actionResponse: {
              payment_intent_id: paymentIntent?.id!,
            },
            modal,
          }),
        ).then((res: any) => {
          if (res.type === 'process3ds/fulfilled') {
            formik.resetForm();
          }
        });
      }
    } catch (err) {
      enqueueErrorSnackbar(err);
    }
  };

  //submit sale
  useEffect(() => {
    if (paymentFormInUse && paymentMethod?.method === PAYMENT_METHOD.CREDIT_CARD) {
      handleSubmit();
    }
  }, [paymentFormInUse]);

  const handleSubmit = async () => {
    const cardElement = elements?.getElement(CardElement);

    if (cardElement) {
      await stripe
        ?.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: profile?.givenNames + ' ' + profile?.surname,
            email: profile?.emailAddress,
            phone: profile?.phoneNumber,
          },
        })
        .then(({ paymentMethod, error }) => {
          debug({ paymentMethod, error });

          if (error) {
            enqueueErrorSnackbar((error as any).message);
            dispatch(setPaymentFormInUse(false));
          }
          if (paymentMethod) {
            setToken(paymentMethod);
          }
        })
        .catch(e => {
          debug('credit card payment error', e);
          throw e;
        });
    }
  };

  if (!stripe || !elements) {
    return null;
  }

  const alreadySelected = paymentMethod?.method === PAYMENT_METHOD.CREDIT_CARD ? true : false;

  return (
    <PaymentMethodProvider
      method={PAYMENT_METHOD.CREDIT_CARD}
      right={alreadySelected ? <SaveCardCheckbox /> : undefined}
      subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
    >
      <div style={styles.contentContainer}>
        <div style={combineStyles(styles.input, p('input', CONTAINER_PROPERTIES))}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  backgroundColor: 'transparent', // workaround for issue with darkmode
                },
              },
            }}
            onChange={event => {
              if (event.complete) {
                dispatch(setPaymentFormFilled(true));
              } else {
                if (paymentFormFilled) {
                  dispatch(setPaymentFormFilled(false));
                }
              }
            }}
          />
        </div>
      </div>
    </PaymentMethodProvider>
  );
};

const styles = {
  contentContainer: {
    minHeight: 57,
  },
  input: {
    borderRadius: 4,
    border: '1px solid #c4c4c4',
  },
};

export default CreditCardProvider;
