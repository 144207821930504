import lodash from 'lodash';

import { OrderingSelectors, determineLocationSessions } from 'polygon-ordering';

import { store } from '../store';
type LocationType = {
  id: string;
  name: string;
  online: boolean;
  hidden: boolean;
  coordinatesValid: boolean;
  canOrderOutOfSession: boolean;
  saleTypes: string | undefined;
  takingOrdersNow: string | undefined;
  avgWait: number;
};

const { getLocations, getOrderingWindowPadding } = OrderingSelectors;

function validCoordinate(coord: number | undefined | null) {
  const blacklist = [0, undefined, null];

  return !blacklist.includes(coord);
}

function stringifySimpleArray(arr: any[]) {
  return lodash.orderBy(arr).join(', ') || undefined;
}

export default (locationId: number) => {
  const state = store.getState();

  const locations = getLocations(state);
  const orderingWindowPadding = getOrderingWindowPadding(state);

  let results: LocationType[] = [];

  const enhancedLocations = lodash.values(locations).map(location => ({
    ...location,
    ...determineLocationSessions(location, orderingWindowPadding, 102),
  }));

  enhancedLocations.forEach((location: POSLocation & { takingOrders: any }) => {
    if (locationId != null && String(location.id) !== String(locationId)) {
      return;
    }

    results.push({
      id: location.id,
      name: location.name,
      online: location.online,
      hidden: location.hidden,
      //   latitude: location.latitude,
      //   longitude: location.longitude,
      coordinatesValid: validCoordinate(location.latitude) && validCoordinate(location.longitude),
      canOrderOutOfSession: location.outOfSessionOrderingEnabled,
      saleTypes: stringifySimpleArray(location.supportedSaleTypes),
      takingOrdersNow: stringifySimpleArray(location.takingOrders),
      avgWait: location.averageOrderWaitTime,
    });
  });

  if (results.length) {
    console.log('Locations:');

    const transformed = results.reduce(
      (acc: { [key: string]: any }, { id, ...x }: { [key: string]: any }) => {
        acc[id] = x;
        return acc;
      },
      {},
    );

    console.table(transformed);
  }

  // openNow?

  // supportedSaleTypes

  //   console.groupEnd(groupName);

  if (results.length) {
    console.log('Ordering Window Padding:');
    console.table(orderingWindowPadding);
  } else {
    console.log('No matching location found');
  }

  return 'Done.';
};
