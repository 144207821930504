import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';
import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import checkoutAction from '../actions/checkout';
import combineStyles from '../utils/combineStyles';
import StandardButton from './StandardButton';
import PaymentRequestButton from './PaymentRequestButton';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import lodash from 'lodash';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getCurrentSavedCardToken from '../selectors/getCurrentSavedCardToken';
import { useStripe, useElements, CardCvcElement } from '@stripe/react-stripe-js';
import { enqueueErrorSnackbar } from '../utils/snackbar';

const {
  getOrderTotals,
  getOrderReadyToSubmit,
  getAvailablePaymentMethods,
  getSelectedPaymentMethods,
  getTotalCoveredBySubPayments,
  getCartContainsSoldOutItems,
  getCartContainsInvalidQuantities,
  getFreeOrderReadyToSubmit,
} = OrderingSelectors;

const { fetchPaymentIntent } = OrderingOperations;

interface IProps {
  containerStyle?: React.CSSProperties;
}

const CheckoutButton: React.FC<IProps> = ({ containerStyle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const orderReadyToSubmit = useAppSelector(getOrderReadyToSubmit);
  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const totalCoveredBySubPayments = useAppSelector(getTotalCoveredBySubPayments);
  const creditCardComplete = useAppSelector(state => state.creditCardComplete);
  const checkoutInProgress = useAppSelector(state => state.checkoutInProgress);
  const cartContainsSoldOutItems = useAppSelector(getCartContainsSoldOutItems);
  const cartContainsInvalidQuantities = useAppSelector(getCartContainsInvalidQuantities);
  const freeOrderReadyToSubmit = useAppSelector(getFreeOrderReadyToSubmit);
  const enableCvcCheck = useAppSelector(state => state.config.enableStripeCvcRevalidation);
  const cvcFilled = useAppSelector(state => state.cvcFilled);
  const currentSavedCardToken = useAppSelector(getCurrentSavedCardToken);
  const savedCards = useAppSelector(state => state.savedCards);
  const savedCard = savedCards?.filter(card => card.token === currentSavedCardToken)[0];
  const elements = useElements();
  const stripe = useStripe();
  const orderTotals = useAppSelector(getOrderTotals);
  const [cvcValidationInProgress, setCvcValidationInProgress] = useState(false);

  const includesSavedCard =
    selectedPaymentMethods.filter(method => method.method === PAYMENT_METHOD.SAVED_CARD).length > 0;

  const ccMissing =
    lodash.find(selectedPaymentMethods, ['method', PAYMENT_METHOD.CREDIT_CARD]) &&
    !creditCardComplete;

  const gcMissingOrInvalid =
    lodash.find(selectedPaymentMethods, ['method', PAYMENT_METHOD.GIFT_CARD]) &&
    !availablePaymentMethods.includes(PAYMENT_METHOD.GIFT_CARD);

  const noSelectedPaymentMethods = selectedPaymentMethods.length === 0;

  const paymentRequestButtonMethodSelected = selectedPaymentMethods.some(selectedPaymentMethod => {
    if (
      selectedPaymentMethod.method === PAYMENT_METHOD.APPLE_PAY ||
      selectedPaymentMethod.method === PAYMENT_METHOD.GOOGLE_PAY
    ) {
      return true;
    }
    return false;
  });

  const showPaymentRequestButton =
    paymentRequestButtonMethodSelected && orderReadyToSubmit && !gcMissingOrInvalid;

  const handleCvcCheck = () => {
    setCvcValidationInProgress(true);
    dispatch(
      fetchPaymentIntent({
        id: currentSavedCardToken!,
        customer: savedCard?.customer!,
        total: orderTotals?.discountedMoneyPrice!,
      }),
    ).then((response: any) => {
      const payment_intent = response.payload;

      const { client_secret, payment_method } = payment_intent;

      const cvcElement = elements?.getElement(CardCvcElement);

      stripe
        ?.confirmCardPayment(client_secret, {
          payment_method,
          payment_method_options: {
            card: {
              cvc: cvcElement!,
            },
          },
        })
        .then(response => {
          if (response.error) {
            enqueueErrorSnackbar(response.error.message!);
          } else {
            dispatch(checkoutAction());
          }
          setCvcValidationInProgress(false);
        });
    });
  };

  const handleCheckout = () => {
    if (enableCvcCheck && includesSavedCard && cvcFilled) {
      handleCvcCheck();
    } else {
      dispatch(checkoutAction());
    }
  };

  return showPaymentRequestButton ? (
    <div style={styles.paymentRequestButtonContainer}>
      <PaymentRequestButton />
    </div>
  ) : (
    <StandardButton
      themeKey="checkoutButton"
      label={t('button.checkout')}
      onClick={() => handleCheckout()}
      disabled={
        cvcValidationInProgress ||
        checkoutInProgress ||
        ccMissing ||
        gcMissingOrInvalid ||
        !orderReadyToSubmit ||
        (freeOrderReadyToSubmit ? false : noSelectedPaymentMethods) ||
        !totalCoveredBySubPayments ||
        cartContainsSoldOutItems ||
        //@ts-ignore
        cartContainsInvalidQuantities.cartContainsInvalidQuantities ||
        (includesSavedCard && enableCvcCheck && !cvcFilled)
      }
      showSpinner={checkoutInProgress || cvcValidationInProgress}
      containerStyle={combineStyles(styles.button, containerStyle)}
      LeftIconComponent={
        invalidOfferPresent || invalidStagedPurchasePresent ? RiAlertFill : undefined
      }
    />
  );
};

const styles: Styles = {
  button: {
    marginTop: 20,
  },
  paymentRequestButtonContainer: {
    margin: '-10px -10px 0 -10px',
    paddingTop: 20,
    paddingBottom: 10,
  },
};

export default CheckoutButton;
