import { createSelector } from 'reselect';

import { centsToDollarString } from '../utils/misc';

import getGooglePayItems from './getGooglePayItems';
import { getSelectedPaymentMethods } from '.';
import { getItemPaymentTotal } from '../utils/misc';

export default createSelector(
  [getGooglePayItems, getSelectedPaymentMethods],
  (items, getSelectedPaymentMethods: any[]) => ({
    total_price: centsToDollarString(getItemPaymentTotal(getSelectedPaymentMethods, 'google-pay')),
    currency_code: 'AUD',
    shipping_address_required: false,
    billing_address_required: false,
    line_items: items,
  }),
);
