import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightFill, RiAlertFill } from 'react-icons/ri';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';

import { OrderingSelectors } from 'polygon-ordering';

import history from '../history';

import { DESKTOP_MODAL_TYPES } from '../constants';

import { setCurrentModal } from '../thunks/setCurrentModal';

import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import getOrderInProgress from '../selectors/getOrderInProgress';

import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import OrderSummaryColumn from '../components/OrderSummaryColumn';

import { REVIEW_ORDER_SCREEN_ROUTE } from '../screens/ReviewOrderScreen';
import { COMPLETED_ORDER_SCREEN_ROUTE } from '../screens/CompletedOrderScreen';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { initiateCheckout } from '../utils/analytics';

const {
  getOrderReadyToSubmitSansPurchaser,
  getOrderSubmitted,
  getStagedPurchaseCount,
  getCartContainsSoldOutItems,
  getCartContainsInvalidQuantities,
  getOrderTotals,
  getStagedPurchases,
} = OrderingSelectors;

export const ORDER_SUMMARY_MODAL_ID = 'ORDER_SUMMARY_MODAL_ID';

const OrderSummaryModal = ({
}) => {
 const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const orderReadyToSubmitSansPurchaser = useAppSelector(getOrderReadyToSubmitSansPurchaser);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
   const cartContainSoldOutItems= useAppSelector(getCartContainsSoldOutItems)
 const cartContainsInvalidQuantities= useAppSelector(getCartContainsInvalidQuantities)
  const orderTotals= useAppSelector(getOrderTotals)
 const stagedPurchases= useAppSelector(getStagedPurchases)

  const { t } = useTranslation();
  const dispatch = useAppDispatch();


  const { pathname } = useLocation();

  if (pathname === COMPLETED_ORDER_SCREEN_ROUTE || !orderInProgress || orderSubmitted) {
    return null;
  }

  const hideCheckoutButton = pathname === REVIEW_ORDER_SCREEN_ROUTE;

  return (
    <Modal
      clickBackgroundToClose
      closeButtonLabel={t('button.modalClose.dismiss')}
      desktopModalType={DESKTOP_MODAL_TYPES.CALLOUT}
      desktopMaxWidth={380}
      desktopMinWidth={380}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
    >
      <PerfectScrollbar style={styles.scrollable}>
        <OrderSummaryColumn />
      </PerfectScrollbar>
      {!hideCheckoutButton && (
        <StandardButton
          disabled={
          //@ts-ignore
            cartContainSoldOutItems || cartContainsInvalidQuantities.cartContainsInvalidQuantities
          }
          themeKey="modalProceedButton"
          label={
            orderReadyToSubmitSansPurchaser
              ? t('button.orderSummary.checkout')
              : t('button.orderSummary.review')
          }
          RightIconComponent={RiArrowRightFill}
          LeftIconComponent={
            invalidStagedPurchasePresent || (minimumOrderAmountNotMet && stagedPurchaseCount)
              ? RiAlertFill
              : undefined
          }
          onClick={() => {
              initiateCheckout(
              stagedPurchases.map(purchaseItem => {
                return {
                  ...purchaseItem.item,
                  totalPrice: purchaseItem.moneyPrice,
                };
              }),
              orderTotals!.discountedMoneyPrice,
            );

            dispatch(setCurrentModal({ modalId: null }));
            history.push(REVIEW_ORDER_SCREEN_ROUTE);
          }}
        />
      )}
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    borderRadius: 2,
  },
  modalMobileContainer: {
    padding: 0,
    paddingTop: 15,
  },

  scrollable: {
    overflowY: 'auto',

    flex: 1,

    padding: 15,
  },
};

export default OrderSummaryModal;
