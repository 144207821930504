import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import StandardButton from '../components/StandardButton';
import PurchaseGiftCardForSelf from '../components/PurchaseGiftCardForSelf';
import PurchaseGiftCardForOthers from '../components/PurchaseGiftCardForOthers';

export const PURCHASE_GIFT_CARD_ROUTE = '/purchase-gift-card';

const PurchaseGiftCardScreen: React.FC = () => {
  const { t } = useTranslation();
  const [gift, setGift] = useState(true);

  return (
    <div>
      <div>
        <Row
          className="text-center my-3   mx-0 "
          style={{ borderRadius: 20, border: '1px solid', padding: 2 }}
        >
          <Col className="px-0">
            <StandardButton
              onClick={() => setGift(!gift)}
              themeKey={gift ? 'giftCardOptionActive' : 'giftCardOptionDefault'}
              label={t('buyAsGift')}
            />
          </Col>
          <Col className="px-0">
            <StandardButton
              onClick={() => setGift(!gift)}
              themeKey={!gift ? 'giftCardOptionActive' : 'giftCardOptionDefault'}
              label={t('buyForMyself')}
            />
          </Col>
        </Row>
      </div>
      {gift ? <PurchaseGiftCardForOthers /> : <PurchaseGiftCardForSelf />}
    </div>
  );
};

export default PurchaseGiftCardScreen;
