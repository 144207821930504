import React from 'react';
import { RiPencilFill, RiTimeFill, RiPhoneFill } from 'react-icons/ri';
import moment from 'moment';

import { OrderingSelectors, determineLocationSessions } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';
import getEditLocationAvailable from '../selectors/getEditLocationAvailable';

import { setCurrentModal } from '../thunks/setCurrentModal';

import { LOCATION_MODAL_ID } from '../modals/LocationModal';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { IconType } from 'react-icons/lib';

const { getSaleType, getLocation, getOrderingWindowPadding } = OrderingSelectors;

const Detail = ({ label, IconComponent }: { label?: string; IconComponent: IconType }) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <div style={styles.detail}>
      <IconComponent
        style={combineStyles(
          p('locationDetail', ['color', 'fontSize']),
          p('locationDetailIcon', ['color', 'fontSize']),
        )}
      />

      <Text themeKey="locationDetail" style={styles.detailLabel}>
        {label}
      </Text>
    </div>
  );
};

const OrderPropertyLocation = ({
  readOnly,
  containerStyle,
}: {
  readOnly?: boolean;
  containerStyle?: React.CSSProperties;
}) => {
  const p = useAppSelector(getThemeLookup);
  const saleType = useAppSelector(getSaleType);
  const unenhancedLocation = useAppSelector(getLocation);
  const orderingWindowPadding = useAppSelector(getOrderingWindowPadding);
  const editLocationAvailable = useAppSelector(getEditLocationAvailable);
  const hideLocationHours = useAppSelector(state => state.config.hideLocationHours);
  const dispatch = useAppDispatch();
  const labelStyle = combineStyles(
    styles.label,
    p('defaultButton', TEXT_PROPERTIES),
    p('orderPropertyButton', TEXT_PROPERTIES),
  );

  if (!unenhancedLocation) {
    return null;
  }

  const enhancedLocation = {
    ...unenhancedLocation,
    ...determineLocationSessions(unenhancedLocation, orderingWindowPadding, saleType),
  };

  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  const showPhone = !isDineIn && enhancedLocation.phone;

  type OnClick = () => void;

  let onClick: OnClick | undefined = (): void => {
    dispatch(
      setCurrentModal({
        modalId: LOCATION_MODAL_ID,
        preserveReturnModalDetails: true,
        params: { saleType },
      }),
    );
  };
  if (readOnly || isDelivery) {
    onClick = undefined;
  }

  const start = moment(enhancedLocation.orderingWindowStart).format('LT');
  const end = moment(enhancedLocation.orderingWindowEnd).format('LT');

  return (
    <div style={combineStyles(styles.mainContainer, containerStyle)}>
      <TouchableOpacity
        style={styles.touchable}
        onClick={onClick}
        disabled={!editLocationAvailable}
        ariaLabel={editLocationAvailable ? 'edit location' : 'location'}
      >
        <Text style={labelStyle}>{enhancedLocation.name}</Text>

        {editLocationAvailable && Boolean(onClick) && (
          <RiPencilFill
            style={combineStyles(
              styles.editIcon,
              labelStyle,
              p('orderPropertyButtonIcon', ['color']),
            )}
          />
        )}
      </TouchableOpacity>

      {!hideLocationHours && <Detail label={`${start} - ${end}`} IconComponent={RiTimeFill} />}

      {showPhone && <Detail label={enhancedLocation.phone} IconComponent={RiPhoneFill} />}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
  },

  touchable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  label: {
    textAlign: 'center',
  },

  editIcon: {
    marginLeft: 5,
    marginRight: -10,
  },

  detail: {
    marginTop: 5,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailLabel: {
    marginLeft: 4,
  },
};

export default OrderPropertyLocation;
