import { centsToDollarString } from '../misc';

export default function googlePayItemFromPurchase(
  purchase: PurchaseWithTotals,
): GooglePayItem {
  const unitPrice = purchase.discountedMoneyPrice / purchase.quantity;

  return {
    currency_code: 'AUD',
    description: purchase?.item?.name || 'Item',
    unit_price: centsToDollarString(unitPrice),
    total_price: centsToDollarString(purchase.discountedMoneyPrice),
    quantity: String(purchase.quantity || 1),
  };
}
