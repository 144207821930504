import { call, put } from 'redux-saga/effects';

import { OrderingOperations, requestAndWaitForFlow } from 'polygon-ordering';

import debug from '../utils/debug';
import history from '../history';

import setCurrentModal from '../actions/setCurrentModal';

import { MENU_SCREEN_ROUTE } from '../screens/MenuScreen';

const { updateKeyOrderProperty } = OrderingOperations;

export default function* confirmLocationEstimate({ payload = {} }) {
  debug('applying buffered delivery estimate', { payload });

  const { locationId } = payload;

  const result = yield call(requestAndWaitForFlow, updateKeyOrderProperty, {
    locationId,
    confirmLocationDeliveryEstimate: true,
  });

  if (result.succeeded) {
    history.replace(MENU_SCREEN_ROUTE);
    yield put(setCurrentModal(null));
  }
}
