import React from 'react';

import combineStyles from '../utils/combineStyles';

import MapMarker from './MapMarker';

interface IProps {
  location: POSLocation;
  hovered: boolean;
  hoverLocationStart: (id: string) => void;
  hoverLocationEnd: (id: null) => void;
  selectedColor: React.CSSProperties;
  color: React.CSSProperties;
  selected: boolean;
  selectLocation: (id: string, selectedByMarker: boolean) => void;
  lat?: number;
  lng?: number;
}

const LocationMapMarker: React.FC<IProps> = ({
  location,
  selected,
  selectLocation,
  color,
  selectedColor,
  hovered,
  hoverLocationStart,
  hoverLocationEnd,
}) => {
  return (
    <MapMarker
      color={selected && selectedColor ? selectedColor : color}
      darkDot={selected}
      onClick={() => selectLocation(location.id, true)}
      onMouseEnter={() => hoverLocationStart(location.id)}
      onMouseLeave={() => hoverLocationEnd(null)}
      style={combineStyles(selected && styles.selected, !selected && hovered && styles.hovered)}
    />
  );
};

const styles: Styles = {
  selected: {
    zIndex: 10, // sit above other markers
  },
  hovered: {
    filter: 'grayscale(50%)', // less jaring than using opacity
  },
};

export default LocationMapMarker;
